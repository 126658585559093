import React, { Component } from "react";
import { Box, Typography, TextField, Autocomplete, InputAdornment, styled, MenuItem } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search'
import { LevelType } from "../../../../blocks/cfpipelines1/src/CreatePipelineController";
import { AccountsDetails, PipelinesDetails } from "../Cftasklistener3Controller";
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';

interface CreateEditTaskErrorsType {
    group_name?: string
    description?: string
    selectedLevel?: string;
    selectedPipelines?: string;
    selectedUsers?: string;
    selectedCategory?:string
}
interface Props {
    navigation: any;
    id: string;
    title: string;
    group_name: string;
    taskId: string;
    handleChange: (e: any) => void;
    handleAutoComplete: (id: string) => (e: any, newValue: any) => void
    handleCustomSelectedCategory:(e:string)=>void
    selectedLevel: LevelType[]
    allLevels: LevelType[]
    users: AccountsDetails[]
    selectedUsers: AccountsDetails[]
    pipelines: PipelinesDetails[]
    selectedPipelines: PipelinesDetails[]
    selectedCategory:string
    description: string
    handleCreateEdit: () => void
    isCreateEditFormComplete?: () => boolean | string
    createEditErrors: CreateEditTaskErrorsType
    buttonText: string
}
interface S {
    searchQuery: string
    selectedCategory:string
    openStates:boolean
}


interface SS {
}

export default class Customtaskgroup extends Component<Props,S> {
    constructor(props: any) {
        super(props);
        this.state = {
            searchQuery: '',
            selectedCategory: "",
            openStates: false
        }

    }
    handleDropdownToggle = () => {
        this.setState((prevState) => ({
            openStates: !prevState.openStates
        }));
            };
    handleSelectCategory = (option: string) => {
        this.setState({
          selectedCategory: option,
          searchQuery: "",
          openStates:false
          
        });
        this.props.handleCustomSelectedCategory(option);
      };
    renderAutoComplete = (title: string, id: string, options: any, value: any,error?:string) => {
        return (
            <Box>
                                <Box sx={{display:"flex"}}>
                                <Typography
                    variant="body1"
                    style={{
                        margin: "0.5rem 0",
                        color: "#1A4A42",
                        fontWeight: 700,
                        fontSize: "1.1rem",
                    }}
                >
                    {title}
                </Typography>
                    <Typography variant="body1" style={{marginLeft:"0.3rem",  marginTop: "0.5rem", color: "red", fontWeight: 700, fontSize: '1.5rem' }}>
                        *
                    </Typography>
                    </Box>

                <Autocomplete
                    data-test-id={id}
                    multiple
                    id={id}
                    options={options}
                    onChange={(event, newValue) => this.props.handleAutoComplete(id)(event, newValue)}
                    isOptionEqualToValue={(option, value) => option?.attributes?.label === value?.attributes?.label}
                    value={value}
                    getOptionLabel={(option) => option?.attributes?.label}
                    renderOption={(props, option, { selected },) => {
                        return (
                            <li
                            key={option.id}
                                {...props}
                                style={{
                                    fontSize: "1.4rem",
                                    backgroundColor: selected ? "#1A4A421A" : "transparent",
                                    fontWeight:selected?600: 400,
                                    color: selected ? "#1A4A42" : "#0F172A",
                                }}
                            >
                                {option.attributes.label}
                            </li>
                        );
                    }}
                    style={{ width: "100%" }}
                    renderInput={(params) => (
                        <TextField
                            style={{ marginBottom: "0.8rem" }}
                            {...params}
                            placeholder={title}
                            InputLabelProps={{ shrink: false }}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <InputAdornment position="end"
                                        sx={{
                                            "& .MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-fullWidth MuiInputBase-formControl MuiInputBase-adornedEnd MuiAutocomplete-inputRoot css-1hiurh-MuiInputBase-root-MuiOutlinedInput-root": {
                                                paddingRight: "0 !important"

                                            }
                                        }}
                                    >
                                        <SearchIcon sx={{
                                            color: '#1A4A4266',
                                            cursor: 'pointer',
                                            fontSize: '1.8rem',
                                            "& .MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-fullWidth MuiInputBase-formControl MuiInputBase-adornedEnd MuiAutocomplete-inputRoot css-1hiurh-MuiInputBase-root-MuiOutlinedInput-root": {
                                                paddingRight: "0 !important"

                                            }
                                        }} />
                                    </InputAdornment>
                                ),
                                sx: {
                                    fontWeight: 400,
                                    paddingLeft: "0.3rem !important",
                                    fontSize: "1.2rem",borderRadius: "0.5rem",
                                    "& .css-18bmrv6-MuiButtonBase-root-MuiChip-root": {
                                        fontSize: "1.2rem",
                                        paddingRight: "0",
                                        display: 'none'
                                    },
                                    "& .MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-fullWidth MuiInputBase-formControl MuiInputBase-adornedEnd MuiAutocomplete-inputRoot css-1hiurh-MuiInputBase-root-MuiOutlinedInput-root": {
                                        paddingRight: "0 !important"

                                    },
                                    "& .MuiAutocomplete-tag":{
                                        display:"none"
                                    }

                                },
                            }}
                            sx={{
                                "& .MuiOutlinedInput-root": {
                                    padding: "0 0.4rem",
                                },
                                '& .MuiSelect-icon': {
                                    display: 'none',
                                },
                                "& .MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-fullWidth MuiInputBase-formControl MuiInputBase-adornedEnd MuiAutocomplete-inputRoot css-1hiurh-MuiInputBase-root-MuiOutlinedInput-root, .MuiAutocomplete-hasClearIcon.css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root": {
                                    paddingRight: "0 !important"
                                },
                                "& .MuiInputAdornment-root": {
                                    marginRight: '1rem',
                                },
                                "&.MuiInputBase-root.MuiOutlinedInput-root": {
                                    paddingRight: "0 !important",
                                },
                                "& .MuiInputBase-adornedEnd": {
                                    paddingRight: "0 !important",
                                },
                                "& .MuiAutocomplete-inputRoot": {
                                    paddingRight: "0 !important",
                                },

                            }}
                        />
                    )}
                />
                             {error && (
                        <Typography sx={{ marginBottom: '1rem', marginTop: '-0.6rem', fontSize: '1rem' }} color="red" variant="body2">
                        {error}
                        </Typography>
                    )} 
                <Box sx={{ marginTop: "0.1rem", display: "flex", gap: "0.5rem", flexWrap: 'wrap', alignItems: 'center' }}>
                    {value.map((item: { attributes: { label: string  }; }, index: number) => (
                        <Box
                            key={index}
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                border: '0.1rem solid #1A4A4266',
                                backgroundColor: "white",
                                borderRadius:"0.5rem",
                                padding: "0.7rem",
                                fontSize: "1.2rem",
                                fontWeight: 400,
                                color: "#292524",
                                justifyContent: 'space-between',
                                width:"15%"
                            }}
                        >
                            {title=="Individuals"? <Box display="flex" alignItems="center" gap={1}>
                                <img
                                    src={"https://randomuser.me/api/portraits/men/1.jpg"}
                                    style={{ width: '18px', height: '18px', borderRadius: '50%', objectFit: 'cover' }}
                                />
                                {item.attributes.label}
                            </Box>
                                :
                                item.attributes.label}
                            <CloseIcon
                                sx={{
                                    marginLeft: "0.8rem",
                                    fontSize: "1.4rem",
                                    cursor: "pointer",
                                    color: "#1A4A4280"
                                }}
                                onClick={() =>
                                    this.props.handleAutoComplete(id)(null, value.filter((item: { attributes: { label: string; }; }) => item !== item))
                                }
                            />
                        </Box>
                    ))}
                </Box>
            </Box>
        );
    };

    render() {
        const { selectedCategory,navigation, buttonText, createEditErrors, title, group_name, taskId, handleChange, allLevels, selectedLevel, handleCreateEdit, users, selectedUsers, pipelines, selectedPipelines, description } = this.props
        return (
            <Box sx={{ ...createEditAccountGroupContainer }}>
                <Box marginBottom={2}>
                    <Box display='flex' alignItems='center' gap={1}>
                        <ArrowBackIcon style={{ fontSize: '2rem' }} onClick={() => navigation.goBack()} />
                        <Typography variant="h6" sx={{ fontWeight: 700, fontSize: '1.5rem', color: "#292524" }}>{title}</Typography>
                    </Box>
                </Box>
                <Box display='flex' flexWrap='wrap' gap={1} marginBottom={0}>
                    <Box style={{ flex: 1 }}>
                    <Box sx={{display:"flex"}}>
                    <Typography variant="body1" style={{ margin: "0.5rem 0", color: "#1A4A42", fontWeight: 700, fontSize: '1.1rem' }}>
                            Task Group Name
                    </Typography>
                    <Typography variant="body1" style={{marginLeft:"0.3rem",  marginTop: "0.5rem", color: "red", fontWeight: 700, fontSize: '1.5rem' }}>
                        *
                    </Typography>
                    </Box>

                        <TextField
                            name="group_name"
                            data-test-id="group_name"
                            value={group_name}
                            onChange={handleChange}
                            placeholder="Type group name"
                            fullWidth
                            multiline
                            InputLabelProps={{ shrink: false }}
                            style={{ marginBottom: "0.8rem" }}
                            sx={{
                                '& .css-1obe73v-MuiInputBase-root-MuiOutlinedInput-root': {
                                    padding: '0.9rem 0.6rem! important',
                                    borderRadius:"0.6rem"
                                }
                            }}
                            inputProps={{
                                sx: {
                                    fontWeight: 400,
                                    fontSize: '1.2rem',
                                    whiteSpace: 'normal',
                                    wordWrap: 'break-word',
                                    wordBreak: 'break-word',
                                },
                            }}
                        />
                        {createEditErrors.group_name && (
                            <Typography sx={{ marginBottom: '1rem', marginTop: '-0.6rem', fontSize: '1rem' }} color="red" variant="body2">{createEditErrors.group_name}</Typography>
                        )}
                    </Box>
                  { this.props.id=="Edit Task" &&<Box style={{ flex: 1 }}>

                        <Typography variant="body1" style={{ margin: "0.5rem 0", color: "#1A4A42", fontWeight: 700, fontSize: '1.1rem' }}>
                            Task Group Id
                        </Typography>
                        <TextField
                            name="taskId"
                            data-test-id="taskId"
                            value={taskId}
                            fullWidth
                            disabled
                            multiline
                            variant="outlined" 
                            InputLabelProps={{ shrink: false }}
                            sx={{
                                background: "#1A4A4233",
                                borderRadius: "0.8rem",
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        border: 'none',
                                    },
                                    '&:hover fieldset': {
                                        border: 'none',
                                    },
                                    '&.Mui-focused fieldset': {
                                        border: 'none',
                                    },
                                },
                                '& .MuiInputBase-root': {
                                    padding: '0.9rem',
                                },
                            }}
                            inputProps={{
                                sx: {
                                    fontWeight: 400,
                                    fontSize: '1.2rem',
                                    whiteSpace: 'normal',
                                    wordWrap: 'break-word',
                                    wordBreak: 'break-word',
                                },
                            }}
                        />

                    </Box>}
                </Box>
                <Box display="flex" flexWrap='wrap' gap={1} marginBottom={0}>
                </Box>
                <Box sx={{display:"flex"}}>
                <Typography variant="body1" style={{ margin: "0.5rem 0", color: "#1A4A42", fontWeight: 700, fontSize: '1.1rem' }}>
                    Description
                </Typography>
                    <Typography variant="body1" style={{marginLeft:"0.3rem",  marginTop: "0.5rem", color: "red", fontWeight: 700, fontSize: '1.5rem' }}>
                        *
                    </Typography>
                    </Box>

                <TextField
                    name="description"
                    data-test-id="description"
                    value={description}
                    onChange={handleChange}
                    multiline
                    rows={4}
                    fullWidth
                    InputLabelProps={{ shrink: false }}
                    placeholder="Type task description"
                    style={{ marginBottom: "0.8rem" }}
                    sx={{
                        '& .css-1obe73v-MuiInputBase-root-MuiOutlinedInput-root': {
                            padding: '0.9rem 0.6rem! important',
                            borderRadius:"0.6rem"
                        }
                    }}
                    inputProps={{
                        sx: {
                            fontWeight: 400,
                            fontSize: '1.2rem',

                        },
                    }}
                />
                                {createEditErrors.description && (
                    <Typography sx={{ marginBottom: '1rem', marginTop: '-0.6rem', fontSize: '1rem' }} color="red" variant="body2">{createEditErrors.description}</Typography>
                )}

<Box sx={{display:"flex"}}>
                    <Typography variant="body1" style={{ margin: "0.5rem 0", color: "#1A4A42", fontWeight: 700, fontSize: '1.1rem' }}>
                         Category
                    </Typography>
                    <Typography variant="body1" style={{marginLeft:"0.3rem",  marginTop: "0.5rem", color: "red", fontWeight: 700, fontSize: '1.5rem' }}>
                        *
                    </Typography>
                    </Box>
                    <Box sx={{ flex: 1 }}>
                        <Autocomplete
                            data-test-id="selectcategory" placeholder="Search category"
                            options={["Communications","Category"]} value={this.state.selectedCategory || selectedCategory}
                            open={this.state.openStates}  onChange={() => { this.handleDropdownToggle() }}
                            isOptionEqualToValue={(option, value) => option === this.state.selectedCategory}
                            getOptionLabel={(option) => option}
                            renderInput={(params) => (
                                <TextField  {...params}
                                    data-test-id="categoryTextField"  placeholder="Search Category"
                                    onClick={() => this.handleDropdownToggle()} style={{ marginBottom: "0.8rem" }}
                                    InputProps={{...params?.InputProps,
                                        endAdornment: (
                                            <InputAdornment position="end"
                                                sx={{
                                                    "& .MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-fullWidth MuiInputBase-formControl MuiInputBase-adornedEnd MuiAutocomplete-inputRoot css-1hiurh-MuiInputBase-root-MuiOutlinedInput-root": {
                                                        paddingRight: "0 !important"}
                                                }}
                                            >
                                                <ArrowDropDownOutlinedIcon  style={{
                                                    color: '#64748B',cursor: 'pointer', fontSize: '2rem'
                                                }} />
                                            </InputAdornment>
                                        ),
                                        sx: {
                                            fontWeight: 400, paddingLeft: "0.3rem !important",
                                            fontSize: "1.2rem",borderRadius: "0.5rem",
                                            "& .css-18bmrv6-MuiButtonBase-root-MuiChip-root": {
                                                fontSize: "1.2rem",paddingRight: "0",display: 'none'
                                            },
                                            "& .MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-fullWidth MuiInputBase-formControl MuiInputBase-adornedEnd MuiAutocomplete-inputRoot css-1hiurh-MuiInputBase-root-MuiOutlinedInput-root": {
                                                paddingRight: "0 !important"
                                            },
                                            "& .MuiAutocomplete-tag":{
                                                display:"none"}
                                        }}}
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                            padding: "0 0.4rem", },
                                        '& .MuiSelect-icon': {
                                            display: 'none',},
                                        "& .MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-fullWidth MuiInputBase-formControl MuiInputBase-adornedEnd MuiAutocomplete-inputRoot css-1hiurh-MuiInputBase-root-MuiOutlinedInput-root, .MuiAutocomplete-hasClearIcon.css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root": {
                                            paddingRight: "0 !important"
                                        },
                                        "& .MuiInputAdornment-root": {
                                            marginRight: '1rem',
                                        },
                                        "&.MuiInputBase-root.MuiOutlinedInput-root": {
                                            paddingRight: "0 !important",},
                                        "& .MuiInputBase-adornedEnd": {
                                            paddingRight: "0 !important", },
                                        "& .MuiAutocomplete-inputRoot": {
                                            paddingRight: "0 !important",
                                        },
                                    }}
                                />
                            )}
                            renderOption={(props, option, { selected }) => (
                                <MenuItem
                                    {...props} key={option}
                                    data-test-id="renderOptioncategory"
                                    sx={{ fontSize: "1.2rem",padding: "0.6rem 0",fontWeight: selected ? 600 : 400,
                                        color: selected ? "#1A4A42" : "#292524","&:hover": { backgroundColor: "#f0f0f0" },
                                    }}
                                    onClick={() => this.handleSelectCategory(option)}>
                                    {option}
                                </MenuItem>
                            )}
                            noOptionsText="No category found"/>
                        {createEditErrors.selectedCategory && ( <Typography sx={{ marginTop: '0.5rem', fontSize: '1rem' }} color="red" variant="body2">
                                {createEditErrors.selectedCategory}
                            </Typography>
                        )}
                    </Box>
                    {this.renderAutoComplete("Associated Pipelines", "selectedPipelines", pipelines, selectedPipelines,createEditErrors.selectedPipelines)}
                    <Box style={{ flex: 1 }}>
                        {this.renderAutoComplete("Levels", "selectedLevel", allLevels, selectedLevel,createEditErrors.selectedLevel)}
                    </Box>
                <Box sx={{ flex: 1 }}>
                        {this.renderAutoComplete("Individuals", "selectedUsers", users, selectedUsers,createEditErrors.selectedUsers)}
                    </Box>
                    <CustomWarningBox>
                        <CustomCreateEditButton type="submit" 
                        disabled={false}
                        onClick={handleCreateEdit} 
                        style={{
                            backgroundColor: "#1A4A42",
                            color: "#fff",
                            cursor:  "pointer",
                        }}>{buttonText}</CustomCreateEditButton>
                    </CustomWarningBox>
            </Box >
        );
    }
}

const createEditAccountGroupContainer = {
    boxSizing: "border-box",
    borderRadius: "1.2rem",
    minHeight: 'calc(100vh - 2.5rem)',
    padding: "2rem 2rem 1rem",
    backgroundColor: "#fff",
    fontFamily: "Raleway, sans-serif",
    border: "1px solid #ccc",

};

const createEditCommonDividerStyle = {
    margin: "1.5rem 0",
    backgroundColor: "#1A4A4233",
};

const createEditTextBox = {
    flexDirection: "column",
    backgroundColor: "#f9f9f9",
    gap: "1rem",
    padding: "1.5rem",
    display: "flex",
    borderRadius: "1.2rem",
    marginTop: "2.5rem",
    border: "0.1rem dashed #292524",
}
const CustomWarningBox = styled("div")(
    {
        border: "0.1rem dashed #292524",
        padding: "1.5rem",
        borderRadius: "1.2rem",
        backgroundColor: "#f9f9f9",
        marginTop: "2.5rem"
    });

const CustomCreateEditButton = styled("button")({
    width: "100%",
    padding: "0.8rem",
    border: "none",
    borderRadius: "0.5rem",
    fontSize: "1.2rem",
    cursor: "pointer",
    fontWeight: 600,
});