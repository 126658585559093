import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
interface EditTaskErrorType {
    selectedUsers?: string;
    selectedPipelines?: string;
    selectedLevel?: string;
    group_name?: string
    description?: string
    selectedCategory?:string
}
import { generateRandomUsersWithLabel, generateRandomPipelines, TaskDataType,UserTypeWithLabel } from "./utils/generateTasks";
import { toast } from "react-toastify"
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { AccountsDetails, PipelinesDetails, TaskDetails } from "./Cftasklistener3Controller";
import { LevelType } from "../../../blocks/cfpipelines1/src/CreatePipelineController";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    taskId: string
    selectedPipelines:PipelinesDetails[]
    singleTask: TaskDetails['attributes']
    selectedUsers:AccountsDetails[]
    allUsers: AccountsDetails[]
    allPipelines: PipelinesDetails[]
    selectedLevel:LevelType[]
    description: string
    group_name: string
    editErrors: EditTaskErrorType
    levels: LevelType[]
    selectedCategory:string
    // Customizable Area End
}

export interface SS {
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export default class EdittaskgroupController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    editLevelsListCallId: string="";
    individualUserListCallId: string="";
    pipelineListCallId: string="";
  editTaskGroupCallId: string="";
    editTaskDetailsCallId: string="";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.editLevelsListAPI()
        this.individualUserListAPI()
        this.pipelineListAPI()
        this.taskDetailsAPI()
        // Customizable Area End

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            taskId: '',
            allPipelines: [],
            selectedLevel: [],
            group_name: '',
            selectedUsers: [],
            selectedPipelines: [],
            singleTask: {
              task_serial_id: "",
              id: 0,
              account_id: "",
              title: "",
              due_date: "",
              description: "",
              status: "",
              priority: "",
              permissions: [],
              accounts: [],
              pipelines: [],
              created_at: "",
              updated_at: ""
            },
            description: '',
            editErrors: {},
            levels: [],
            allUsers: [],
            selectedCategory:""
            // Customizable Area End
        };

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }
    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
              getName(MessageEnum.RestAPIResponceDataMessage)
            )
      
            let responseJson = message.getData(
              getName(MessageEnum.RestAPIResponceSuccessMessage)
            )
           let errorResponse  =message.getData(
              getName(MessageEnum.RestAPIResponceErrorMessage)
            )
            if (apiRequestCallId) {
              switch (apiRequestCallId) {
                case this.individualUserListCallId:
                  this.handleIndividualUserListRes(responseJson, errorResponse);
                  break;
                case this.editLevelsListCallId:
                  this.handleEditLevelsListRes(responseJson,errorResponse)
                  break;
                  case this.pipelineListCallId:
                    this.handlePipelineListRes(responseJson,errorResponse)
                    break;
                    case this.editTaskGroupCallId:
                      this.handleEditTaskGroupRes(responseJson, errorResponse);
                      break;
                      case this.editTaskDetailsCallId:this.handleTaskDetailsRes(responseJson,errorResponse)
                      break;  
    
              }
            }
          }
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount(): Promise<void> {
        const singleTask = JSON.parse(localStorage.getItem("task") || "{}") 
        this.setState({
           singleTask, group_name: singleTask.title,
             taskId: singleTask.task_serial_id, 
            description: singleTask.description,
             selectedLevel:singleTask.permissions, 
             selectedPipelines: singleTask.pipelines,
              selectedUsers: singleTask.accounts })
    }
    handleTaskDetailsRes=(responseJson:any,errorRes: string)=>{
      if(responseJson.data){
           this.setState({
            singleTask:responseJson.data.attributes, group_name: responseJson.data.attributes.title,
              taskId: responseJson.data.attributes.task_serial_id, 
             description: responseJson.data.attributes.description,
              selectedLevel:responseJson.data.attributes.permissions, 
              selectedPipelines: responseJson.data.attributes.pipelines,
               selectedUsers: responseJson.data.attributes.accounts,selectedCategory:"Communications" })
      }
        }
        taskDetailsAPI=async ()=>{
          let taskID= localStorage.getItem("taskID")
          const token = localStorage.getItem("token")
          const header = {
            "Content-Type": configJSON.validationApiContentType,
            "token": token
          };
          const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
          );
      
          this.editTaskDetailsCallId = requestMessage.messageId;
      
          requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.taskGroupListApiEndPoint+"/"+taskID
          );
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
          );
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getMethod
          );
      
          runEngine.sendMessage(requestMessage.id, requestMessage);
      
        }
    handleEditTaskGroupRes=(responseJson:any,errorRes: string)=>{
      if (!responseJson.error) {
           toast.success("Task group edited successfully", { style: { fontSize: "1.6rem" } })
           localStorage.setItem("taskID",responseJson.id)
      this.props.navigation.replace("Detailtaskgroup")
  
      }
    }
    handlePipelineListRes=(responseJson:any,errorRes: string)=>{
        if(responseJson){
            const transformedData = responseJson.map((item: { id: string; pipeline_name: string;
                 pipeline_serial_id: string; }) => ({
                id: String(item.id),
                type: "drop_down_pipeline",
                attributes: {
                  id: item.id,
                  pipeline_name: item.pipeline_name,
                  label: item.pipeline_serial_id 
                }
              }));
              this.setState({allPipelines:transformedData})
        }
    }
    handleIndividualUserListRes=(responseJson:any,errorRes: string)=>{
        if(responseJson.data){
            const updatedRes = responseJson.data.map((item: { attributes: { first_name: string;
               last_name: string; }; id: string; }) => ({
                ...item,
                attributes: {
                  ...item.attributes,
                  label: `${item.attributes.first_name} ${item.attributes.last_name}`,
                  id: Number(item.id)
                }
              }));
                    
            this.setState({allUsers:updatedRes,})
          }
  
    }
    handleEditLevelsListRes=(responseJson:any,errorResponse:any)=>{
        if(responseJson.data){
          this.setState({levels:responseJson.data,})
        }
      }
      apiCall = async (data: any) => {
        const { contentType, method, endPoint, body, type } = data;
        const token = await localStorage.getItem('token');
        const header = {
          "Content-Type": contentType,
          token: token
        };
    
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          endPoint
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          method
        );
    
        body && type != 'formData' ?
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
          )
    
          : requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            body
          );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
      };
    
      editLevelsListAPI=async ()=>{
        this.editLevelsListCallId = await this.apiCall({
          contentType: configJSON.validationApiContentType,
          method: configJSON.getMethod,
          endPoint: configJSON.levelListApiEndPoint,
        })
      }
      individualUserListAPI=async ()=>{
        this.individualUserListCallId = await this.apiCall({
          contentType: configJSON.validationApiContentType,
          method: configJSON.getMethod,
          endPoint: configJSON.individualUserApiEndPoint,
        })
      }
      pipelineListAPI=async ()=>{
        this.pipelineListCallId = await this.apiCall({
          contentType: configJSON.validationApiContentType,
          method: configJSON.getMethod,
          endPoint: configJSON.pipelineListApiEndPoint,
        })
      }
    handleEditTaskChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        let validateVal = value.replace(/^\s+/, "").replace(/\s{2,}/g, " ");
        this.setState((prevState) => {
            const updatedErrors = { ...prevState.editErrors };

            if (name === "group_name" && validateVal.length <= 100) {
                delete updatedErrors.group_name;
            }

            if (name === "description" && validateVal.length <= 300) {
                delete updatedErrors.description;
            }

            return {
                ...prevState,
                [name]: validateVal,
                editErrors: updatedErrors,
            };
        });
    };

    handleChangeEditTaskAutoComplete = (name: string) => (event: any, newValue: string) => {
      this.setState((prev) => {
        const updatedErrors :any= { ...prev.editErrors };
    
        if (newValue.length > 0) {
          delete updatedErrors[name];
        }
    
        return {
          ...prev,
          [name]: newValue,
          editErrors: updatedErrors, 
        };
      });
    }
    validateTaskEditFields = (group_name: string, task_description: string,selectedCategory?: string,
      selectedLevel?:LevelType[], selectedPipelines?:PipelinesDetails[], selectedUsers?:AccountsDetails[]
    ) => {
        const errors: EditTaskErrorType = {};

        if (!group_name.trim()) {
          errors.group_name = "Task group name is required.";
      } else if (group_name.length > 100) {
          errors.group_name = "Task group name cannot exceed 100 characters.";
      }
    
      if (!task_description.trim()) {
          errors.description = "Task group description is required.";
      } else if (task_description.length > 300) {
          errors.description = "Task group description cannot exceed 300 characters.";
      }
        if (!selectedLevel || selectedLevel.length === 0) {
          errors.selectedLevel = "At least one level must be selected.";
      }
    
      if (!selectedPipelines || selectedPipelines.length === 0) {
          errors.selectedPipelines = "At least one associated pipeline must be selected.";
      }
    
      if (!selectedUsers || selectedUsers.length === 0) {
          errors.selectedUsers = "At least one individual must be selected.";
      }
      if (!selectedCategory) {
        errors.selectedCategory = "At least one category must be selected.";
    }

    
        return errors;
    };

    handleEditTaskGroup = () => {
        const { group_name, description, selectedLevel, selectedPipelines, selectedUsers,selectedCategory } = this.state
        const addStageErrorMessages = this.validateTaskEditFields(group_name, description,selectedCategory, selectedLevel, selectedPipelines, selectedUsers);

        if (Object.keys(addStageErrorMessages).length > 0) {
            this.setState({ editErrors: addStageErrorMessages });
            return;
        }

        let selectedLevelIds=selectedLevel.map((item:LevelType) => item.attributes.id);
        let selectedPipelineIds=selectedPipelines.map((item: PipelinesDetails ) => item.attributes.id);
        let selectedUserIds=selectedUsers.map((item:AccountsDetails) => item.attributes.id);

        let taskID= localStorage.getItem("taskID")

        const token = localStorage.getItem("token")
        const header = {
          "Content-Type": configJSON.validationApiContentType,
          "token": token
        };
        const httpBody = {
            "task": {
                "title": group_name,
                "description": description,
                "priority": "medium",
                "account_ids": selectedUserIds,
                "pipeline_ids":selectedPipelineIds,
                "permission_ids":selectedLevelIds ,
            }
        }
    
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.editTaskGroupCallId = requestMessage.messageId;
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.createTaskGroupApiEndPoint+"/"+taskID
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(httpBody)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.patchMethod
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);

    }
    isEditTaskComplete = () => {
        const { group_name, description } = this.state
        return (
            group_name &&
            description
        );
    }


    handleEditSelectedCategory=(category: string) => {
      this.setState((prev) => {
        const updatedErrors :EditTaskErrorType= { ...prev.editErrors };
    
        if (category) {
          delete updatedErrors.selectedCategory;
        }
    
        return {
          selectedCategory: category,
          editErrors: updatedErrors,
        };
      });
      }
    // Customizable Area End
}
