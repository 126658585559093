import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
interface PipelineCreateErrorType {
  selectedLevels?: string;
  selectedTasks?: string;
  selectedUsers?: string;
  associatedClinic?: string;
  pipeline_name?: string,
  description?: string,
}
export interface TaskType {
  id:string
  type:string
  attributes: {
    id:number
    task_name: string
  label: string
  }
}
export interface LevelType {
  id:string
  type:string
  attributes: {
    id:number
  permission_level: string
  label: string}
}
export interface UserType {
  id:string
  type:string
  attributes: {
    id:number
  user_name: string
  label: string,
  source: string
  }
}
import { toast } from "react-toastify"
import { generatePipeline, generateRandomUsers } from "./utils/generatePipeline";
import { AccountsDetails } from "../../../blocks/cftasklistener3/src/Cftasklistener3Controller";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  pipeline_name: string;
  description: string;
  createPipelineErrors: PipelineCreateErrorType
  selectedTasks: TaskType[]
  createTasksData: any[]
  associatedClinic: string
  createLevelsData: LevelType[]
  createUsersData: UserType[]
  selectedUsers: UserType[]
  selectedLevels: LevelType[]
  isLoading:boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CreatePipelineController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  createPipelineAPICallId: string="";
  levelsListCallId: string="";
  pipelineIndividualUserListCallId: string="";
  pipelineTaskGroupListCallId: string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.levelsListAPI()
    this.pipelineIndividualUserListAPI()
    this.pipelineTaskGroupListAPI()
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      pipeline_name: '',
      description: '',
      createPipelineErrors: {
      },
      selectedTasks: [],
      selectedUsers: [],
      selectedLevels: [],
      createTasksData:  [
        {
            "id": "2",
            "type": "permission",
            "attributes": {
                "id": 2,
                "label": "task 1",
                "task_name": "task 1"            
              }
        },
        {
            "id": "3",
            "type": "task",
            "attributes": {
                "id": 3,
                "label": "task 2",
                "task_name": "task 2"
            }
        },
        {
            "id": "4",
            "type": "task",
            "attributes": {
                "id": 4,
                "label": "task 3",
                "task_name": "task 3"
            }
        }
    ],
      createLevelsData: [],
      createUsersData:[
        {
            "id": "1",
            "type": "user",
            "attributes": {
                "id": 1,
                "label": "John Smith",
                "user_name": "John Smith",
                "source": "https://randomuser.me/api/portraits/men/1.jpg"
            }
        },
        {
            "id": "2",
            "type": "user",
            "attributes": {
                "id": 2,
                "label": "Michael Johnson",
                "user_name": "Michael Johnson",
                "source": "https://randomuser.me/api/portraits/men/2.jpg"
            }
        },
        {
            "id": "3",
            "type": "user",
            "attributes": {
                "id": 3,
                "label": "James Brown",
                "user_name": "James Brown",
                "source": "https://randomuser.me/api/portraits/men/3.jpg"
            }
        },
        {
            "id": "4",
            "type": "user",
            "attributes": {
                "id": 4,
                "label": "Robert Williams",
                "user_name": "Robert Williams",
                "source": "https://randomuser.me/api/portraits/men/4.jpg"
            }
        },
        {
            "id": "5",
            "type": "user",
            "attributes": {
                "id": 5,
                "label": "David Jones",
                "user_name": "David Jones",
                "source": "https://randomuser.me/api/portraits/men/5.jpg"
            }
        },
        {
            "id": "6",
            "type": "user",
            "attributes": {
                "id": 6,
                "label": "Mary Miller",
                "user_name": "Mary Miller",
                "source": "https://randomuser.me/api/portraits/women/1.jpg"
            }
        },
        {
            "id": "7",
            "type": "user",
            "attributes": {
                "id": 7,
                "label": "Jennifer Davis",
                "user_name": "Jennifer Davis",
                "source": "https://randomuser.me/api/portraits/women/2.jpg"
            }
        },
        {
            "id": "8",
            "type": "user",
            "attributes": {
                "id": 8,
                "label": "Patricia Garcia",
                "user_name": "Patricia Garcia",
                "source": "https://randomuser.me/api/portraits/women/3.jpg"
            }
        },
        {
            "id": "9",
            "type": "user",
            "attributes": {
                "id": 9,
                "label": "Linda Martinez",
                "user_name": "Linda Martinez",
                "source": "https://randomuser.me/api/portraits/women/4.jpg"
            }
        }
    ]
    ,
      associatedClinic: '',
      isLoading:true
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      )

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      )
     let errorResponse  =message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      )
      if (apiRequestCallId) {
        switch (apiRequestCallId) {
          case this.createPipelineAPICallId:
            this.handleCreatePipelineRes(responseJson, errorResponse);
            break;
          case this.levelsListCallId:
            this.handleLevelsListRes(responseJson,errorResponse)
            case this.pipelineIndividualUserListCallId:
              this.handlePipelineIndividualUserListRes(responseJson, errorResponse);
            break;
            case this.pipelineTaskGroupListCallId:
              this.handlePipelineTaskGroupListRes(responseJson, errorResponse);
        }
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start

  handlePipelineTaskGroupListRes=(responseJson:any,errorRes: string)=>{
    if(responseJson.data)
      { 
        const updatedRes = responseJson.data.map((item: { attributes: { title: string; }; id: any; }) => ({
          ...item,
          attributes: {
            ...item.attributes,
            label:item.attributes.title,
            id: Number(item.id)
          }
        }));

        this.setState({isLoading:false,createTasksData:updatedRes})
      }
      
  }
  handlePipelineIndividualUserListRes=(responseJson:any,errorRes: string)=>{
          if(responseJson.data){
              const updatedRes = responseJson.data.map((item: AccountsDetails) => ({
                  ...item,
                  attributes: {
                    ...item.attributes,
                    label: `${item.attributes.first_name} ${item.attributes.last_name}`,
                    id: Number(item.id)
                  }
                }));
                      
              this.setState({createUsersData:updatedRes,isLoading:false})
            }else{
              this.setState({createUsersData:[],isLoading:false})
            }
    
      }
  handleCreatePipelineRes=(responseJson:any,errorRes: string)=>{
    if (!responseJson.error) {
responseJson.data.attributes.users = [
  {
    id: "1",
    type: "user",
    attributes: {
      id: 1,
      label: "John Smith",
      user_name: "John Smith",
      source: "https://randomuser.me/api/portraits/men/1.jpg"
    }
  }
];

responseJson.data.attributes.taskGroup = [
  {
    id: "4",
    type: "task",
    attributes: {
      id: 4,
      label: "task 3",
      task_name: "task 3"
    }
  }
];
responseJson.data.attributes.clinic_name="clinic1"

localStorage.setItem("pipelineID",responseJson.data.id)
  this.props.navigation.navigate('PipelineDetail', { pipelineData:responseJson.data })
         toast.success("Pipeline created successfully", { style: { fontSize: "1.6rem" } })

    }else{
        Object.entries(responseJson.error).forEach(([field, messages]:any) => {
            messages.forEach((message: any) => {
                toast.error(`${field} ${message}`, { style: { fontSize: "1.6rem" } })
              });
        });
    
    

    }
  }
  handleLevelsListRes=(responseJson:any,errorResponse:any)=>{
    if(responseJson.data){
      this.setState({createLevelsData:responseJson.data,isLoading:false})
    }
  }

  levelsListAPI=async ()=>{
    const token = localStorage.getItem("token")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.levelsListCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.levelsListApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  async componentDidMount() {
    super.componentDidMount()
  }
  apiCall = async (data: any) => {
    const { contentType, method, 
      endPoint, body, type } = data;
    const token = await localStorage.getItem('token');
    const header = {"Content-Type": contentType,
       token: token};
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),endPoint);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),method);
    body && type != 'formData'
     ? requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
     JSON.stringify(body))
      : requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
      body);
    runEngine.sendMessage(requestMessage.id,
       requestMessage);
    return requestMessage.messageId;
  };

  pipelineTaskGroupListAPI=async ()=>{
    this.pipelineTaskGroupListCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.getMethod,
      endPoint: configJSON.taskGroupListApiEndPoint,
    })
  }

      pipelineIndividualUserListAPI=async ()=>{
        this.pipelineIndividualUserListCallId = await this.apiCall({
          contentType: configJSON.validationApiContentType,
          method: configJSON.getMethod,endPoint: configJSON.individualUserApiEndPoint})
      }

  handleCreateChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    let sanitizedValue = value.replace(/^\s+/, "").replace(/\s{2,}/g, " ");

    this.setState((prevState) => {
      const updatedErrors = { ...prevState.createPipelineErrors };

      if (name === "pipeline_name" && sanitizedValue.length <= 100) {
        delete updatedErrors.pipeline_name;
      }

      if (name === "description" && sanitizedValue.length <= 300) {
        delete updatedErrors.description;
      }

      return {
        ...prevState,
        [name]: sanitizedValue,
        createPipelineErrors: updatedErrors,
      };
    });
  };
  validateCreatePipelineFields = (pipeline_name: string, description: string,selectedLevels?:LevelType[], selectedTasks?:TaskType[], selectedUsers?:UserType[], associatedClinic?:string) => {
    const errors: PipelineCreateErrorType = {
    };

    if (!pipeline_name.trim()) {
      errors.pipeline_name = "Pipeline name is required.";
  } else if (pipeline_name.length > 100) {
      errors.pipeline_name = "Pipeline name cannot exceed 100 characters.";
  }

  if (!description.trim()) {
      errors.description = "Pipeline description is required.";
  } else if (description.length > 300) {
      errors.description = "Pipeline description cannot exceed 300 characters.";
  }

  if (!selectedLevels || selectedLevels.length === 0) {
      errors.selectedLevels = "At least one level must be selected.";
  }

  if (!selectedTasks || selectedTasks.length === 0) {
      errors.selectedTasks = "At least one task group must be selected.";
  }

  if (!selectedUsers || selectedUsers.length === 0) {
      errors.selectedUsers = "At least one individual must be selected.";
  }

  if (!associatedClinic) {
      errors.associatedClinic = "At least one associated clinic must be selected.";
  }

  return errors;

  };

  handleCreatePipeline = async (event: any) => {
    event.preventDefault()
    const { pipeline_name, description, associatedClinic, selectedLevels, selectedTasks, selectedUsers } = this.state
    const pipelineErrors = this.validateCreatePipelineFields(pipeline_name, description, selectedLevels, selectedTasks, selectedUsers, associatedClinic);
    if (Object.keys(pipelineErrors).length > 0) {

      this.setState({ createPipelineErrors: pipelineErrors });
      return;
    }
    let selectedLevelIds=selectedLevels.map((item) => item.attributes.id);
    const token = localStorage.getItem("token")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": token
    };
    const httpBody = {
        "pipeline": {
            "pipeline_name":pipeline_name ,
            "description": description,
            "status": "to_do",
            "permission_ids": selectedLevelIds
        }
    } 

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createPipelineAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createPipelineApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  handleChangeCreateAutoComplete = (name: string) => (event: any, newValue: any) => {
    this.setState((prev) => {
      const updatedErrors :any= { ...prev.createPipelineErrors };
  
      if (newValue.length > 0) {
        delete updatedErrors[name];
      }
  
      return {
        ...prev,
        [name]: newValue,
        createPipelineErrors: updatedErrors, 
      };
    });
  };  handleCreateClinicSelect = (clinic: string) => {
    this.setState((prev) => {
      const updatedErrors :any= { ...prev.createPipelineErrors };
  
      if (clinic) {
        delete updatedErrors.associatedClinic;
      }
  
      return {
        associatedClinic: clinic,
        createPipelineErrors: updatedErrors,
      };
    });
    }
  // Customizable Area End
}
