import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
interface CreateTaskErrorType {
    group_name?: string
    description?: string
    selectedLevel?: string;
    selectedPipelines?: string;
    selectedUsers?: string;
    selectedCategory?:string
}
import { generateRandomUsersWithLabel, generateRandomPipelines, generateTasks, TaskDataType,UserTypeWithLabel } from "./utils/generateTasks";
import { toast } from "react-toastify"
import { LevelType } from "../../../blocks/cfpipelines1/src/CreatePipelineController";
import { AccountsDetails,PipelinesDetails } from "./Cftasklistener3Controller";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    group_name: string
    taskId: string
    levels: LevelType[]
    selectedLevel:LevelType[]
    selectedUsers: AccountsDetails[]
    users: AccountsDetails[]
    allPipelines: PipelinesDetails[]
    selectedPipelines:PipelinesDetails[]
    description: string
    createErrors: CreateTaskErrorType
    isLoading:boolean
    selectedCategory: string

    // Customizable Area End
}

export interface SS {
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export default class CreatetaskgroupController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    levelsListCallId: string="";
    individualUserListCallId: string="";
    pipelineListCallId: string="";
    createTaskGroupCallId: string="";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        // Customizable Area End

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            group_name: '',
            taskId: 'Auto Generated',
            levels: [],
            selectedLevel: [],
            selectedUsers: [],
            users: [],
            allPipelines: [],
            selectedPipelines: [],
            description: '',
            createErrors: {},
            isLoading:true,
            selectedCategory: '',
            // Customizable Area End
        };

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        this.levelsListAPI()
        this.individualUserListAPI()
        this.pipelineListAPI()
        // Customizable Area End
    }
    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
              getName(MessageEnum.RestAPIResponceDataMessage)
            )
      
            let responseJson = message.getData(
              getName(MessageEnum.RestAPIResponceSuccessMessage)
            )
           let errorResponse  =message.getData(
              getName(MessageEnum.RestAPIResponceErrorMessage)
            )
            if (apiRequestCallId) {
              switch (apiRequestCallId) {
                case this.createTaskGroupCallId:
                    this.handleCreateTaskGroupRes(responseJson, errorResponse);
                    break;        
                case this.individualUserListCallId:
                  this.handleIndividualUserListRes(responseJson, errorResponse);
                  break;
                case this.levelsListCallId:
                  this.handleEditLevelsListRes(responseJson,errorResponse)
                  break;
                  case this.pipelineListCallId:
                    this.handlePipelineListRes(responseJson,errorResponse)
                    break;
  
              }
            }
          }
        // Customizable Area End
    }

    // Customizable Area Start
    
    handleCreateTaskGroupRes=(responseJson:any,errorRes: string)=>{
        if(responseJson.id){
            localStorage.setItem("taskID",responseJson.id)
            this.setState({ isLoading:false})
            toast.success("Task Created successfully", { style: { fontSize: '1.6rem' } })
        this.props.navigation.navigate('Detailtaskgroup')

        }else{
          this.setState({ isLoading:false})
            Object.entries(responseJson).forEach(([field, messages]:any) => {
                messages.forEach((message: any) => {
                    toast.error(`${field} ${message}`, { style: { fontSize: "1.6rem" } })
                  });
            });
    
        }
    }
    handlePipelineListRes=(responseJson:any,errorRes: string)=>{
        if(responseJson){
            const transformedData = responseJson.map((item: { id: number; pipeline_name: string;
                 pipeline_serial_id: string; }) => ({
                id: String(item.id),
                type: "drop_down_pipeline",
                attributes: {
                  id: item.id,
                  pipeline_name: item.pipeline_name,
                  label: item.pipeline_serial_id 
                }
              }));
              this.setState({allPipelines:transformedData,isLoading:false})
        }
    }
    handleIndividualUserListRes=(responseJson:any,errorRes: string)=>{
        if(responseJson.data){
            const updatedRes = responseJson.data.map((item: AccountsDetails) => ({
                ...item,
                attributes: {
                  ...item.attributes,
                  label: `${item.attributes.first_name} ${item.attributes.last_name}`,
                  id: Number(item.id)
                }
              }));
                    
            this.setState({users:updatedRes,isLoading:false})
          }
  
    }
    handleEditLevelsListRes=(responseJson:any,errorResponse:any)=>{
        if(responseJson.data){
          this.setState({levels:responseJson.data,isLoading:false})
        }
      }
      apiCall = async (data: any) => {
        const { contentType, method, endPoint, body, type } = data;
        const token = await localStorage.getItem('token');
        const header = {"Content-Type": contentType, token: token};
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),endPoint);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),method);
    
        body && type != 'formData'
         ? requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),JSON.stringify(body))
          : requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),body);
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
      };
    
      levelsListAPI=async ()=>{
        this.levelsListCallId = await this.apiCall({
          contentType: configJSON.validationApiContentType,method: configJSON.getMethod,
          endPoint: configJSON.levelListApiEndPoint})
      }
      individualUserListAPI=async ()=>{
        this.individualUserListCallId = await this.apiCall({contentType: configJSON.validationApiContentType,
          method: configJSON.getMethod,endPoint: configJSON.individualUserApiEndPoint})
      }
      pipelineListAPI=async ()=>{this.pipelineListCallId = await this.apiCall({
          contentType: configJSON.validationApiContentType,method: configJSON.getMethod,
          endPoint: configJSON.pipelineListApiEndPoint})
      }
    handleCreateTaskChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        let validateValue = value.replace(/^\s+/, "").replace(/\s{2,}/g, " ");
        this.setState((prevState) => {
            const updatedErrors = { ...prevState.createErrors };

            if (name === "group_name" && validateValue.length <= 100) {
                delete updatedErrors.group_name;
            }

            if (name === "description" && validateValue.length <= 300) {
                delete updatedErrors.description;
            }

            return {
                ...prevState,
                [name]: validateValue,
                createErrors: updatedErrors,
            };
        });
    };

    handleChangeCreateTaskAutoComplete = (name: string) => (event: any, newValue: string) => {
      this.setState((prev) => {
        const updatedErrors :any= { ...prev.createErrors };
    
        if (newValue.length > 0) {
          delete updatedErrors[name];
        }
    
        return {
          ...prev,
          [name]: newValue,
          createErrors: updatedErrors, 
        };
      });
  
    }
    validateTaskCreateFields = (group_name: string, task_description: string,
      selectedCategory?: string,
      selectedLevel?:LevelType[], selectedPipelines?:PipelinesDetails[], selectedUsers?:AccountsDetails[],) => {
        const errors: CreateTaskErrorType = {};
        if (!group_name.trim()) 
        { errors.group_name = "Task group name is required.";} 
        else if (group_name.length > 100) 
        { errors.group_name = "Task group name cannot exceed 100 characters.";}
      
        if (!task_description.trim()) 
        {errors.description = "Task group description is required.";}
     else if (task_description.length > 300)
        {errors.description = "Task group description cannot exceed 300 characters.";}
        if (!selectedLevel || selectedLevel.length === 0) 
        {errors.selectedLevel = "At least one level must be selected.";}
      if (!selectedPipelines || selectedPipelines.length === 0)
         { errors.selectedPipelines = "At least one associated pipeline must be selected.";}
      if (!selectedUsers || selectedUsers.length === 0) {
          errors.selectedUsers = "At least one individual must be selected.";}
          if (!selectedCategory) {
            errors.selectedCategory = "At least one category must be selected.";
        }
      
        return errors;

    };

    handleCreateTaskGroup = () => {

        const { group_name, description,selectedCategory, selectedLevel, selectedPipelines, selectedUsers } = this.state
        const addStageErrorMessages = this.validateTaskCreateFields(group_name, description,selectedCategory, selectedLevel, selectedPipelines, selectedUsers,);
        if (Object.keys(addStageErrorMessages).length > 0) {
            this.setState({ createErrors: addStageErrorMessages });
            return;
        }
        let selectedLevelIds=selectedLevel.map((item: LevelType) => item.attributes.id);
        let selectedPipelineIds=selectedPipelines.map((item: PipelinesDetails) => item.attributes.id);
        let selectedUserIds=selectedUsers.map((item: AccountsDetails) => item.attributes.id);

        const token = localStorage.getItem("token")
        const header = {
          "Content-Type": configJSON.validationApiContentType,
          "token": token
        };
        const httpBody = {
            "task": {
                "title": group_name,
                "description": description,
                "priority": "medium",
                "account_ids": selectedUserIds,
                "pipeline_ids":selectedPipelineIds,
                "permission_ids":selectedLevelIds ,
            }
        }
    
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.createTaskGroupCallId = requestMessage.messageId;
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.createTaskGroupApiEndPoint
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(httpBody)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.postMethod
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
    
    }
    handleCreateSelectedCategory=(category: string) => {
      this.setState((prev) => {
        const updatedErrors :CreateTaskErrorType= { ...prev.createErrors };
    
        if (category) {
          delete updatedErrors.selectedCategory;
        }
    
        return {
          selectedCategory: category,
          createErrors: updatedErrors,
        };
      });
      }
    // Customizable Area End
}
