import React from 'react';

// Customizable Area Start
import Customtaskgroup from "./utils/Customtaskgroup.web"
import { LoadingSpinner } from '../../../blocks/cfpipelines1/src/CreatePipeline.web';
// Customizable Area End

import CreatetaskgroupController, {
  Props,
} from './CreatetaskgroupController';

export default class Createtaskgroup extends CreatetaskgroupController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {isLoading, group_name, taskId, selectedLevel, createErrors, levels, users, selectedUsers, allPipelines, selectedPipelines, description } = this.state
    return (
      <> {
              isLoading ?
         <LoadingSpinner />
              :
      <Customtaskgroup
        navigation={this.props.navigation}
        id="Create Task"
        group_name={group_name}
        taskId={taskId}
        title="Create Task Group"
        handleChange={this.handleCreateTaskChange}
        handleAutoComplete={this.handleChangeCreateTaskAutoComplete}
        allLevels={levels}
        selectedLevel={selectedLevel}
        users={users}
        selectedUsers={selectedUsers}
        pipelines={allPipelines}
        selectedPipelines={selectedPipelines}
        selectedCategory={""}
        handleCustomSelectedCategory={this.handleCreateSelectedCategory}
        description={description}
        handleCreateEdit={this.handleCreateTaskGroup}
        createEditErrors={createErrors}
        buttonText="Create Task Group"
      />}
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
// Customizable Area End
