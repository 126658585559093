export const Pinned = require("../assets/pinned.png")
export const Pipelines = require("../assets/pipelines.png")
export const Stages = require("../assets/stages.png")
export const Deals = require("../assets/deals.png")
export const Setting = require("../assets/setting.png")
export const Pipeline = require("../assets/pipeline.png")
export const Stage = require("../assets/stage.png")
export const DoubleTick = require("../assets/doubleTick.png")
export const DiagonalArrow = require("../assets/diagonalArrow.png")
export const Bell = require("../assets/bell.png")
