import React from "react";

import { Input, Button, Typography } from '@builder/component-library';

// Customizable Area Start
import {
  Box,
  IconButton,
  Button as MuiButton,
  Typography as MuiTypography,
  Divider,
  Modal,
  MenuItem,
  TextField,
  Checkbox,
  Menu,
  Autocomplete,
  InputAdornment,
  Grid,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import FilterListIcon from '@mui/icons-material/FilterList';
import PaginationComponent from '../../cfpipelines1/src/utils/PaginationComponent';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { formatDateTimeTable } from '../../cfpipelines1/src/utils/generatePipeline';
import { TaskDataType } from "./utils/generateTasks";
import CloseIcon from '@mui/icons-material/Close';
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from "dayjs"
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { SearchBar } from "../../../blocks/cfpipelines1/src/Cfpipelines1.web";
import  {TaskDetails} from "./Cftasklistener3Controller";
import { LoadingSpinner } from "../../../blocks/cfpipelines1/src/CreatePipeline.web";
import { Checked, UnChecked } from "../../../blocks/cfpipelines1/src/assets";
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
const icon = <CheckBoxOutlineBlankIcon style={{ height: "2rem", width: "2rem" }} />;
const checkedIcon = <CheckBoxIcon style={{ height: "2rem", width: "2rem", color: "#1A4A42", borderRadius: "20rem" }} />;
// Customizable Area End

import Cftasklistener3Controller, {
  Props,
  configJSON,
} from "./Cftasklistener3Controller";

export default class Cftasklistener3 extends Cftasklistener3Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderTaskColumnTypography = (flex: number, text: string | number, field: string) => {
    const { sortField, sortOrder } = this.state;
    let sortIcon = null;
    if (sortField === field) {
      sortIcon = sortOrder === 'asc' ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />;
    } else {
      sortIcon = <ArrowDropDownIcon />;
    }

    return (
      <MuiTypography variant="h6" data-test-id={"column-typography"+text} onClick={() => this.handleSortTasks(field)} style={{ display: "flex", alignItems: "center", fontSize: '1.2rem', flex: flex, fontWeight: 700, color: '#3E3E3E' }}>{text}{text == "Actions" ? "" : sortIcon}</MuiTypography>
    )
  }
  renderRowTypography = (flex: number, text: string | number, taskData: TaskDetails) => {
    return (
            <Box sx={{display:"flex",flexDirection:"column",flex:flex}}>
      
      <MuiTypography variant="h6" data-test-id={"rowTypography"+text} 
      onClick={() => this.handleTaskDetailView(taskData)} 
      style={{
        fontSize: '1.1rem',whiteSpace: 'normal',
        wordWrap: 'break-word',wordBreak: 'break-word',
        flex: flex, fontWeight: 500, cursor: 'pointer', color: '#203447'
      }}>
        {text}
      </MuiTypography>
      <Menu data-test-id={"tootipView"+text}
                        anchorEl={this.state.openToolTip}
                        open={Boolean(this.state.openToolTip)}
                        anchorOrigin={{
                          vertical: "bottom",horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",horizontal: "right",
                        }}                
                        onClose={this.handleCloseToolTip}
                        slotProps={{paper: {
                            sx: {
                              borderRadius: "12px",
                              boxShadow: "none", border: "1px solid #1A4A4233",
                              minWidth: "120px", marginTop:0.5
                            },
                          },
                        }}>
                        <MenuItem  sx={{fontWeight:"500", 
                          fontSize:"1.1rem", color: "#0F172A",
                          textAlign: "right", justifyContent: "flex-end" }}>
                            Duplicate</MenuItem>
                        <MenuItem sx={{fontWeight:"500",fontSize:"1.1rem", color: "#0F172A",textAlign: "right", justifyContent: "flex-end" }}
                        data-test-id={"toolTipEdit"+text}
                        onClick={()=>this.handleTaskDetailsNavigate()}>Edit</MenuItem>
                        <MenuItem 
                         sx={{fontWeight:"500",fontSize:"1.1rem", color: "#0F172A",textAlign: "right", justifyContent: "flex-end" }}
                         data-test-id={"toolTipDelete"+text} onClick={()=>this.handleDeleteTaskToggle()}>
                          Delete
                        </MenuItem>
                      </Menu>
            </Box>
    )
  }

  renderDatePicker = (text: string, value: dayjs.Dayjs | null, onChangeText: string, minDate:dayjs.Dayjs | null = null) => {
    return (
      <Box><MuiTypography style={{
        fontSize: '1.2rem', fontWeight: 400, color: '#1A4A42', marginBottom: "0.2rem"
      }}>{text}</MuiTypography>
        <DatePicker
        disableFuture
          value={value}
          minDate={minDate}
          format="DD-MM-YYYY"
          slots={{
            textField: (params) => (
              <TextField
                {...params}
                placeholder="Select Date"
                sx={{fontSize: '1.2rem'}}
                InputProps={{...params.InputProps,
                  sx: {
                    fontWeight: 400,fontSize: "1.4rem",
                    borderRadius: "0.8rem",
                    '& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': {
                      padding: '0.6rem 0 0.6rem 1rem',fontSize: "1.2rem",
                    }},
                }}
              />
            ),
          }}
          slotProps={{
            popper: {
              sx: {
                '& .MuiPaper-root': {
                  minWidth: '30rem', border: '0.1rem solid #E2E8F0',
                  marginTop: '0.2rem',borderRadius: '0.8rem'
                },
                '& .MuiTypography-root': {
                  fontSize: '1.8rem !important',
                },
                '& .MuiPickersArrowSwitcher-button': {
                  fontSize: '2rem !important',
                  '& .MuiSvgIcon-root': {
                    fontSize: '2rem !important', },
                },
                '& .css-dplwbx-MuiPickersCalendarHeader-label': {
                  fontSize: '1.6rem'
                },
                '& .css-1tkx1wf-MuiSvgIcon-root-MuiPickersCalendarHeader-switchViewIcon': {
                  width: '2rem',height: '2rem'
                },
                '& .css-13m8yui-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected)': {
                  border: 'none',fontWeight: 700
                },

                '& .css-1b2k5pm-MuiPickersYear-yearButton': {
                  fontSize: '1.4rem'
                },
                '& .MuiDayCalendar-weekDayLabel': {
                  fontSize: '1.4rem !important',fontWeight: 400
                },
              },
            },
            day: {
              sx: {
                fontSize: '1.2rem',},
            },
          }}
          onChange={(newValue) => this.handleDateChange(onChangeText, newValue)}
          data-test-id="date_picker"
        />
      </Box>
    )
  }

  renderFilterDateSection = (title: string, name: string, inputState: dayjs.Dayjs | null, onChangeText: string, inputState2: dayjs.Dayjs | null, onChangeText2: string) => {
    const { openRows, dates } = this.state
    const minDateForToPicker = dates.createdFrom? dayjs(dates.createdFrom): null;
    return (
      <Box style={{ marginBottom: '0.8rem' }}>
        <Box display="flex" justifyContent='space-between' alignItems='center' flexWrap='wrap'>
          <MuiTypography variant="h6" sx={{ fontWeight: 700, color: "#292524", fontSize: '1.4rem' }}>{title}</MuiTypography>
          <IconButton data-test-id={"down_up_arrow"+name} onClick={() => this.handleRowClick(name)}>
            {openRows[name] ? 
            <KeyboardArrowUpIcon style={{ color: "#0F172A", fontSize: '1.8rem' }} />
           : <KeyboardArrowDownIcon style={{ color: "#0F172A", fontSize: '1.8rem' }} />}
          </IconButton>
        </Box>
        {openRows[name] &&
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box
              sx={{
                paddingTop: '0',display: "flex",gap: "1rem"
              }}>
              {this.renderDatePicker("From", inputState,
                 onChangeText)}
              {this.renderDatePicker("To", inputState2,
                 onChangeText2, minDateForToPicker)}
            </Box>
          </LocalizationProvider>
        }
      </Box>
    )
  }
  renderCheckBox = (name: string, text: string, checkValue: boolean) => {
    return (
      <Box
        display="flex"
        alignItems="center"
        sx={{
          border: "0.1rem solid #1A4A4233",borderRadius: "0.8rem",
          boxSizing: "border-box",flex: "1 1 calc(50% - 1rem)"

        }}
      >
        <Checkbox
          checked={checkValue}
          onChange={this.handleLevelChange}
          icon={icon}checkedIcon={checkedIcon}
          name={name}
        />
        <MuiTypography
          sx={{
            fontSize: "1.2rem",fontWeight: 400,
            color: "#0F172A"
          }}
        >
          {text}
        </MuiTypography>
      </Box>
    );
  }

  renderCategoryFilterSection = (title: string, name: string) => {
    const { openRows } = this.state
    return (
      <Box sx={{ marginBottom: '0.8rem'}}>
        <Box display="flex" justifyContent='space-between' alignItems='center' flexWrap='wrap'>
        <MuiTypography variant="h6" sx={{ fontWeight: 700, color: "#292524", fontSize: '1.4rem' }}>{title}</MuiTypography>
        <IconButton data-test-id="down_up_arrow" onClick={() => this.handleRowClick(name)}>
          {openRows[name] ? <KeyboardArrowUpIcon style={{ color: "#0F172A", fontSize: '1.8rem' }} /> : <KeyboardArrowDownIcon style={{ color: "#0F172A", fontSize: '1.8rem' }} />}
        </IconButton>
      </Box>
        {openRows[name] &&
           
           <Box sx={{ flex: 1 }}>
           <Autocomplete
               data-test-id="selectcategory"
               placeholder="Search category"
               options={["Communications","Category"]}
               getOptionLabel={(option) => option}
               renderInput={(params) => (
                   <TextField
                       {...params}
                       data-test-id="categoryTextField"
                       placeholder="Select Category"
                       style={{ marginBottom: "0.8rem" }}
                       InputProps={{
                           ...params?.InputProps,
                           endAdornment: (
                               <InputAdornment position="end"
                                   sx={{
                                       "& .MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-fullWidth MuiInputBase-formControl MuiInputBase-adornedEnd MuiAutocomplete-inputRoot css-1hiurh-MuiInputBase-root-MuiOutlinedInput-root": {
                                           paddingRight: "0 !important"
                                       }
                                   }}
                               >
                                   <KeyboardArrowDownIcon  style={{
                                       color: '#0F172A',
                                       cursor: 'pointer',
                                       fontSize: '1.8rem'
                                   }} />
                               </InputAdornment>
                           ),
                           sx: {
                               fontWeight: 400,
                               paddingLeft: "0.3rem !important",
                               fontSize: "1.2rem",borderRadius: "0.5rem",
                               "& .css-18bmrv6-MuiButtonBase-root-MuiChip-root": {
                                   fontSize: "1.2rem",
                                   paddingRight: "0",
                                   display: 'none'
                               },
                               "& .MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-fullWidth MuiInputBase-formControl MuiInputBase-adornedEnd MuiAutocomplete-inputRoot css-1hiurh-MuiInputBase-root-MuiOutlinedInput-root": {
                                   paddingRight: "0 !important"},
                               "& .MuiAutocomplete-tag":{
                                   display:"none"}
                           },
                       }}
                       sx={{
                           "& .MuiOutlinedInput-root": {
                               padding: "0 0.4rem",},'& .MuiSelect-icon': {
                               display: 'none',},
                           "& .MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-fullWidth MuiInputBase-formControl MuiInputBase-adornedEnd MuiAutocomplete-inputRoot css-1hiurh-MuiInputBase-root-MuiOutlinedInput-root, .MuiAutocomplete-hasClearIcon.css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root": {
                               paddingRight: "0 !important"},
                           "& .MuiInputAdornment-root": {
                               marginRight: '1rem',},
                           "&.MuiInputBase-root.MuiOutlinedInput-root": {
                               paddingRight: "0 !important", },
                           "& .MuiInputBase-adornedEnd": {
                               paddingRight: "0 !important",},
                           "& .MuiAutocomplete-inputRoot": {
                               paddingRight: "0 !important", },
                       }}
                   />
               )}
               renderOption={(props, option, { selected }) => (
                   <MenuItem
                       {...props}
                       data-test-id="renderOptioncategory"
                       key={option}
                       sx={{fontSize: "1.2rem",padding: "0.6rem 0",fontWeight: selected ? 600 : 400,
                           color: selected ? "#1A4A42" : "#292524","&:hover": { backgroundColor: "#f0f0f0" },
                       }}>
                       {option}
                   </MenuItem>
               )} noOptionsText="No category found"
           />
       </Box>
        }
      </Box>
    )
  }

  renderPermissionFilterSection = (title: string, name: string) => {
    const { openRows } = this.state
    return (
      <Box><Box display="flex" justifyContent='space-between' alignItems='center' flexWrap='wrap'>
        <MuiTypography variant="h6" sx={{ fontWeight: 700, color: "#292524", fontSize: '1.4rem' }}>{title}</MuiTypography>
        <IconButton data-test-id="down_up_arrow" onClick={() => this.handleRowClick(name)}>
          {openRows[name] ? <KeyboardArrowUpIcon style={{ color: "#0F172A", fontSize: '1.8rem' }} /> : <KeyboardArrowDownIcon style={{ color: "#0F172A", fontSize: '1.8rem' }} />}
        </IconButton>
      </Box>
        {openRows[name] &&
          <Box
            display="flex"
            sx={{ gap: "1rem", flexWrap: 'wrap' }}
          >
            {this.renderCheckBox("level1", "Level 1", this.state.levels.level1)}
            {this.renderCheckBox("level2", "Level 2", this.state.levels.level2)}
            {this.renderCheckBox("level3", "Level 3", this.state.levels.level3)}
            {this.renderCheckBox("level4", "Level 4", this.state.levels.level4)}
          </Box>
        }
      </Box>
    )
  }

  renderTasksComponent = (Children: (pppp:TaskDetails[])=>React.ReactNode) => {
    const { currentPageIndex, requiredPageSize } = this.state
    const sortedRows = this.handleSortTaskData()
    const paginatedRows = sortedRows.slice(currentPageIndex * requiredPageSize, (currentPageIndex + 1) * requiredPageSize);
    const startIndex = currentPageIndex * requiredPageSize + 1;
    const endIndex = Math.min((currentPageIndex + 1) * requiredPageSize, sortedRows.length);
    const totalResults = sortedRows.length;
    const isAllSelected = paginatedRows.every((row) => this.state.openRows[row.id] === true);
    const anySelected = paginatedRows.some((row) => {
      return this.state.openRows[row.id] === true;
    });
    const selectedCount = Object.values(this.state.openRows).filter(value => value === true).length;
    return (
      <Box sx={{ ...accountGroupContainer }}>
        <Grid container  style={{ display: "flex", marginBottom: '1.6rem', justifyContent: "space-between", alignItems: 'center', flexWrap: 'nowrap' }}>
          <Grid item xs={4.6}>
          <MuiTypography variant="h6" sx={{ fontWeight: 700, fontSize: '1.5rem', color: "#292524" }}>
            Task Groups
          </MuiTypography>
          </Grid>
          <Grid item container  xs={8} spacing={1}  justifyContent={"space-between"}>
          <Grid item xs={12} sm={5} md={5} alignItems={"center"}>
          <SearchBar handleSearchField={this.handleSearchTaskGroup} />
          </Grid>
          <Grid item xs={5.5} sm={2.8}  display="flex" alignItems="center">
          <MuiButton onClick={this.handleTaskCreate} sx={{
              backgroundColor: '#1A4A42',
              color: 'white',
              padding: '0.5rem 2.4rem',
              height:"2.6rem",
              fontWeight: 700,
              borderRadius: '0.6rem',
              textTransform: 'none',
              fontSize: '1rem',
              whiteSpace: 'nowrap',
              '&:hover': {
                backgroundColor: '#1B5C54',
                color: '#fff',
              }
            }}>
              Create Task Group
            </MuiButton>
          </Grid>
          <Grid item xs={5.5} sm={2.8} display="flex" alignItems="center">
          <MuiButton 
            onClick={this.toggleCategoryModal} 
            sx={{
              backgroundColor: '#1A4A42',
              color: 'white',
              padding: '0.5rem 2.2rem',
              height:"2.6rem",
              fontWeight: 700,
              borderRadius: '0.6rem',
              textTransform: 'none',
              fontSize: '1rem',
              whiteSpace: 'nowrap',
              '&:hover': {
                backgroundColor: '#1B5C54',
                color: '#fff',
              }
            }}>
              Create Category
            </MuiButton>
          </Grid>
          <Grid item xs={1} sm={0.6} display="flex" alignItems="center" justifyContent={"flex-end"} >
          <Box style={{ position: 'relative' }}>
              <FilterListIcon data-test-id="toggleFilter" onClick={this.toggleFilterModal} style={{ ...iconStyle, cursor: "pointer", fontSize: "2rem" }} />
              {this.state.isFilterModal &&
                <Box
                  sx={filterModalStyle}
                >
                  <Box display="flex" justifyContent="space-between" alignItems="center" >
                    <MuiTypography variant="h6" sx={{ fontWeight: 700, color: "#292524", fontSize: '1.4rem' }}>Filters</MuiTypography>
                    <IconButton onClick={() => this.toggleFilterModal()} >
                      <CloseIcon data-test-id="closeFilter" style={{ color: "#292524", fontSize: '1.8rem' }} />
                    </IconButton>
                  </Box>
                  {this.renderFilterDateSection("Date Created", "created_at", this.state.dates.createdFrom, "createdFrom", this.state.dates.createdTo, "createdTo")}
                  {this.renderFilterDateSection("Last Edited", "updated_at", this.state.dates.editedFrom, "editedFrom", this.state.dates.editedTo, "editedTo")}
                 
                    {this.renderCategoryFilterSection("Category", "Category")}

                  {this.renderPermissionFilterSection("Permission Level", "permission_level")}
                  
                  <Box display="flex" justifyContent="flex-end" gap={1} mt={2}>
                    <MuiButton onClick={this.resetFilters} variant="contained" sx={{
                      backgroundColor: '#1A4A421A',
                      color: '#1A4A42',
                      padding: '0.8rem',
                      fontWeight: 700,
                      height: '3rem',
                      borderRadius: '0.8rem',
                      textTransform: 'none',
                      fontSize: '1rem',
                      width: '9rem',
                      border:"1px solid #1A4A42",
                      '&:hover': {
                        backgroundColor: '#F5FEFD',
                      }
                      
                    }}>
                      Reset
                    </MuiButton>
                    <MuiButton variant="contained" onClick={this.handleApplyTaskFilter} sx={{
                      backgroundColor: '#1A4A42',
                      color: 'white',
                      padding: '0.8rem',fontWeight: 700,height: '3rem',borderRadius: '0.8rem',
                      textTransform: 'none',fontSize: '1rem',width: '9rem',
                      '&:hover': {backgroundColor: '#1B5C54',}
                    }}> Apply
                    </MuiButton>
                  </Box>
                </Box>}
            </Box>
          </Grid>

         </Grid>
        </Grid>
        <Box display="flex" flexDirection='column' gap={1}>
          <Box display="flex" flexDirection={"column"} style={{ backgroundColor: '#29252414', padding: '0rem 1rem', borderRadius: ' 0.8rem' }}>
                 <Box sx={{ display: "flex", width: "100%" }}>
                <Box sx={{ flex: 2, paddingLeft: "1rem" }}>
                  <Checkbox data-test-id="selectAll"
                    sx={{ '&:hover': { bgcolor: 'transparent' } }} disableRipple color="default"
                    checked={isAllSelected} onChange={()=>this.handleSelectAll(paginatedRows)}
                    checkedIcon={<img src={Checked} style={{ width: '1.6rem' }} />} icon={<img src={UnChecked} style={{ width: '1.6rem' }} />}
                    inputProps={{ 'aria-label': 'Select All Checkbox' }}
                  />
                </Box>
            {this.renderTaskColumnTypography(4, 'Task Group ID', 'id')}
            {this.renderTaskColumnTypography(5, 'Name', 'name')}
            {this.renderTaskColumnTypography(5, 'Category', 'category')}
            {this.renderTaskColumnTypography(8, 'Members', 'members')}
            {this.renderTaskColumnTypography(2, 'Actions', 'actions')}

          </Box>
          {anySelected && <Box  sx={{display:"flex",marginLeft:8,marginBottom:1, flexDirection:"row"}}>
                              <Box sx={{display:"flex",flexDirection:"row",margin:1}} >
                              <MuiTypography sx={{marginRight:1}}>
                                {selectedCount}</MuiTypography>
                              <MuiTypography>Selected</MuiTypography>
                              </Box>
                              <Divider orientation="vertical" flexItem />
                              <MuiTypography sx={{margin:1,color:"#1A4A42"}}>
                                Deselect
                              </MuiTypography >
                              <Divider orientation="vertical" flexItem />
                              <MuiTypography data-test-id="deleteSelected" 
                              onClick={()=>this.handleDeleteTaskToggle()}
                              sx={{margin:1,color:"#E46760", "&:hover":{cursor:"pointer"} }}>  
                        Delete Selected</MuiTypography>
            </Box>}
          </Box>
          {Children(paginatedRows)}
        </Box>
        <PaginationComponent 
        currentPageIndex={currentPageIndex} 
        requiredPageSize={requiredPageSize} 
        startIndex={startIndex} 
        endIndex={endIndex} 
        totalResults={totalResults} 
        handlePageChange={this.handlePageChangeTask} />
      </Box>
    )
  }
  renderTaskList = (paginatedRows: TaskDetails[]) => {
    return <>
      {paginatedRows.length > 0 ? paginatedRows.map((row) => (
        <Box key={row.id} style={{ padding: '0rem 1rem', borderBottom: '0.1rem solid #1A4A421A', alignItems: 'center' }}>
          <Box display='flex' style={{ alignItems: 'center' }}>
                       <Box sx={{ flex:2,paddingLeft:"1rem" }}>
                                               <Checkbox
                                                   sx={{'&:hover': { bgcolor: 'transparent' },}}
                                                   data-test-id="singleCheckbox"
                                                   checkedIcon={<img src={Checked} style={{width:"1.6rem"}} />}
                                                   icon={ <img src={UnChecked} style={{width:"1.6rem"}}  />}
                                                   disableRipple color="default"
                                                   checked={this.state.openRows[row.id]|| false}
                                                   onChange={()=>this.handleRowClick(row.id)} inputProps={{ 'aria-label': 'Checkbox demo' }}                                               />
                                           </Box>
            {this.renderRowTypography(4, `${row.attributes.task_serial_id}`, row)}
            {this.renderRowTypography(5, row.attributes.title, row)}
            {this.renderRowTypography(5, "Communications", row)}
            <Box  marginTop={0} marginBottom={1} sx={{flex:8}} display='flex' flexDirection='row' gap={1}>
                <Box display='flex' gap={1} alignItems='center'>
                  {row.attributes.permissions.map((u) => (
                    <Box key={u.attributes.label} display='inline-flex' gap={1} justifyContent={"center"} alignItems='center'
                      sx={{
                        border: '0.1rem solid #1A4A4266',
                        backgroundColor: "white",
                        padding:"0.4rem",
                        borderRadius: "0.7rem",
                        fontSize: "1rem",
                        fontWeight: 400,
                        color: "#203447",
                        maxWidth: '2.4rem',
                        minWidth: '2.4rem',
                        boxSizing: 'border-box'
                      }}
                    >
                      <MuiTypography style={{ fontSize: '1rem', fontWeight: 800 }}>
                        {u.attributes.label.charAt(0).toUpperCase() + u.attributes.label.slice(5)}
                      </MuiTypography>
                    </Box>
                  ))}
                </Box>
                <Box display='flex' gap={1} alignItems='center'>
                  {row.attributes.accounts.slice(0, 4).map((u,index) => (
                    <Box key={u.attributes.id} display='inline-flex' alignItems='center'  sx={{}}>
                      <img
                        src={"https://randomuser.me/api/portraits/men/1.jpg"}
                        alt='i,mm'
                        style={{
                          borderRadius: '50%',
                          height: '2.4rem',
                          width: '2.4rem',
                          objectFit: 'cover',
                          marginRight:-19,
                          zIndex:10,borderColor: "white",
                          border: '2px solid white'
                        }}
                      />
                      
                    </Box>
                  ))}
                 {
                   row.attributes.accounts.length>4 &&  <Box sx={{marginRight:-19, background:"#C1C7D0", zIndex:10, border: '0.1rem solid #C1C7D0', borderRadius: '50%', height: '2.4rem', width: '2.4rem', display: "flex", alignItems: 'center', justifyContent: 'center' }}>
                   <MuiTypography style={{
                      fontSize: '1.1rem', fontWeight: 600, color: "#fff"
                    }}>
                      +{row.attributes.accounts.length - 4}
                    </MuiTypography>
                  </Box>}
                </Box>
              
            </Box>
            <Box style={{ flex: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <IconButton data-test-id="tooltip-btn-test" sx={addButtonStyle} 
            style={{ padding: "0.4rem" }} 
            onClick={(event) => { this.handleOpenTooltip(event,row) }}
            >
                <MoreHorizOutlinedIcon fontSize="medium" />
            </IconButton>
            </Box>
          </Box>
        </Box>
      )) : <Box display='flex' justifyContent='center' alignItems='center'><MuiTypography variant="h5">No Task Groups Found</MuiTypography></Box>}</>
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        { 
        this.state.isLoading ?<LoadingSpinner />
        :
        this.renderTasksComponent(this.renderTaskList)}
        <Modal
          open={this.state.isDeleteTask}
          onClose={this.toggleTaskDeleteModal}
          aria-labelledby="delete-stage-modal"
          aria-describedby="delete-stage-form"
        >
          <Box sx={tasksModalStyle}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
              <MuiTypography variant="h6" sx={{ fontWeight: 700, color: "#292524", fontSize: '1.4rem' }}>Delete Selected Task Groups</MuiTypography>
              <IconButton onClick={this.toggleTaskDeleteModal}>
                <CloseIcon style={{ height: "2rem", width: '2rem' }} />
              </IconButton>
            </Box>

            <Divider sx={{ backgroundColor: "#1A4A4233", marginBottom: "1rem" }} />
            <Box sx={{ display: "flex"}}>
              <MuiTypography  style={{ fontSize: '1rem',fontWeight:700 }}>
                {`Type "DELETE" below in the input field to proceed.`}</MuiTypography>
            </Box>
            <TextField
            data-test-id="deleteTextInput"
      placeholder="Enter value"
      onChange={(event)=>this.handleDeleteChange(event)}
      fullWidth
      sx={{
        marginTop:"2rem",
        "& .MuiOutlinedInput-root": {
          borderColor: "#94A3B8",height:"3.2rem",borderRadius:"0.8rem",
          "& fieldset": {borderColor: "", 
          },
          "&:hover fieldset": {borderColor: "#94A3B8", 
          },
          "&.Mui-focused fieldset": {borderColor: "teal",
          },
        },
        "& .MuiInputBase-input::placeholder": {color: "#94A3B8", fontWeight:700
        },
      }}
    />
{this.state.deleteError.length>0 &&
 <MuiTypography sx={{ marginTop: '0.5rem', fontSize: '1rem' }} color="red" variant="body2">
  {this.state.deleteError}
  </MuiTypography>
}    

            <Divider sx={{ backgroundColor: "#1A4A4233", marginBottom: "1rem",marginTop:"2rem" }} />

            <Box display="flex" justifyContent="flex-end" gap={2} mt={3}>
              <MuiButton onClick={this.toggleTaskDeleteModal} variant="contained" sx={{
                backgroundColor: '#1A4A421A',
                color: '#1A4A4299',
                padding: '0.8rem 0.8rem',
                fontWeight: 700,
                borderRadius: '0.6rem',
                textTransform: 'none',
                fontSize: '1rem',
                width: '7rem',
                '&:hover': {
                  backgroundColor: '#F5FEFD',
                }
              }}>Cancel
              </MuiButton>
              <MuiButton 
              data-test-id="confirmDelete"
                onClick={()=>this.deleteSelectedPipelines()} variant="contained" sx={{
                backgroundColor: '#1A4A42',
                color: 'white',padding: '0.8rem 0.8rem',
                fontWeight: 700,borderRadius: '0.8rem',
                textTransform: 'none', fontSize: '1rem',
                width: '7rem',
                '&:hover': { backgroundColor: '#1B5C54',}
              }}>Delete
              </MuiButton>
            </Box>
          </Box>
        </Modal>
        <Modal
          data-test-id="delete-pipeline-modal" open={this.state.isCategryModalOpen}
          onClose={this.toggleCategoryModal} aria-labelledby="edit-stage-modal"
          aria-describedby="edit-stage-form">
          <Box sx={tasksModalStyle}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
              <MuiTypography  sx={{fontSize:"1.4rem", fontWeight: 700, }}>Create New Category</MuiTypography>
               <IconButton onClick={this.toggleCategoryModal}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Divider sx={{ backgroundColor: "#1A4A4233", marginBottom: "1rem" }} />
                    <Box sx={{display:"flex"}}>
                    <MuiTypography variant="body1" style={{ margin: "0.5rem 0", color: "#1A4A42", fontWeight: 700, fontSize: '1rem' }}>
                    Category
                    </MuiTypography>
                    </Box>
                    <TextField
                        name="pipeline_name" data-test-id="pipeline_name"
                        value={""}  fullWidth
                        multiline
                        InputLabelProps={{ shrink: false }}
                        placeholder="Enter category name"
                        style={{ marginBottom: "0.8rem" }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                padding: '0.8rem! important',borderRadius:"0.5rem",
                            }
                        }}
                        inputProps={{
                            sx: {
                                fontWeight: 400,
                                fontSize: '1rem',whiteSpace: 'normal',
                                wordWrap: 'break-word',wordBreak: 'break-word',
                            },
                        }}
                    />

            <Divider sx={{ backgroundColor: "#1A4A4233", marginBottom: "1rem",marginTop:"2rem" }} />
            <Box display="flex" justifyContent="flex-end" gap={2} mt={3}>
              <MuiButton onClick={this.toggleCategoryModal} variant="contained" sx={{
                backgroundColor: '#1A4A421A',
                color: '#1A4A4299',
                padding: '0.8rem 0.8rem',fontWeight: 700,borderRadius: '0.6rem',
                textTransform: 'none',width: "7rem", fontSize:"1rem",
                '&:hover': { backgroundColor: '#1A4A421A',
                } }}>
                Cancel
              </MuiButton>
              <MuiButton 
                onClick={this.toggleCategoryModal}
                variant="contained" sx={{
                backgroundColor: '#1A4A42',color: 'white',padding: '0.8rem 0.8rem',
                fontWeight: 700,borderRadius: '0.6rem',textTransform: 'none',
                width: "7rem",fontSize:"1rem",
                '&:hover': {backgroundColor: '#1A4A42',
                },
              }}>
                Create
              </MuiButton>
            </Box>
          </Box>
        </Modal>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const accountGroupContainer = {
  padding: "1.5rem 1.5rem 1rem",
  borderRadius: "1.2rem",
  backgroundColor: "#fff",
  fontFamily: "Raleway, sans-serif",
  boxSizing: "border-box",
  border: "1px solid #ccc",
};

const dividerStyle = {
  margin: "1.5rem 0",
  backgroundColor: "#1A4A4233",
};
const iconStyle = {
  backgroundColor: "#1A4A424D",
  color: "#1A4A42",
  padding: "0.5rem",
  borderRadius: "0.6rem",
  height:"1.6rem",
  width:"1.7rem"
};
const addButtonStyle = {
  backgroundColor: "#1A4A424D",
  color: "#1A4A42",
  padding: "0.8rem",
  borderRadius: "0.6rem",
  marginRight:"0.8rem"
};
const deleteIconStyle = {
  backgroundColor: "#EF444433",
  color: "#DC2626",
  padding: "0.45rem",
  borderRadius: "0.6rem",
};
const tasksModalStyle = {
  position: 'absolute',
  borderRadius: '0.8rem',
  boxShadow: 2,
  transform: 'translate(-50%, -50%)',
  width: '40rem',
  top: '50%',
  left: '50%',
  bgcolor: 'background.paper',
  p: 3,
};
const filterModalStyle = {
  position: 'absolute',
  top: '100%',
  right: '10%',
  width:{xs:"45rem",sm: '28rem'},
  bgcolor: 'background.paper',
  borderRadius: '0.8rem',
  boxShadow: 2,
  zIndex: '99',
  p: 2,
}
// Customizable Area End
