import React from "react";
// Customizable Area Start
import { styled } from "@mui/styles";
import { Box,Button, Typography, Grid } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CallMergeRoundedIcon from '@mui/icons-material/CallMergeRounded';
import RuleDetailsController, {
  Props,
} from "./RuleDetailsController";
// Customizable Area End


export default class RuleDetails extends RuleDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderValueTypography = (text: string, textAlign: any,fontWeight?:string,
    fontSize?:string,color?:string) => {
    return (
      <Typography  sx={{ margin: "0.5rem", textAlign, color:color, fontSize: fontSize ,fontWeight:fontWeight}}>
        {text}
      </Typography>
    )
  }
  renderParameterHeadings=()=>{
    return(<Grid container  sx={{flex: 1, justifyContent: "center", alignItems: "center", }}>
        <Grid item xs={3} sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                {this.renderValueTypography("Parent Parameter", "left", "700", "0.8rem", "#1A4A42")}
        </Grid>
        <Grid item xs={3} sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                {this.renderValueTypography("Child Parameter", "left", "700", "0.8rem", "#1A4A42")}
        </Grid>
        <Grid item xs={3} sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                {this.renderValueTypography("Condition", "left", "700", "0.8rem", "#1A4A42")}
        </Grid>
        <Grid item xs={3} sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                {this.renderValueTypography("Input", "left", "700", "0.8rem", "#1A4A42")}
        </Grid>
    </Grid>)
  }

   renderParameterValues = (data: (string|string[] )[]) => {
    return (
      <Grid container sx={{mb:1.5, flex: 1, justifyContent: "flex-start", alignItems: "flex-start" }}>
        {data.map((item, index) => (
          <Grid 
            key={index} 
            item 
            xs={3} 
            sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}
          >
            <Box sx={{ textAlign: "center" }}>
              {Array.isArray(item) 
                ? item.map((line, i) => (
                    <Typography key={i} sx={{ fontSize: "1rem", color: "#292524" }}>
                      {line}
                    </Typography>
                  ))
                : <Typography sx={{ fontSize: "1rem", color: "#292524" }}>{item}</Typography>
              }
            </Box>
          </Grid>
        ))}
      </Grid>
    );
  };
  
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
        <PipelineRuleContainer>
            <Box display="flex" justifyContent='space-between' marginBottom={2}>
          <Box display='flex' alignItems='center' gap={1}>
          <ArrowBackIcon data-test-id="backbtn" style={{ cursor: "pointer", fontSize: '2rem' }} onClick={this.props.navigation.goBack} />
                    {this.renderValueTypography("View Rule", "left", "700", "1.5rem", "#292524")}
          </Box>
          <Box display="flex" gap={1} sx={{width:"30%",justifyContent:"flex-end"}}>
            <Button onClick={()=>this.handleNavigateEdit()} data-test-id="edit_rule" sx={{
              backgroundColor: '#1A4A42',
              color: 'white',
              width:"7rem", 
              height:"3rem", 
            fontWeight: 700,
              borderRadius: '0.8rem',
              textTransform: 'none',
              fontSize: '1rem',
              whiteSpace: 'nowrap',
              '&:hover': {
                backgroundColor: '#1B5C54',
                color: '#fff',
              }
            }}>
              Edit
            </Button>
            <Button 
            data-test-id="btnDelete"
            sx={{
              backgroundColor: '#EF444433',
              color: '#DC2626',
              width:"7rem",  
              height:"3rem",
              fontWeight: 700,
              borderRadius: '0.8rem',
              textTransform: 'none',
              fontSize: '1rem',
              whiteSpace: 'nowrap',
              '&:hover': {
                backgroundColor: '#EF444455',
              },
              
            }}
            >
              Delete
            </Button>
          </Box>
        </Box>
            <Box>
                <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                    <Box sx={{ flex: 1 }}>
                        {this.renderValueTypography("Rule Name","left","700","1rem","#1A4A42")}
                        {this.renderValueTypography("Deal is Created", "left", "400", "1.1rem", "#292524")}
                    </Box>
                    <Box sx={{ flex: 1 }}>
                    {this.renderValueTypography("Rule ID","left","700","1rem","#1A4A42")}
                    {this.renderValueTypography("#R0000001", "left", "400", "1.1rem", "#292524")}
                    </Box>
                </Box>
                <Box data-test-id="btnParameter" display={"flex"} marginBottom={2} marginTop={2} flexDirection={"row"}
                    sx={{ background: "#1A4A4280", borderRadius: 1.5, justifyContent: "space-between" }} >
                    {this.renderValueTypography("Triggers", "left", "700", "1.2rem", "#F4F4F4F4")}
                </Box>
                <Grid container>
                    <Grid item md={12} sm={12} xs={12}>
                            <React.Fragment >
                            <Box sx={{ p: 1, borderRadius: 2, border: "1px solid #1A4A4233", mb: 2 }}>
                                    {this.renderValueTypography("Trigger 1", "left", "700", "1rem", "#1A4A42")}
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={12}>
                                            {this.renderValueTypography("Field is Changed", "left", "700", "1.1rem", "#292524")}
                                        </Grid>
                                    </Grid>
                                    <React.Fragment >
                                            {this.renderValueTypography("Parameter 1", 
                                                "center", "700", "1.1rem", "#292524")}
                                            {this.renderParameterHeadings()}
                                            {this.renderParameterValues([
                                        "Session DB Table","Session Number",
                                        "Equal to",
                                        ["0001"]])}

                                        </React.Fragment>
                                        <React.Fragment >
                                            {this.renderValueTypography("Parameter 2", "center", "700", "1.1rem", "#292524")}
                                            {this.renderParameterHeadings()}
                                    {this.renderParameterValues([
                                        "Session DB Table",
                                        "Session Date","Date is X from now",
                                        "24 Hrs"])}

                                        </React.Fragment>
                                </Box>
                                <Box display="flex" justifyContent="center" gap={2} sx={{ marginBottom: 2 }}>
                                    <Button
                                    disabled
                                        sx={{
                                            textTransform: "none",
                                            fontWeight: "700",
                                            width: "15%",
                                            background: "#1A4A42E5",
                                            color: "#fff",
                                            borderRadius: 2,
                                            paddingLeft: 1,
                                            paddingRight: 1,
                                            "&.Mui-disabled": {
                                                background: "#1A4A42E5", 
                                                color: "#fff", 
                                                opacity: 1,
                                              },
                                          
                                        }}
                                        startIcon={<CallMergeRoundedIcon />}
                                        data-test-id={`btn-And`}
                                    >
                                        Add AND Trigger
                                    </Button>
                                </Box>
                                <Box sx={{ p: 1, borderRadius: 2, border: "1px solid #1A4A4233", mb: 2 }}>
                                    {this.renderValueTypography("Trigger 2", "left", "700", "1rem", "#1A4A42")}
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={12}>
                                            {this.renderValueTypography("Field is Changed", "left", "700", "1.1rem", "#292524")}
                                        </Grid>
                                    </Grid>
                                        <React.Fragment >
                                            {this.renderValueTypography("Parameter 1", "center", "700", "1.1rem", "#292524")}
                                            {this.renderParameterHeadings()}
                                            {this.renderParameterValues([
                                        "Patient Database",
                                        "Birthday",
                                        "Date is between",
                                        ["MM/DD/YYYY", "MM/DD/YYYY"]])}
                                        </React.Fragment>
                                </Box>
                            </React.Fragment>
                    </Grid>
                </Grid>
                <Box data-test-id="btnParameter" display={"flex"} marginBottom={2} flexDirection={"row"}
                    sx={{ background: "#1A4A4280", borderRadius: 1.5, justifyContent: "space-between" }} >
                    {this.renderValueTypography("Actions", "left", "700", "1.2rem", "#F4F4F4F4")}
                </Box>
                <Grid container>
                    <Grid item md={12} sm={12} xs={12}>
                            <Box sx={{ p:1, borderRadius: 2, border: "1px solid #1A4A4233", mb: 2 }}>
                                <Grid container display={"flex"} spacing={2} alignItems={"center"}>
                                    <Grid item xs={7.5} >
                                        {this.renderValueTypography("Action 1", "left", "700", "1rem", "#1A4A42")} {this.renderValueTypography("Send a SMS", "left", "700", "1.1rem", "#292524")}
                                    </Grid>
                                    <Grid item xs={4}>
                                        {this.renderValueTypography("Waiting Time", "left", "700", "1rem", "#1A4A42")}
                                        {this.renderValueTypography("Immediately", "left", "400", "1.1rem", "#292524")}
                                    </Grid>
                                </Grid>
                                    <React.Fragment >
                                    {this.renderValueTypography("Parameter 1", 
                                                "center", "700", "1.1rem", "#292524")}
                                    {this.renderParameterHeadings()}
                                    {this.renderParameterValues(["Lorem Ipsum","Lorem Ipsum","Lorem Ipsum"])}
                                        </React.Fragment>
                            </Box>
                    </Grid>
                </Grid>
                {this.renderValueTypography("Associated Pipeline ID","left","700","1rem","#1A4A42")}
                <Box display={"flex"}>
                    <Box sx={{ border: "1px solid #1A4A4233", margin: "0.5rem", padding: "0rem 0.1rem", borderRadius: 2 }}>
                        {this.renderValueTypography("#P00000001", "left", "400", "1rem", "#292524")}
                    </Box>
                    <Box sx={{ border: "1px solid #1A4A4233", margin: "0.5rem", padding: "0rem 0.1rem", borderRadius: 2 }}>
                        {this.renderValueTypography("#P00000001", "left", "400", "1rem", "#292524")}
                    </Box>
                </Box>
            </Box>
        </PipelineRuleContainer >
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const PipelineRuleContainer = styled("div")({
  padding: "2rem 2rem 1rem",
  borderRadius: "1.2rem",
  backgroundColor: "#fff",
  border: "1px solid #ccc",
  fontFamily: "Raleway, sans-serif",
  boxSizing: "border-box",
});

// Customizable Area End
