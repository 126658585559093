import React, { Component } from "react";
import TopNav from "../../../navigationmenu/src/TopNav.web";
import { styled } from "@mui/styles";
import { Box, TextField, MenuItem, Typography, Divider, Autocomplete, InputAdornment } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import { LevelType, TaskType, UserType } from "../CreatePipelineController";

interface CreateEditPipelineErrorsType {
    pipeline_name?: string
    description?: string
    selectedLevels?: string;
  selectedTasks?: string;
  selectedUsers?: string;
  associatedClinic?: string;
}
interface Props {
    navigation: { goBack: () => void };
    id: string;
    title: string;
    pipeline_name: string;
    handleChangeValue: (e: any) => void;
    description: string;
    handleCreateEditPipeline: (e: any) => void
    handleAutoComplete: (id: string) => (e: any, newValue: any) => void
    allLevels: LevelType[];
    allUsers: UserType[]
    allTasks: TaskType[]
    customSelectedLevels: LevelType[]
    customSelectedUsers: UserType[]
    customSelectedTasks:TaskType[]
    customAssociatedClinic: string
    handleCustomSelectClinic: (clinic: string) => void
    isCustomFormComplete?: () => boolean | string
    buttonText: string
    customPipelinesError: CreateEditPipelineErrorsType
}

interface S {
    searchQuery: string
    open: boolean
    selectedClinic:string
    filteredOptions: string[]
    openStates: {
        selectedUsers: boolean;
        selectedTasks: boolean;
        selectedLevels: boolean;
        selectedClinic:boolean
    };
}

interface SS {
}

export default class Custompipelinecomp extends Component<Props, S> {
    constructor(props: any) {
        super(props);
        this.state = {
            searchQuery: '',
            open: false,
            selectedClinic: "",
            filteredOptions: ["Global", "Clinic 1", "Clinic 2", "Clinic 3", "Clinic 4"],
            openStates: {
                selectedUsers: false,
                selectedTasks: false,
                selectedLevels: false,
                selectedClinic:false
            },
        }
    }
    handleDropdownToggle = (id: keyof S['openStates']) => {
        this.setState((prevState) => {
            const newOpenStates = {
              selectedUsers: false,
              selectedTasks: false,
              selectedLevels: false,
              selectedClinic:false
            };
            newOpenStates[id] = !prevState.openStates[id]; 
      
            return {
              openStates: newOpenStates,
            };
          });
    };
    renderVisibleBySection = (id: keyof S['openStates'], options: any,
         value: {attributes:{ label: string }}[], placeholder: string,error?:any) => {
        return (
            <Box style={{ flex: 1 }}>
                <Autocomplete
                    multiple
                    id={id}
                    options={options}
                    open={this.state.openStates[id]}
                    onChange={(event, newValue) => {this.handleDropdownToggle(id);
                        this.props.handleAutoComplete(id)(event, newValue)}}
                    isOptionEqualToValue={(option, value) => option?.attributes?.label === value?.attributes?.label}
                    value={value}
                    getOptionLabel={(option) => option?.attributes?.label}
                    renderOption={(props, option, { selected }) => {
                        return (
                            <li
                                {...props}
                                style={{
                                    fontSize: "1.4rem",
                                    backgroundColor: selected ? "#1A4A421A" : "transparent",
                                    fontWeight:selected?600: 400,
                                    color: selected ? "#1A4A42" : "#0F172A",
                                }}
                            >
                                {option?.attributes?.label}
                            </li>
                        );
                    }}
                    style={{ width: "100%" }}
                    renderInput={(params) => (
                        <TextField
                            style={{ marginBottom: "0.8rem", display: "flex", alignItems: 'center' }}
                            {...params}
                            onClick={()=>this.handleDropdownToggle(id)}
                            placeholder={placeholder}
                            InputLabelProps={{ shrink: false }}
                            InputProps={{
                                ...params?.InputProps,
                                endAdornment: (
                                    <InputAdornment position="end"
                                        sx={{
                                            "& .MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-fullWidth MuiInputBase-formControl MuiInputBase-adornedEnd MuiAutocomplete-inputRoot css-1hiurh-MuiInputBase-root-MuiOutlinedInput-root": {
                                                paddingRight: "0 !important"
                                            }
                                        }}
                                    >
                                        {id === "selectedUsers" || id==="selectedTasks" ? <SearchIcon onClick={(e: React.MouseEvent<SVGSVGElement, MouseEvent>)=>{e?.stopPropagation();this.handleDropdownToggle(id)}} sx={{
                                            color: '#1A4A4266',
                                            cursor: 'pointer',
                                            fontSize: '1.8rem',
                                            "& .MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-fullWidth MuiInputBase-formControl MuiInputBase-adornedEnd MuiAutocomplete-inputRoot css-1hiurh-MuiInputBase-root-MuiOutlinedInput-root": {
                                                paddingRight: "0 !important"

                                            }
                                        }} /> : <ArrowDropDownOutlinedIcon onClick={(e: React.MouseEvent<SVGSVGElement, MouseEvent>)=>{e?.stopPropagation();this.handleDropdownToggle(id)}} style={{
                                            color: '#64748B',
                                            cursor: 'pointer',
                                            fontSize: '2rem'
                                        }} />}
                                    </InputAdornment>
                                ),
                                sx: {
                                    fontWeight: 400,paddingLeft: "0.3rem !important",fontSize: "1.2rem",
                                    flexWrap: 'wrap',overflowY: 'auto',display: "flex",
                                    alignItems: 'center', borderRadius:"0.5rem","& .css-18bmrv6-MuiButtonBase-root-MuiChip-root": {
                                        fontSize: "1.4rem",paddingRight: "0", display: 'none',
                                    },
                                    "& .MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-fullWidth MuiInputBase-formControl MuiInputBase-adornedEnd MuiAutocomplete-inputRoot css-1hiurh-MuiInputBase-root-MuiOutlinedInput-root": {
                                        paddingRight: "0 !important"},
                                    "& .MuiAutocomplete-tag": {
                                        display: "none"}},
                            }}
                            sx={{
                                "& .MuiOutlinedInput-root": {
                                    padding: "0 0.4rem",
                                },
                                '& .MuiSelect-icon': {
                                    display: 'none',},
                                "& .MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-fullWidth MuiInputBase-formControl MuiInputBase-adornedEnd MuiAutocomplete-inputRoot css-1hiurh-MuiInputBase-root-MuiOutlinedInput-root, .MuiAutocomplete-hasClearIcon.css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root": {
                                    paddingRight: "0 !important"},
                                "& .MuiInputBase-adornedEnd": {
                                    paddingRight: "0 !important",},
                                "& .MuiAutocomplete-inputRoot": {
                                    paddingRight: "0 !important",},
                                "& .MuiInputAdornment-root": {
                                        marginRight: '1rem',},
                                "&.MuiInputBase-root.MuiOutlinedInput-root": {
                                        paddingRight: "0 !important",},

                            }}
                        />
                    )}
                />
                {error && (
                        <Typography sx={{ marginBottom: '1rem', marginTop: '-0.6rem', fontSize: '1rem' }} color="red" variant="body2">
                        {error}
                        </Typography>
                    )} 

                <Box sx={{ marginTop: "0.1rem", display: 'flex', flexWrap:"wrap", gap: '0.5rem', width: '100%' }}>
                    {value.length > 0 && value.map((item: any, index: number) => (
                        <Box
                            key={index}
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                border: '0.1rem solid #1A4A4266',
                                backgroundColor: "white",
                                borderRadius:"0.5rem",
                                padding: "0.7rem",
                                fontSize: "1.2rem",
                                fontWeight: 400,
                                color: "#292524",
                                justifyContent: 'space-between',
                                width:"15%"
                            }}
                        >
                            <Box display="flex" alignItems="center" gap={1}>
                                {(() => {
                                    if (item.attributes.source) {
                                        return (
                                            <img
                                                src={item.attributes.source}
                                                style={{ width: '16px', height: '16px', borderRadius: '50%', objectFit: 'cover' }}
                                            />
                                        );
                                    } else if (id === "selectedTasks") {
                                        return <PeopleAltOutlinedIcon style={{ fontSize: '1.4rem', color: "#292524" }} />;
                                    } else {
                                        return <CheckCircleOutlineIcon style={{ fontSize: '1.4rem', color: "#292524" }} />;
                                    }
                                })()}
                                {item?.attributes?.label}
                            </Box>

                            <CloseIcon
                                sx={{
                                    marginLeft: "0.8rem",
                                    fontSize: "1.4rem",
                                    cursor: "pointer",
                                    color: "#1A4A4266"
                                }}
                                data-test-id={"btnClose"+index}
                                onClick={() =>
                                    this.props.handleAutoComplete(id)(null, value.filter((v: any) => v !== item))
                                }
                            />
                        </Box>
                    ))}
                </Box>
            </Box>
        );
    };
    handleSelectClinic = (option: string) => {
        this.setState({
          selectedClinic: option,
          searchQuery: "",
          openStates: {
              selectedClinic: false,
              selectedUsers: false,
              selectedTasks: false,
              selectedLevels: false
          },
        });
        this.props.handleCustomSelectClinic(option);
      };
    render() {
        const { navigation, buttonText, customPipelinesError, title, pipeline_name, handleChangeValue, description, customAssociatedClinic, handleCreateEditPipeline, allLevels, allTasks, allUsers, customSelectedLevels, customSelectedTasks, customSelectedUsers, isCustomFormComplete } = this.props
        const allClinicsData = ["Global", "Clinic 1", "Clinic 2", "Clinic 3", "Clinic 4"]
        const filteredOptions = allClinicsData.filter((option) => option.toLowerCase().includes(this.state.searchQuery));
        return (
            <Custompipelinecomponent>
                <Box marginBottom={2}>
                    <Box display='flex' alignItems='center' style={{ gap: "1rem" }}>
                        <ArrowBackIcon data-test-id="backbtn" style={{ cursor: "pointer", fontSize: '2rem' }} onClick={navigation.goBack} />
                        <Typography variant="h6" sx={{ fontWeight: 700, fontSize: '1.5rem', color: "#292524" }}>{title}</Typography>
                    </Box>
                </Box>
                <Box component="form" noValidate autoComplete="off" onSubmit={handleCreateEditPipeline}>
                    <Box sx={{display:"flex"}}>
                    <Typography variant="body1" style={{ margin: "0.5rem 0", color: "#1A4A42", fontWeight: 700, fontSize: '1.1rem' }}>
                        Pipeline Name
                    </Typography>
                    <Typography variant="body1" style={{marginLeft:"0.3rem",  marginTop: "0.5rem", color: "red", fontWeight: 700, fontSize: '1.5rem' }}>
                        *
                    </Typography>
                    </Box>
                    <TextField
                        name="pipeline_name"
                        data-test-id="pipeline_name"
                        value={pipeline_name}
                        onChange={handleChangeValue}
                        fullWidth
                        multiline
                        InputLabelProps={{ shrink: false }}
                        placeholder="Type pipeline name"
                        style={{ marginBottom: "0.8rem" }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                padding: '0.9rem 0.6rem! important',
                                borderRadius:"0.5rem",
                            }
                        }}
                        inputProps={{
                            sx: {
                                fontWeight: 400,
                                fontSize: '1.2rem',
                                whiteSpace: 'normal',
                                wordWrap: 'break-word',
                                wordBreak: 'break-word',
                            },
                        }}
                    />
                    {customPipelinesError.pipeline_name && (
                        <Typography sx={{ marginBottom: '1rem', marginTop: '-0.6rem', fontSize: '1rem' }} color="red" variant="body2">{customPipelinesError.pipeline_name}</Typography>
                    )}
                    <Box sx={{display:"flex"}}>
                    <Typography variant="body1" style={{ margin: "0.5rem 0", color: "#1A4A42", fontWeight: 700, fontSize: '1.1rem' }}>
                        Description
                    </Typography>

                    <Typography variant="body1" style={{marginLeft:"0.3rem",  marginTop: "0.5rem", color: "red", fontWeight: 700, fontSize: '1.5rem' }}>
                        *
                    </Typography>

                    </Box>
                    <TextField
                        name="description"
                        data-test-id="pipeline_desc"
                        value={description}
                        onChange={handleChangeValue}
                        fullWidth
                        InputLabelProps={{ shrink: false }}
                        placeholder="Type pipeline description"
                        style={{ marginBottom: "0.8rem" }}
                        sx={{
                            '& .css-1obe73v-MuiInputBase-root-MuiOutlinedInput-root': {
                                padding: '0.9rem 0.6rem! important',
                                borderRadius:"0.6rem",
                            }
                        }}
                        multiline
                        rows={4}
                        inputProps={{
                            sx: {
                                fontWeight: 400,
                                fontSize: '1.2rem',
                                whiteSpace: 'normal',
                                wordWrap: 'break-word',
                                wordBreak: 'break-word',
                            },
                        }}
                    />
                    {customPipelinesError.description && (
                        <Typography sx={{ marginBottom: '1rem', marginTop: '-0.6rem', fontSize: '1rem' }} color="red" variant="body2">{customPipelinesError.description}</Typography>
                    )}
                       <Box sx={{display:"flex"}}>
                    <Typography variant="body1" style={{ margin: "0.5rem 0", color: "#1A4A42", fontWeight: 700, fontSize: '1.1rem' }}>
                        Associated Clinic
                    </Typography>
                    <Typography variant="body1" style={{marginLeft:"0.3rem",  marginTop: "0.5rem", color: "red", fontWeight: 700, fontSize: '1.5rem' }}>
                        *
                    </Typography>
                    </Box>
                    <Box sx={{ flex: 1 }} marginBottom={1}>
                        <Autocomplete
                            data-test-id="selectClinic"
                            placeholder="Search Clinic"
                            options={filteredOptions}
                            value={this.state.selectedClinic || customAssociatedClinic}
                            open={this.state.openStates["selectedClinic"]}
                            onChange={() => { this.handleDropdownToggle("selectedClinic") }}
                            isOptionEqualToValue={(option, value) => option === this.state.selectedClinic}
                            getOptionLabel={(option) => option}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    data-test-id="clinicTextField"
                                    placeholder="Search Clinic"
                                    onClick={() => this.handleDropdownToggle("selectedClinic")}
                                    InputProps={{
                                        ...params?.InputProps,
                                        endAdornment: (
                                            <InputAdornment position="end"
                                                sx={{
                                                    "& .MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-fullWidth MuiInputBase-formControl MuiInputBase-adornedEnd MuiAutocomplete-inputRoot css-1hiurh-MuiInputBase-root-MuiOutlinedInput-root": {
                                                        paddingRight: "0 !important"
                                                    }
                                                }}
                                            >
                                                <ArrowDropDownOutlinedIcon  style={{
                                                    color: '#64748B',
                                                    cursor: 'pointer',
                                                    fontSize: '2rem'
                                                }} />
                                            </InputAdornment>
                                        ),
                                        sx: {
                                            fontWeight: 400,paddingLeft: "0.3rem !important",fontSize: "1.2rem",
                                            flexWrap: 'wrap',overflowY: 'auto',display: "flex",
                                            alignItems: 'center',borderRadius: "0.5rem",
                                            "& .css-18bmrv6-MuiButtonBase-root-MuiChip-root": {
                                                fontSize: "1.4rem",paddingRight: "0", display: 'none',
                                            },
                                            "& .MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-fullWidth MuiInputBase-formControl MuiInputBase-adornedEnd MuiAutocomplete-inputRoot css-1hiurh-MuiInputBase-root-MuiOutlinedInput-root": {
                                                paddingRight: "0 !important"},
                                            "& .MuiAutocomplete-tag": {
                                                display: "none"}
                                        },
                                    }}
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                            padding: "0 0.4rem",
                                            // minHeight: '3.5rem !important',overflow: 'auto',border: '0rem solid #1A4A4233',
                                            // mb: "1rem"
                                        },
                                        '& .MuiSelect-icon': {
                                            display: 'none',
                                        },
                                        "& .MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-fullWidth MuiInputBase-formControl MuiInputBase-adornedEnd MuiAutocomplete-inputRoot css-1hiurh-MuiInputBase-root-MuiOutlinedInput-root, .MuiAutocomplete-hasClearIcon.css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root": {
                                            paddingRight: "0 !important"},
                                        "& .MuiInputAdornment-root": {
                                            marginRight: '1rem',},
                                        "&.MuiInputBase-root.MuiOutlinedInput-root": {
                                            paddingRight: "0 !important",},
                                        "& .MuiInputBase-adornedEnd": {
                                            paddingRight: "0 !important",},
                                        "& .MuiAutocomplete-inputRoot": {
                                            paddingRight: "0 !important", },
                                    }}
                                />
                            )}
                            renderOption={(props, option, { selected }) => (
                                <MenuItem
                                    {...props}
                                    key={option}
                                    data-test-id="renderOptionClinic"
                                    sx={{
                                        fontSize: "1.4rem",padding: "0.6rem 0",fontWeight: selected ? 700 : 400,
                                        color: selected ? "#1A4A42" : "#292524",
                                        "&:hover": { backgroundColor: "#f0f0f0" },
                                    }}
                                    onClick={() => this.handleSelectClinic(option)}
                                >
                                    {option}
                                </MenuItem>
                            )}
                            noOptionsText="No Associated Clinics found"
                        />
                        {customPipelinesError.associatedClinic && (
                            <Typography sx={{ marginTop: '0.5rem', fontSize: '1rem' }} color="red" variant="body2">
                                {customPipelinesError.associatedClinic}
                            </Typography>
                        )}
                    </Box>
                    <Box sx={{display:"flex"}}>
                    <Typography variant="body1" style={{ margin: "0.5rem 0", color: "#1A4A42", fontWeight: 700, fontSize: '1.1rem' }}>
                    Levels
                    </Typography>
                    <Typography variant="body1" style={{marginLeft:"0.3rem",  marginTop: "0.5rem", color: "red", fontWeight: 700, fontSize: '1.5rem' }}>
                        *
                    </Typography>
                    </Box>
                    <Box style={{ display: "flex", gap: "1rem", marginBottom: "0.5rem" }}>
                        {this.renderVisibleBySection("selectedLevels", allLevels, customSelectedLevels, "Levels",customPipelinesError.selectedLevels)}
                    </Box>
                    <Box sx={{display:"flex"}}>
                    <Typography variant="body1" style={{ margin: "0.5rem 0", color: "#1A4A42", fontWeight: 700, fontSize: '1.1rem' }}>
                        Task Groups
                    </Typography>
                    <Typography variant="body1" style={{marginLeft:"0.3rem",  marginTop: "0.5rem", color: "red", fontWeight: 700, fontSize: '1.5rem' }}>
                        *
                    </Typography>
                    </Box>
                    <Box style={{ display: "flex", gap: "1rem", marginBottom: "0.5rem" }}>
                        {this.renderVisibleBySection("selectedTasks", allTasks, customSelectedTasks, "Search Task Groups",customPipelinesError.selectedTasks)}
                    </Box>
                    <Box sx={{display:"flex"}}>
                    <Typography variant="body1" style={{ margin: "0.5rem 0", color: "#1A4A42", fontWeight: 700, fontSize: '1.1rem' }}>
                        Individuals
                    </Typography>
                    <Typography variant="body1" style={{marginLeft:"0.3rem",  marginTop: "0.5rem", color: "red", fontWeight: 700, fontSize: '1.5rem' }}>
                        *
                    </Typography>
                    </Box>
                    <Box style={{ display: "flex", gap: "1rem", marginBottom: "0.5rem" }}>
                        {this.renderVisibleBySection("selectedUsers", allUsers, customSelectedUsers, "Search Individuals",customPipelinesError.selectedUsers)}
                    </Box>
                 
                    <CustomWarningBox>
                        <CustomCreateEditButton type="submit" 
                        disabled={false} 
                        style={{
                            backgroundColor: "#1A4A42",
                            color: "#fff",
                            cursor:  "pointer",
                        }}>{buttonText}</CustomCreateEditButton>
                    </CustomWarningBox>
                </Box>
            </Custompipelinecomponent>
        );
    }
}

const Custompipelinecomponent = styled("div")({
    padding: "2rem 2rem 1rem",
    borderRadius: "1.2rem",
    backgroundColor: "#fff",
    border: "1px solid #ccc",
    boxSizing: "border-box",
    minHeight: 'calc(100vh - 2.5rem)'
});

const dividerStyle = {
    margin: "1.5rem 0",
    backgroundColor: "#1A4A4233",
};

const CustomWarningBox = styled("div")(
    {
        border: "0.1rem dashed #292524",
        padding: "1.5rem",
        borderRadius: "1.2rem",
        backgroundColor: "#f9f9f9",
        marginTop: "2.5rem"
    });

const CustomCreateEditButton = styled("button")({
    width: "100%",
    padding: "0.8rem",
    border: "none",
    borderRadius: "0.5rem",
    fontSize: "1.4rem",
    cursor: "pointer",
    fontWeight: 600,
});