import React from "react";
// Customizable Area Start
import { styled } from "@mui/styles";
import { Modal, Box, TextField, IconButton, MenuItem, Button, Typography, TypographyProps, Divider, Radio, FormControlLabel, Autocomplete, Paper, Grid, InputAdornment } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import CallMergeRoundedIcon from '@mui/icons-material/CallMergeRounded';
import CallSplitRoundedIcon from '@mui/icons-material/CallSplitRounded';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import SearchIcon from '@mui/icons-material/Search';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
export type Option = { label: string; value?: string; isNew?: boolean; id?: number | null,fixedPara?:boolean };
// Customizable Area End

import CreatePipelineRuleController, {
  Props,
} from "./CreatePipelineRuleController";

export default class PipelineRule extends CreatePipelineRuleController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderTypography = (text: string, textAlign: TypographyProps['align'] = 'left') => {
    return (
      <Typography variant="body1" style={{ margin: "0.5rem 0", textAlign, color: "#1A4A42", fontWeight: 700, fontSize: "1rem" }}>
        {text}
      </Typography>
    )
  }


  
  addEditAutocomplete = (
    id: string,
    value: Option | null,
    options: Option[],
    placeholder: string,
    handleChangeDropDown: (event: React.SyntheticEvent, newValue: Option | null) => void,
    text?: string,
    disabled?:boolean,
    pipelineValues?: { label: string; value: string }[]
  ) => { 
    return (
      <React.Fragment>
      <Autocomplete
      data-test-id={id}
          disabled={disabled || value?.fixedPara}
        id={id}
        onChange={handleChangeDropDown}
        value={value}
        options={options}
        getOptionLabel={(option: Option) => option.label}
        isOptionEqualToValue={(option, value) => {
          return option.label === value?.label;
      }}
      componentsProps={{
        popupIndicator: {
          sx: { display: 'none' }
        },
      }}
        PaperComponent={({ children }) => (
          <Paper
            elevation={2}
            sx={{
              border: '0.1rem solid #A8A29E',
              borderRadius: '0.8rem',
              marginTop: '0.2rem',
              padding: '0.5rem'
            }}
          >
            {children}
          </Paper>
        )}
        renderOption={(props, option, { selected }) => {
          return (
              <li
                  {...props}
                  style={{
                      fontSize: "1.1rem",
                      backgroundColor: selected ? "#1A4A421A" : "transparent",
                      fontWeight: 400,
                      color: selected ? "#1A4A42" : "#0F172A",
                  }}
              >
                  {option?.label}
              </li>
          );
      }}
        style={{ width: '100%' }}
        renderInput={(params) => (
          <TextField
  style={{ marginBottom: "0.8rem", display: "flex", alignItems: "center" }}
  {...params}
  placeholder={!disabled && value==null ?placeholder:""}
  InputLabelProps={{ shrink: false }}
  InputProps={{
    ...params?.InputProps,
    endAdornment: (
      <InputAdornment position="end"
      sx={{
        "& .MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-fullWidth MuiInputBase-formControl MuiInputBase-adornedEnd MuiAutocomplete-inputRoot css-1hiurh-MuiInputBase-root-MuiOutlinedInput-root": {
            paddingRight: "0 !important",
        }
    }}>
        {id === "selectedPipeline" && !disabled && value==null  ? (
          <SearchIcon
            sx={{
              color: "#64748B",
              cursor: "pointer",
              fontSize: "2rem",
              "& .MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-fullWidth MuiInputBase-formControl MuiInputBase-adornedEnd MuiAutocomplete-inputRoot css-1hiurh-MuiInputBase-root-MuiOutlinedInput-root": {
                paddingRight: "0 !important"

            }
            }}
          />
        ) : !disabled  && !value?.fixedPara ||text=="Not parameter"  ? (
          <ArrowDropDownRoundedIcon
            style={{
              color: "#1A4A42",
              cursor: "pointer",
              fontSize: "2rem",
            }}
          />
        ) : (
          <></>
        )}
      </InputAdornment>
    ),
    sx: {
      height: "3rem !important",
      fontWeight: 400,
      paddingLeft: "0.3rem !important",
      fontSize: "1.1rem",
      flexWrap: "wrap",
      overflowY: "auto",
      display: "flex",
      alignItems: "center",
      borderRadius: "0.6rem",
      backgroundColor: !disabled && value==null ? "transparent" : "#1A4A4233",
      "& .MuiInputBase-input": {
        fontSize: "1.2rem",
        color: "#292524",
        WebkitTextFillColor: "#292524 !important"
      },
    },
  }}
  sx={{
    "& .MuiOutlinedInput-root": {
      padding: "0 0.3rem",
      minHeight: text=="Not parameter"? "3.5rem !important" : "1rem !important",
      overflow: "auto",
      backgroundColor: !value?.fixedPara && !disabled  ? "transparent" : "#1A4A4233",
      border: !value?.fixedPara && !disabled  ?"0.0.1px solid #1A4A4233":"none",
      "& fieldset": { border: !value?.fixedPara && !disabled    ?"0.0.1px solid #1A4A4233": "none" }, 

    },
                  '& .MuiSelect-icon': {
                      display: 'none',
                  },
                  "& .MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-fullWidth MuiInputBase-formControl MuiInputBase-adornedEnd MuiAutocomplete-inputRoot css-1hiurh-MuiInputBase-root-MuiOutlinedInput-root, .MuiAutocomplete-hasClearIcon.css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root": {
                      paddingRight: "0 !important"
                  },
                  "& .MuiInputAdornment-root": {
                      marginRight: '1rem',
                  },
                  "&.MuiInputBase-root.MuiOutlinedInput-root": {
                      paddingRight: "0 !important",
                  },
                  "& .MuiInputBase-adornedEnd": {
                      paddingRight: "0 !important",
                  },
                  "& .MuiAutocomplete-inputRoot": {
                      paddingRight: "0 !important",
                  },
    "& input::placeholder": {
      fontSize: "1.1rem",
      opacity: 1,
    },
  }}
/>

      )}
      />
      {id === "selectedPipeline"&& <Box sx={{ marginTop: "0.1rem", display: "flex", gap: "0.5rem", flexWrap: 'wrap', alignItems: 'center' }}>
                    {pipelineValues && pipelineValues.map((item, index: number) => (
                        <Box
                            key={index}
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                border: '0.1rem solid #1A4A4233',
                                backgroundColor: "white",
                                borderRadius: "0.6rem",
                                padding: "0.5rem",
                                fontSize: "1.1rem",
                                fontWeight: 400,
                                color: "#203447",
                            }}
                        >
                            {item.label}
                            <CloseIcon
                                sx={{
                                    marginLeft: "0.5rem",
                                    fontSize: "1.4rem",
                                    cursor: "pointer",
                                    color: "#1A4A4280"
                                }}
                                data-test-id="crossBtn"
                                onClick={() =>this.handleCrossPipeline(item)}
                            />
                        </Box>
                    ))}
                </Box>}
      </React.Fragment>
    )
  }

  addEditRuleTextInput = (
    id: string,
    value: string,
    placeholder: string,
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
    text: string,
    disabled?:boolean
  ) => {
    return (
      <TextField
        data-test-id={id}
        id={id}
        value={value}
        disabled={disabled}
        onChange={handleChange}
        placeholder={""} 
        fullWidth
        variant="outlined"
        sx={{
          "& .MuiOutlinedInput-root": {
            padding: "0.3rem 0.6rem",
            height: "3rem !important",
            minHeight: "3rem",
            borderRadius: "0.6rem",
            fontSize: "1.1rem",
            display: "flex",
            alignItems: "center",
            backgroundColor: !disabled  ? "transparent" : "#1A4A4233",
            border:  !disabled  ?"0.0.1px solid #1A4A4233":"none",
            "& fieldset": { border: !disabled    ?"0.0.1px solid #1A4A4233": "none" }, 
                },
          "& .MuiInputBase-root": {
            height: "3rem !important",
            minHeight: "3rem",
            fontSize: "1.1rem",
            display: "flex",
            alignItems: "center",
          },
          "& input": {
            fontSize: "1.1rem",
            padding: "0.6rem",
            height: "3rem !important",
            minHeight: "3rem",
            display: "flex",
            alignItems: "center",
          },
          "& input::placeholder": { fontSize: "1.1rem", opacity: 1 },
        }}
      />
    );
  };
  
  renderStaticTextField = (
    name: string,
    testId: string,
    minHeight: string,
    lineHeight: string,
    defaultValue: string
  ) => {
    return (
      <TextField
        name={name}
        data-test-id={testId}
        fullWidth
        multiline
        style={{ marginBottom: "0.8rem", boxSizing: 'border-box' }}
        inputProps={{
          readOnly: true,
          sx: {
            minHeight: `${minHeight} !important`,
            fontWeight: 400,
            fontSize: '1.6rem',
            display: 'flex',
            alignItems: 'center',
            flexWrap: "wrap",
            color: "#999",
            lineHeight: `${lineHeight}`,
          },
        }}
        InputLabelProps={{ shrink: false }}
        defaultValue={defaultValue}
      />
    );
  };

  renderTextFieldWithSelect = (
    name: string,
    value: string,
    staticText: string,
    optionsData: { value: string; label: string }[],
  ) => {
    return (
      <TextField
        name={name}
        data-test-id={name}
        value={value || ''}
        onChange={this.handleChange}
        select
        fullWidth
        style={{ marginBottom: "0.8rem" }}
        inputProps={{
          sx: {
            minHeight: '8rem !important',
            fontWeight: 400,
            fontSize: "1.6rem",
            display: 'flex',
            alignItems: 'center',
            flexWrap: "wrap",
            whiteSpace: 'normal',
            overflowWrap: 'break-word',
            wordBreak: 'break-word'
          },
        }}
        InputLabelProps={{ shrink: false }}
        SelectProps={{
          displayEmpty: true,
          renderValue: (selected: unknown) => {
            const selectedOptionData = optionsData.find(item => item.value === selected);
            if (!selectedOptionData) {
              return (
                <span style={{ color: "#999", whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '3rem' }}>
                  {staticText}
                </span>
              );
            }
            return (
              <div style={{
                whiteSpace: 'normal',
                wordWrap: 'break-word',
                wordBreak: 'break-word',
                lineHeight: '3rem',
                maxWidth: '100%',
                display: 'block',
              }}>
                {selectedOptionData.label}
              </div>
            )
          },
          MenuProps: {
            PaperProps: {
              sx: {
                maxHeight: '200px',
                overflowY: 'auto',
                '& .MuiMenuItem-root': {
                  whiteSpace: 'normal',
                  overflowWrap: 'break-word',
                  wordBreak: 'break-word',
                },
              },
            },
          },
        }}
      >
        {optionsData.map((item, index) => (
          <MenuItem
            key={index}
            value={item.value}
            sx={{
              fontSize: '1.6rem',
              fontWeight: 400,
              backgroundColor: value === item.value ? '#1A4A421A' : 'white',
              '&:hover': {
                backgroundColor: value === item.value ? '#1A4A421A' : '#f0f0f0',
              },
            }}
          >
            <FormControlLabel
              value={item.value}
              control={
                <Radio
                  checked={value === item.value}
                  onChange={this.handleChange}
                  value={item.value}
                  name={name}
                  sx={{
                    padding: '0 0.8rem',
                    '&.Mui-checked': {
                      color: '#1A4A42',
                    },
                    '&.MuiRadio-root': {
                      color: '#1A4A42',
                    },
                    '&.MuiButtonBase-root-MuiMenuItem-root.Mui-selected:hover': {
                      backgroundColor: '#1A4A421A',
                    },
                  }}
                />
              }
              label={
                <Typography sx={{
                  fontSize: '1.4rem', fontWeight: 700, color: "#1A4A42", whiteSpace: 'normal',
                  overflowWrap: 'break-word',
                  wordBreak: 'break-word'
                }}>
                  {item.label}
                </Typography>
              }
              sx={{
                fontSize: '1.6rem', fontWeight: 400, whiteSpace: 'normal',
                overflowWrap: 'break-word',
                wordBreak: 'break-word',
              }}
            />
          </MenuItem>
        ))}
      </TextField>
    );
  };

  renderModalTopbar = (handleModalToggle: () => void) => {
    return (
      <Box display="flex" justifyContent="flex-end" alignItems="center" mb={1}>
        <IconButton onClick={handleModalToggle}>
          <CloseIcon style={{ height: "2rem", width: '2rem' }} />
        </IconButton>
      </Box>
    )
  }

  renderModalTitle = (title: string) => {
    return (
      <Typography variant="h6" sx={{ fontWeight: 700, color: "#292524", fontSize: '1.6rem', marginBottom: '2rem' }}>{title}</Typography>
    )
  }

  renderModalHorizontalLabel = (label: string) => {
    return (
      <Typography variant="body1" sx={{ flex: 1, color: "#1A4A42", fontWeight: 700, fontSize: '1.4rem', marginRight: '1rem' }}>
        {label}
      </Typography>
    )
  }

  renderModalVerticalLabel = (label: string) => {
    return (
      <Typography variant="body1" style={{ margin: "0.5rem 0", color: "#1A4A42", fontWeight: 700, fontSize: '1.4rem' }}>
        {label}
      </Typography>
    )
  }

  renderModalTextField = (name: string,
    value: string,
    optionsField: { value: string; label: string }[] | undefined,
    placeholder: string,
    disabled: boolean = false,
    previousValue: string = '',
  ) => {
    return (
      <TextField
        style={{ flex: 3 }}
        name={name}
        data-test-id={name}
        value={value || previousValue}
        onChange={this.handleChangeModalValue}
        select
        disabled={disabled}
        fullWidth
        inputProps={{
          sx: {
            padding: '1rem 0.8rem',
            fontSize: '1.6rem',
            fontWeight: 400,
            display: 'flex',
            alignItems: 'center',
          },
        }}
        InputLabelProps={{ shrink: false }}
        SelectProps={{
          displayEmpty: true,
          renderValue: (selected: unknown) => {
            const selectedOption = optionsField && optionsField.find(item => item.value === selected);
            if (!selectedOption) {
              return <span style={{ color: "#999" }}>{placeholder}</span>;
            }
            return <div style={{
              whiteSpace: 'normal',
              wordWrap: 'break-word',
              wordBreak: 'break-word',
              lineHeight: '2rem',
              maxWidth: '100%',
              display: 'block',
            }}>
              {selectedOption.label}
            </div>
          },
        }}
      >
        {optionsField && optionsField.map((optionItem, index) => (
          <MenuItem key={index} value={optionItem.value}
            sx={{
              fontSize: '1.6rem', fontWeight: 400, backgroundColor: value === optionItem.value ? '#1A4A421A' : 'white',
              '&:hover': {
                backgroundColor: value === optionItem.value ? '#1A4A421A' : '#f0f0f0',
              },
            }}
          >
            <FormControlLabel
              value={optionItem.value}
              control={<Radio checked={value === optionItem.value}
                onChange={this.handleChange}
                value={optionItem.value}
                name="triggerName"
                sx={{
                  padding: '0 0.8rem',
                  '&.Mui-checked': {
                    color: '#1A4A42',
                  },
                  '&.MuiRadio-root': {
                    color: '#1A4A42',
                  },
                  '&.MuiButtonBase-root-MuiMenuItem-root.Mui-selected:hover': {
                    backgroundColor: '#1A4A421A',
                  },
                }}

              />}
              label={<Typography sx={{ fontSize: '1.4rem', fontWeight: 700, color: "#1A4A42" }}>
                {optionItem.label}
              </Typography>}
              sx={{
                fontSize: '1.6rem',
                fontWeight: 400
              }}

            />
          </MenuItem>
        ))}
      </TextField>
    )
  }

  addButtonComponent = (handleAdd: (e: any) => void, title: string) => {
    return (
      <Box>
        <Divider sx={{ backgroundColor: "#1A4A4233", marginBottom: "1rem" }} />
        <Box display="flex" justifyContent="center" alignItems="center" mt={3}>
          <Button onClick={handleAdd} variant="contained" sx={{
            backgroundColor: '#1A4A42',
            color: 'white',
            padding: '0.8rem 0',
            fontWeight: 700,
            borderRadius: '0.8rem',
            textTransform: 'none',
            width: "100%",
            fontSize: '1.4rem'
          }}>
            Add {title}
          </Button>
        </Box>
      </Box>
    )
  }

  editButtonComponent = (handleEditToggle: () => void, handleSave: (e: any) => void) => {
    return (
      <Box>
        <Divider sx={{ backgroundColor: "#1A4A4233", marginBottom: "1rem" }} />
        <Box display="flex" justifyContent="center" gap={2} mt={3}>
          <Button onClick={handleEditToggle} variant="contained" sx={{
            backgroundColor: '#1A4A421A',
            color: '#1A4A4299',
            padding: '0.8rem 0',
            fontWeight: 700,
            borderRadius: '0.8rem',
            textTransform: 'none',
            width: "12rem",
            fontSize: '1.2rem'
          }}>
            Cancel
          </Button>
          <Button onClick={handleSave} variant="contained" sx={{
            backgroundColor: '#1A4A42',
            color: 'white',
            padding: '0.8rem 0',
            fontWeight: 700,
            borderRadius: '0.8rem',
            textTransform: 'none',
            width: "12rem",
            fontSize: '1.2rem'
          }}>
            Save
          </Button>
        </Box>
      </Box>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const triggerOptions = [
      { label: 'Field is changed', value: 'field_is_changed' },
      { label: "Session is Booked", value: "session_is_booked" },
      { label: 'Session Occurs', value: 'session_occurs' },
      { label: "Appointment Is", value: "appointment_is" },
      { label: "Deal is Moved", value: "deal_is_moved" },
      { label: 'Deal is Assigned', value: 'deal_is_assigned' },
      { label: "Due Date", value: "due_date" },
    ]
    const eventOptions = [
      { label: "Assign to a CC / Task List", value: "assign_to_a_cc_task_list" },
      { label: "Create Deal", value: "create_deal" },
      { label: "Move Deal", value: "move_deal" },
      { label: "Set a Due Date", value: "set_a_due_date" },
      { label: "Send Email/SMS", value: "send_email_sms" },
      { label: "Set/Change Priority", value: "set_change_priority" },
      { label: "Set a Reminder", value: "set_a_reminder" },
      { label: "Mark Deal as Complete", value: "mark_deal_as_complete" },
      { label: "Change a Field", value: "change_a_field" },
      { label: "Cancel Appointment", value: "cancel_appointment" },
    ];

    const triggerType = [
      { label: "Session is Booked Equal to 000001 Any Day from Now", value: "session_is_booked_equal_to_000001_any_day_from_now" },
      { label: "Session is Booked Equal to 000002 Any Day from Now", value: "session_is_booked_equal_to_000002_any_day_from_now" },
      { label: "Session is Booked Equal to 000003 Any Day from Now", value: "session_is_booked_equal_to_000003_any_day_from_now" },
      { label: "Session is Booked Equal to 000004 Any Day from Now", value: "session_is_booked_equal_to_000004_any_day_from_now" },
      { label: "Session is Booked Equal to 000005 Any Day from Now", value: "session_is_booked_equal_to_000005_any_day_from_now" }
    ];

    const afterDayType = [
      { label: "Immediately", value: "immediately" },
      { label: "After 30 min", value: "after_30_min" },
      { label: "After 45 min", value: "after_45_min" },
      { label: "After 1 hr", value: "after_1_hr" },
      { label: "After 2 hr", value: "after_2_hr" },
    ];
    return (
      <PipelineRuleContainer>
        <Box marginBottom={2}>
          <Box display='flex' alignItems='center' style={{ gap: "1rem" }}>
            <ArrowBackIcon data-test-id="backbtn" style={{ cursor: "pointer", fontSize: '2rem' }} onClick={this.props.navigation.goBack} />
            <Typography variant="h6" sx={{ fontWeight: 700, fontSize: '1.5rem', color: "#292524" }}>{"Create Rule"}</Typography>
          </Box>
        </Box>

        <Box component="form" noValidate autoComplete="off" onSubmit={this.handleCreateRule}>
          {this.renderTypography("Rule Name")}
          <TextField
            name="rule_name"
            data-test-id="rule_name"
            value={this.state.rule_name}
            onChange={this.handleChange}
            fullWidth
            InputLabelProps={{ shrink: false }}
            placeholder="Type rule name"
            sx={{
              marginBottom: "0.4rem",
              fontSize: "1.4rem",
              "& .MuiOutlinedInput-root": {
                borderRadius: "0.6rem",
                height: "3.5rem",
                "& fieldset": {
                  height: "100%",
                },
              },
              "& .MuiInputBase-input": {
                height: "3.5rem",
                padding: "0.5rem",
                fontSize: "1.2rem"
              },
              "& input::placeholder": {
                fontSize: "1.2rem",
                opacity: 1,
              },
              "@media (max-width: 1024px)": {
                "& .MuiOutlinedInput-root, & .MuiInputBase-input": {
                  height: "3.3rem",
                  fontSize: "1.2rem",
                },
              },
              "@media (max-width: 768px)": {
                "& .MuiOutlinedInput-root, & .MuiInputBase-input": {
                  height: "3.2rem",
                  fontSize: "1.1rem",
                },
              },
              "@media (max-width: 480px)": {
                "& .MuiOutlinedInput-root, & .MuiInputBase-input": {
                  height: "3rem",
                  fontSize: "1rem",
                  padding: "0.4rem",
                },
              },
            }}
          />


          <Box data-test-id="btnParameter" display={"flex"} marginBottom={2} marginTop={2} flexDirection={"row"}
            sx={{ background: "#1A4A4280", borderRadius: 1.5, justifyContent: "space-between" }} >
            <Typography paddingLeft={0.5} margin={0.5} variant="h6" sx={{ fontWeight: 700, fontSize: '1.4rem', color: "#F4F4F4F4" }}>
              {"Triggers"}
            </Typography>

          </Box>
          <Grid container>
  <Grid item md={12} sm={12} xs={12}>
    {this.state.triggers.map((trigger, index: number) => (
      <React.Fragment key={trigger.id}>
        <Box sx={{ p: 1.5, borderRadius: 2, border: "1.5px solid #1A4A4233", marginBottom: 2 }}>
          <Typography sx={{ color: "#1A4A42", fontWeight: 700, fontSize: "1rem" }} gutterBottom>
            Trigger {index + 1}
          </Typography>

          <Grid container spacing={2} alignItems="center">
  <Grid 
    item 
    xs={index > 0 ? 11 : 12} 
    sx={{ display: "flex", alignItems: "center", width: "100%"  }}
  >
    {this.addEditAutocomplete(
      `selectedTrigger-${trigger.id}`,
      trigger.selectedTrigger,
      this.state.triggerDetails.map((t) => ({ label: t.trigger_name })),
      "Select Trigger",
      this.handleAutoComplete(index),
      "Not parameter"
    )}
  </Grid>
  
  {index > 0 && (
    <Grid item     xs={1} >
      <DeleteOutlinedIcon
      data-test-id={`deleteTrigger-${index}`}
        sx={{
          width: "2.2rem",
          height: "2.5rem",
          color: "#1A4A42",
          cursor: "pointer",
          marginLeft: "1rem",mb:"0.5rem"
        }}
        onClick={() => this.handleDeleteTrigger(index)}
      />
    </Grid>
  )}
</Grid>


          {trigger.parameters.map((param, paramIndex: number) => (
            <React.Fragment key={`${trigger.id}-param-${paramIndex}`}>
              <Typography
                sx={{
                  display: "flex",
                  fontWeight: 700,
                  fontSize: "1rem",
                  justifyContent: "center",
                  marginBottom: 1,
                  marginTop: 1,
                }}
              >
                Parameter {paramIndex + 1}
              </Typography>

              <Grid container spacing={2} mb={2}>
                <Grid item xs={3}>
                  <Typography sx={{ color: "#1A4A42", fontWeight: 700, fontSize: "0.8rem" }} gutterBottom>
                    Parent Parameter
                  </Typography>
{this.addEditAutocomplete(
  `selectedParentParameter-${trigger.id}-${paramIndex}`,
  param.selectedParentParameter,
  param.parentParameterOptions,
  "Select Parent Parameter",
  this.handleParentParameterChange(index, paramIndex),

  "Parent Parameter",
  !trigger.selectedTrigger 
)}
                </Grid>

                <Grid item xs={3}>
                  <Typography sx={{ color: "#1A4A42", fontWeight: 700, fontSize: "0.8rem" }} gutterBottom>
                    Child Parameter
                  </Typography>
                  {this.addEditAutocomplete(
  `selectedChildParameter-${trigger.id}-${paramIndex}`,
  param.selectedChildParameter,
  param.childParameterOptions,
  "Select Child Parameter",
  this.handleChildParameterChange(index, paramIndex),
  "Child Parameter",
  !trigger.selectedTrigger
)}



                </Grid>

                <Grid item xs={3}>
                  <Typography sx={{ color: "#1A4A42", fontWeight: 700, fontSize: "0.8rem" }} gutterBottom>
                    Condition
                  </Typography>
                  {this.addEditAutocomplete(
  `selectedCondition-${trigger.id}-${paramIndex}`,
  param.selectedCondition,
  param.conditionOptions,
  "Select Condition",
  this.handleConditionChange(index, paramIndex),
  "Condition",
  !trigger.selectedTrigger
)}

                </Grid>

                <Grid item xs={3}>
                  <Typography sx={{ color: "#1A4A42", fontWeight: 700, fontSize: "0.8rem" }} gutterBottom>
                    Input
                  </Typography>
{this.addEditRuleTextInput(
  `selectedInput-${trigger.id}-${paramIndex}`,
  param.selectedInput,
  "Enter Input",
  this.handleInputChange(index, paramIndex),
  "Input",
  !trigger.selectedTrigger
)}

                </Grid>
              </Grid>
            </React.Fragment>
          ))}
        </Box>

        <Box display="flex" justifyContent="center" gap={2} sx={{ marginBottom: 2 }}>
  <Button
    sx={{
      textTransform: "none",
      background:
        this.state.activeTriggerIndexes.some((item: { index: number; activeButton: string }) =>item.index === index && item.activeButton === "AND")
          ? "#1A4A42E5"
          : "#1A4A421A",
          fontWeight: "700",
          width: "15%",    
      color: this.state.activeTriggerIndexes.some(
        (item: { index: number; 
          activeButton: string }) =>
          item.index === index
         && item.activeButton === "AND")
        ?"#fff" :"#1A4A42",
      border: "2px solid #1A4A42",
      borderRadius: 2,
      paddingLeft: 1,
      paddingRight: 1,
    }}
    startIcon={<CallMergeRoundedIcon />}
    data-test-id={`btn-And-${index}`}
    onClick={(e) => this.handleAddTrigger("AND", index, e)}
  >
    Add AND Trigger
  </Button>

  <Button
    sx={{
      textTransform: "none",fontWeight: "700",width: "15%",
      background:
        this.state.activeTriggerIndexes.some(
          (item: { index: number; activeButton: string }) =>
            item.index === index && item.activeButton === "OR"
        ) ? "#1A4A42E5" : "#1A4A421A",
      color: this.state.activeTriggerIndexes.some(
        (item: { index: number; activeButton: string }) =>
          item.index === index && item.activeButton === "OR")? "#fff":"#1A4A42",
      border: "2px solid #1A4A42",
      borderRadius: 2,paddingLeft: 1, paddingRight: 1,
    }}
    startIcon={<CallSplitRoundedIcon />}
    data-test-id={`btn-OR-${index}`}
    onClick={(e) => this.handleAddTrigger("OR", index, e)}>
    Add OR Trigger
  </Button>
</Box>
      </React.Fragment>
    ))}
  </Grid>
</Grid>
          <Box data-test-id="btnParameter" display={"flex"} marginBottom={2} flexDirection={"row"}
            sx={{ background: "#1A4A4280", borderRadius: 1.5, justifyContent: "space-between" }} >
            <Typography paddingLeft={0.5} margin={0.5} variant="h6" sx={{ fontWeight: 700, fontSize: '1.4rem', color: "#F4F4F4F4" }}> {"Actions"}</Typography>
          </Box>
          <Grid container>
  <Grid item 
  md={12} sm={12} xs={12}>
    {this.state.actions.map((action, index: number) => (
      <Box
        key={action.id}
        sx={{ p: 1.5, borderRadius: 2, border: "1.5px solid #1A4A4233", mb: 2 }}>
        <Grid container display={"flex"} spacing={2} alignItems={"center"}>
          <Grid item xs={index > 0 ? 5.5 : 6} sx={{width: "100%"}}>
            <Typography
              sx={{ color: "#1A4A42", fontWeight: 700, fontSize: "1rem" }}gutterBottom>
              Action {index + 1}
            </Typography>
            {this.addEditAutocomplete(
              `selectedAction-${action.id}`,
              action.selectedAction,
              this.state.actionDetails.map((action: { action_name: string; }) => ({
                label: action.action_name,
              })),
              "Select Action",
              this.handleActionAutoComplete(index),
            
              "Not parameter"
            )}
          </Grid>
          <Grid item xs={index > 0 ? 5.5 : 6} sx={{width: "100%"}}>
            <Typography
              sx={{ color: "#1A4A42", fontWeight: 700, fontSize: "1rem" }}
              gutterBottom
            >
              Waiting Time
            </Typography>
            {this.addEditAutocomplete(
              `selectedWaitingTime-${action.id}`,
              action.selectedWaitingTime,
              action.waitingTimeOptions,
              "Select a specified amount of time",
              this.handleAutoComplete(index),
            
              "Not parameter"
            )}
          </Grid>
          {index > 0 && (
            <Grid item xs={1}>
              <DeleteOutlinedIcon
                    data-test-id={`deleteAction-${index}`}

                sx={{ ml: "1rem",mt:"0.5rem"
                  , width: "2.2rem", height: "2.2rem", color: "#1A4A42" }}
                onClick={() => this.handleDeleteAction(index)} 

              />
            </Grid>
          )}
        </Grid>
        {action.parameters.map((param, paramIndex: number) => (
  <React.Fragment key={`${action.id}-param-${paramIndex}`}>
    <Typography
      sx={{
        display: "flex",
        fontWeight: 700,
        fontSize: "1rem",
        justifyContent: "center",
        marginBottom: 1,
        marginTop: 1,
      }}
    >
      Parameter {paramIndex + 1}
    </Typography>

    <Grid container spacing={2} mb={2}>
      <Grid item xs={3}>
        <Typography
          sx={{ color: "#1A4A42", fontWeight: 700, fontSize: "0.8rem" }}
          gutterBottom
        >
          Parent Parameter
        </Typography>
        {this.addEditAutocomplete(
          `selectedActionParentParameter-${action.id}-${paramIndex}`,
          param.selectedParentParameter,
          param.parentParameterOptions,
          "Select Parent Parameter",
          this.handleActionParentParameterChange(index, paramIndex),
        
          "Parent Parameter",
          !action.selectedAction

        )}
      </Grid>

      <Grid item xs={3}>
        <Typography
          sx={{ color: "#1A4A42", fontWeight: 700, fontSize: "0.8rem" }}
          gutterBottom
        >
          Child Parameter
        </Typography>
        {this.addEditAutocomplete(
          `selectedActionChildParameter-${action.id}-${paramIndex}`,
          param.selectedChildParameter,
          param.childParameterOptions,
          "Select Child Parameter",
          this.handleActionChildParameterChange(index, paramIndex),
          "Child Parameter",
          !action.selectedAction

        )}
      </Grid>

      <Grid item xs={3}>
        <Typography
          sx={{ color: "#1A4A42", fontWeight: 700, fontSize: "0.8rem" }}
          gutterBottom
        >
          Condition
        </Typography>
        {this.addEditAutocomplete(
          `selectedActionCondition-${action.id}-${paramIndex}`,
          param.selectedCondition,
          param.conditionOptions,
          "Select Condition",
          this.handleActionConditionChange(index, paramIndex),
          "Condition",  
          !action.selectedAction
        )}
      </Grid>

      <Grid item xs={3}>
        <Typography
          sx={{ color: "#1A4A42", fontWeight: 700, fontSize: "0.8rem" }}
          gutterBottom
        >
          Input
        </Typography>
        {this.addEditRuleTextInput(
          `selectedActionInput-${action.id}-${paramIndex}`,
          param.selectedInput,
          "Enter Input",
          this.handleActionInputChange(index, paramIndex),
          "Input",
          !action.selectedAction
        )}
      </Grid>
    </Grid>
  </React.Fragment>
))}

      </Box>
    ))}

    <Box display="flex" justifyContent="center" gap={2}>
      <Button
      data-test-id="addAction"
        sx={{
          width: "12%",
          marginTop: 1,
          marginBottom: 1,
          textTransform: "none",
          fontWeight: "700",
          background: "#1A4A421A",
          color: "#1A4A42",
          border: "2px solid #1A4A42",
          borderRadius: 2,
          paddingLeft: 1,
          paddingRight: 1,
        }}
        startIcon={<AddCircleOutlineOutlinedIcon />}
        onClick={this.addNewAction}
      >
        Add Action
      </Button>
    </Box>
  </Grid>
</Grid>;


          
          {this.renderTypography("Associated Pipeline ID")}
          {this.addEditAutocomplete(
  "selectedPipeline",
  null,
  this.state.pipelinesData,
  "#Pipeline ID-Pipeline Name",
  this.handlePipelineChange,
  "Not parameter",
  false,
  this.state.selectedPipeline
)}
          <CustomWarningBox>
            <CustomCreateEditButton type="submit"
              disabled={false}
              style={{
                backgroundColor: "#1A4A42",
                color: "#fff",
                cursor: "pointer",
              }}>{"Create Rule"}
            </CustomCreateEditButton>
          </CustomWarningBox>

        </Box>
        <Modal
          open={this.state.addTriggerModal}
          onClose={this.handleTriggerToggle}
          aria-labelledby="add-trigger-modal"
          aria-describedby="add-trigger-form"
        >
          <Box sx={modalStyle}>
            <Box>
              {this.renderModalTopbar(this.handleTriggerToggle)}
              <Box>
                {this.renderModalTitle("Trigger Options")}
                <Box display="flex" alignItems="center" mb={2}>
                  {this.renderModalHorizontalLabel("Trigger:")}
                  {this.renderModalTextField("triggerName", this.state.triggerName, triggerOptions, "Select Trigger")}
                </Box>
                <Box display="flex" alignItems="center" mb={2}>
                  {this.renderModalHorizontalLabel("Select Type:")}
                  {this.renderModalTextField("triggerSelect1", this.state.triggerSelect1, this.state.tsel1data, "Client", !this.state.isTriggerSelect1Enabled)}
                </Box>
                <Box display="flex" alignItems="center" mb={2}>
                  {this.renderModalHorizontalLabel("Select Input:")}
                  {this.renderModalTextField("triggerInput1", this.state.triggerInput1, this.state.tinput1Data, "company_name", !this.state.isTriggerInput1Enabled)}
                </Box>
                <Box display="flex" alignItems="center" mb={2}>
                  {this.renderModalHorizontalLabel("Select property:")}
                  {this.renderModalTextField("triggerSelect2", this.state.triggerSelect2, this.state.tsel2sata, "Date of Session is ANY", !this.state.isTriggerSelect2Enabled)}
                </Box>
                <Box display="flex" alignItems="center" mb={2}>
                  {this.renderModalHorizontalLabel("Input:")}
                  <TextField
                    name="triggerInput2"
                    data-test-id="triggerInput2"
                    value={this.state.triggerInput2}
                    onChange={this.handleChangeModalValue}
                    fullWidth
                    disabled={!this.state.isTriggerInput2Enabled}
                    InputLabelProps={{ shrink: false }}
                    placeholder="NA"
                    style={{ marginBottom: "0.8rem", flex: 3 }}
                    inputProps={{
                      sx: {
                        padding: '1rem 0.8rem',
                        fontWeight: 400,
                        fontSize: '1.6rem'
                      },
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Modal>
        <Modal
          open={this.state.addEventModal}
          onClose={this.handleEventToggle}
          aria-labelledby="add-event-modal"
          aria-describedby="add-event-form"
        >
          <Box sx={modalStyle}>
            <Box>
              {this.renderModalTopbar(this.handleEventToggle)}
              <Box>
                {this.renderModalTitle("Event Options")}
                <Box display="flex" alignItems="center" mb={2}>
                  {this.renderModalHorizontalLabel("Event:")}
                  {this.renderModalTextField("eventName", this.state.eventName, eventOptions, "Move Deal")}
                </Box>
                <Box display="flex" alignItems="center" mb={2}>
                  {this.renderModalHorizontalLabel("Select:")}
                  {this.renderModalTextField("eventSelect", this.state.eventSelect, this.state.esel1Data, "Deal Type", !this.state.isEventSelectEnabled)}
                </Box>
                <Box display="flex" alignItems="center" mb={2}>
                  {this.renderModalHorizontalLabel("Input:")}
                  {this.renderModalTextField("eventInput", this.state.eventInput, this.state.einput1Data, "First Session Set Stage", !this.state.isEventInputEnabled)}
                </Box>
              </Box>
            </Box>
            {this.addButtonComponent(this.handleAddEvent, "Event")}
          </Box>
        </Modal>
        <Modal
          open={this.state.editTriggerModal}
          aria-labelledby="edit-trigger-modal"
          aria-describedby="edit-trigger-form"
        >
          <Box sx={modalStyle}>
            <Box>
              <Box>
                {this.renderModalTitle("Edit Trigger")}
                {this.renderModalVerticalLabel("Trigger")}
                {this.renderModalTextField("editTriggerName", this.state.editTriggerName, triggerOptions, "Select Trigger", false, this.state.editTriggerPrevValue.trigger_name)}
                {this.renderModalVerticalLabel("Select Type")}
                {this.renderModalTextField("editTriggerSelect1", this.state.editTriggerSelect1, this.state.tsel1data, "Client", false, this.state.editTriggerPrevValue.selection1 || '')}
                {this.renderModalVerticalLabel("Select Input")}
                {this.renderModalTextField("editTriggerInput1", this.state.editTriggerInput1, this.state.tinput1Data, "company_name", false, this.state.editTriggerPrevValue.value1)}
                {this.renderModalVerticalLabel("Select property conditions")}
                {this.renderModalTextField("editTriggerSelect2", this.state.editTriggerSelect2, this.state.tsel2sata, "Date of Session is ANY", false, this.state.editTriggerPrevValue.selection2 || '')}
                {this.renderModalVerticalLabel("Input")}
                <TextField
                  name="editTriggerInput2"
                  data-test-id="editTriggerInput2"
                  value={this.state.editTriggerInput2}
                  onChange={this.handleChange}
                  fullWidth
                  InputLabelProps={{ shrink: false }}
                  placeholder="NA"
                  style={{ marginBottom: "0.8rem", flex: 3 }}
                  inputProps={{
                    sx: {
                      padding: '1rem 0.8rem',
                      fontWeight: 400,
                      fontSize: '1.6rem'
                    },
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Modal>
        <Modal
          open={this.state.editEventModal}
          onClose={this.handleEditEventToggle}
          aria-labelledby="edit-event-modal"
          aria-describedby="edit-event-form"
        >
          <Box sx={modalStyle}>
            <Box>
              {this.renderModalTopbar(this.handleEditEventToggle)}
              <Box>
                {this.renderModalTitle("Edit Event")}
                {this.renderModalVerticalLabel("Assign to")}
                {this.renderModalTextField("editEventName", this.state.editEventName, eventOptions, "Move Deal", false, this.state.editEventPrevValue.event_name)}
                {this.renderModalVerticalLabel("Select task")}
                {this.renderModalTextField("editEventSelect", this.state.editEventSelect, this.state.esel1Data, "Deal Type", false, this.state.editEventPrevValue.dropdown || '')}
                {this.renderModalVerticalLabel("Select input")}
                {this.renderModalTextField("editEventInput", this.state.editEventInput, this.state.einput1Data, "First Session Set Stage", false, this.state.editEventPrevValue.value || '')}
              </Box>
            </Box>
            {this.editButtonComponent(this.handleEditEventToggle, this.handleEditEventSave)}
          </Box>
        </Modal>
      </PipelineRuleContainer >
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const PipelineRuleContainer = styled("div")({
  padding: "2rem 2rem 1rem",
  borderRadius: "1.2rem",
  backgroundColor: "#fff",
  border: "1px solid #ccc",
  fontFamily: "Raleway, sans-serif",
  boxSizing: "border-box",
});
const dividerStyle = {
  margin: "1.5rem 0",
  backgroundColor: "#1A4A4233",
};
const iconStyle = {
  backgroundColor: "#1A4A424D",
  color: "#064E3B",
  padding: "0.45rem",
  borderRadius: "0.6rem",
};
const modalStyle = {
  position: 'absolute',
  top: '0',
  right: '0',
  height: '100vh',
  width: '50rem',
  bgcolor: 'background.paper',
  boxShadow: 2,
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: "space-between",
  padding: '1rem 4.8rem 1rem',
};

const CustomWarningBox = styled("div")(
  {
      border: "0.1rem dashed #292524",
      padding: "1.5rem",
      borderRadius: "1.2rem",
      backgroundColor: "#f9f9f9",
      marginTop: "2.5rem"
  });

const CustomCreateEditButton = styled("button")({
  width: "100%",
  padding: "1rem",
  border: "none",
  borderRadius: "0.8rem",
  fontSize: "1.4rem",
  cursor: "pointer",
  fontWeight: 600,
});
// Customizable Area End
