import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { toast } from 'react-toastify';
import { PipelineType } from './utils/generatePipeline';
import isBetween from 'dayjs/plugin/isBetween';
import dayjs, { Dayjs } from "dayjs"
import { MouseEvent } from "react";
interface CreatedDateType {
  createdFrom: Dayjs | null,
  createdTo: Dayjs | null,
  editedFrom: Dayjs | null,
  editedTo: Dayjs | null,
}
interface ClinicsType {
  global: boolean,
  clinic1: boolean,
  clinic2: boolean,
  clinic3: boolean,
}
interface LevelsType {
  level1: boolean,
  level2: boolean,
  level3: boolean,
  level4: boolean,
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  currentPage: number,
  pageSize: number,
  rowData: PipelineType[],
  filteredData: PipelineType[],
  pipeline_id: number | null | string
  pipeline_name: string
  isDeletePipelineModal: boolean,
  searchQuery: string
  isFilterModal: boolean
  sortBy: string
  sortDirection: string;
  isPipelineDuplicateModal: boolean
  singlePiplineDuplicate: PipelineType
  openRows: {[key:string]:boolean}
  dates: CreatedDateType
  clinics: ClinicsType
  isLoading:boolean
  sortField: string
  sortOrder: string
  levels: LevelsType
  openToolTip:HTMLElement|null
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class RulesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  pipelineListCallId: string="";
  deletePipelineCallId: string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      currentPage: 0,
      pageSize: 10,
      rowData: [],
      filteredData: [],
      pipeline_id: null,
      pipeline_name: '',
      isDeletePipelineModal: false,
      searchQuery: '',
      isFilterModal: false,
      sortBy: '',
      sortDirection: '',
      openRows: {},
      isPipelineDuplicateModal: false,
      dates: {
        createdFrom: null,
        createdTo: null,
        editedFrom: null,
        editedTo: null,
      },
      clinics: {
        global: true,
        clinic1: false,
        clinic2: false,
        clinic3: false,
      },
      levels: {
        level1: true,
        level2: false,
        level3: false,
        level4: false,
      },
      isLoading:true,
      sortField: 'id',
      sortOrder: 'asc',
      openToolTip:null,
      singlePiplineDuplicate: {
        id: '', created_at: '', description: '', order: null, permission_level: '', pipeline_name: '', pipeline_serial_id: '', status: '', updated_at: '', stages: [], clinic_name: '', rules: [], visibleBy: { IndividualUsers: [], PermissionLevels: [], TaskGroups: [] },
      }
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      )

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      )
     let errorResponse  =message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      )
      if (apiRequestCallId === this.pipelineListCallId) {
        this.handlePipelineListRes(responseJson, errorResponse);
      }
      
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount()
    this.pipelineListAPI()
  }

  handlePipelineListRes=(responseJson:any,errorRes: string)=>{
    if (responseJson) {
      this.setState({isLoading:false, filteredData: responseJson, rowData: responseJson })
    }
  }
  pipelineListAPI=async ()=>{
    const token = localStorage.getItem("token")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.pipelineListCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.pipelineListApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  
  handlePageChange = (event: any, value: number) => {
    this.setState({ currentPage: value - 1 });
  };

  
  toggleDeleteModal = () => {
    this.setState(prevState => ({ isDeletePipelineModal: !prevState.isDeletePipelineModal }))
  }
  handleDeleteToggle = (id?: number | string, pipeline_name?: string) => {
    this.toggleDeleteModal()
  }
  handleNavigateCreatePipelineRule = () => {
    this.props.navigation.navigate("CreatePipelineRule")
  }
  handleSearchPipeline = (event: { target: { value: string; } }) => {
    let query=event?.target?.value
    this.setState({ searchQuery: query });
    const { rowData } = this.state
    if (query === '') {
      this.setState({ filteredData: rowData });
    }
    else {
      const filtered = rowData.filter(pipeline =>
        pipeline.pipeline_name?.toLowerCase().includes(query?.toLowerCase()) ||
        pipeline.id.toString()?.includes(query?.toUpperCase()) ||
        pipeline.clinic_name?.toLowerCase().includes(query?.toLowerCase())
      );

      this.setState({ filteredData: filtered });
    }
  };
  toggleFilterModal = () => {
    this.setState(prevState => ({ isFilterModal: !prevState.isFilterModal }))
  }

  handleDateChange = (key: string, newValue: dayjs.Dayjs | null) => {
    this.setState((prevState) => ({
      dates: {
        ...prevState.dates,
        [key]: newValue,
      },
    }));
  };
  handleRowClick = (rowName: string) => {
    this.setState((prevState) => ({
      openRows: {
        ...prevState.openRows,
        [rowName]: !prevState.openRows[rowName],
      },
    }));
  };

  resetFilters = () => {
  
    this.setState({
      dates: {
        createdFrom: null,
        createdTo: null,
        editedFrom: null,
        editedTo: null,
      },
    });
  };
  handleApplyPipelineFilter = () => {
    const { rowData, dates } = this.state;
    const { createdFrom, createdTo, editedFrom, editedTo } = dates;
    if (createdFrom && createdTo && dayjs(createdTo).isBefore(dayjs(createdFrom))) {
      toast.error('The "To" date must be greater than or equal to the "From" date.', { style: { fontSize: "1.6rem" } });
      return;
    }
  
    if (editedFrom && editedTo && dayjs(editedTo).isBefore(dayjs(editedFrom))) {
      toast.error('The "To" date must be greater than or equal to the "From" date.', { style: { fontSize: "1.6rem" } });
      return; 
    }

    dayjs.extend(isBetween);
    const filteredByDate = rowData.filter((task) => {
      const createdAt = dayjs(task.created_at);
      const updatedAt = dayjs(task.updated_at);

      let isCreatedInRange = true;
      let isEditedInRange = true;

      if (createdFrom && createdTo) {
        const createdFromDayjs = dayjs(createdFrom);
        const createdToDayjs = dayjs(createdTo);
        isCreatedInRange = createdAt.isBetween(createdFromDayjs, createdToDayjs, null, '[]');
      }

      if (editedFrom && editedTo) {
        const editedFromDayjs = dayjs(editedFrom);
        const editedToDayjs = dayjs(editedTo);
        isEditedInRange = updatedAt.isBetween(editedFromDayjs, editedToDayjs, null, '[]');
      }

      return isCreatedInRange && isEditedInRange;
    });
   
    this.setState({
      filteredData: filteredByDate
    });

    toast.success('Filters applied successfully', { style: { fontSize: "1.6rem" } });
    this.toggleFilterModal()
  };
  handleSortPipelineData = () => {
    const { filteredData } = this.state;

    return filteredData
  };


  handleOpenTooltip=(event: MouseEvent<HTMLButtonElement>)=>{
    this.setState({openToolTip:event?.currentTarget})
  }
  handleCloseToolTip=()=>{this.setState({openToolTip:null})}

  handleSelectAll = (paginatedRows:{created_at:string}[]) => {
    const allSelected = paginatedRows.every(
      (row) => this.state.openRows[row.created_at] === true
    );

    const newState:any= {};
    paginatedRows.forEach((row) => {
      newState[row.created_at] = !allSelected;
    });

    this.setState({ openRows: newState });
  };
  handleRuleDetailsNavigate = () => {
    const redirection: Message = new Message(getName(MessageEnum.NavigationMessage));
    redirection.addData(getName(MessageEnum.NavigationTargetMessage), 'RuleDetails');
    redirection.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(redirection)
  }

  handleEditNavigation=()=>{
    const redirection: Message = new Message(getName(MessageEnum.NavigationMessage));
    redirection.addData(getName(MessageEnum.NavigationTargetMessage), 'EditRule');
    redirection.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(redirection)

  }
  // Customizable Area End
}
