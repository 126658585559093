import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { TaskDataType } from "./utils/generateTasks";
import { toast } from "react-toastify"
import { PipelinesDetails, TaskDetails } from "./Cftasklistener3Controller";
import { LevelType } from "../../../blocks/cfpipelines1/src/CreatePipelineController";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    singleTask: TaskDetails['attributes']
    isDeleteTask: boolean
    pipelinesData: PipelinesDetails[]
    levelsData:LevelType[]  
    isLoading:boolean
    // Customizable Area End
}

export interface SS {
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export default class DetailtaskgroupController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    taskDetailsCallId: string="";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        // Customizable Area End

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            singleTask: {
              task_serial_id: "",
              id: 0,
              account_id: "",
              title: "",
              due_date: "",
              description: "",
              status: "",
              priority: "",
              permissions: [],
              accounts: [],
              pipelines: [],
              created_at: "",
              updated_at: ""
            },
            isDeleteTask: false,
            pipelinesData: [],
            levelsData: [],
            isLoading:true
            // Customizable Area End
        };

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        this.taskDetailsAPI()
        // Customizable Area End
    }
    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
              getName(MessageEnum.RestAPIResponceDataMessage)
            )
      
          let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))
           let errorResponse  =message.getData(getName(MessageEnum.RestAPIResponceErrorMessage))
            if (apiRequestCallId) {
              switch (apiRequestCallId) {
                case this.taskDetailsCallId:this.handleTaskDetailsRes(responseJson,errorResponse)
                  break;
              }
            }
          }
        // Customizable Area End
    }

    // Customizable Area Start

    handleTaskDetailsRes=(responseJson:any,errorRes: string)=>{
        if(responseJson.data){
                    this.setState({ singleTask: responseJson.data.attributes,
            levelsData: responseJson.data.attributes.permissions,
            pipelinesData:responseJson.data.attributes.pipelines,
            isLoading:false
             })
        }
          }
    taskDetailsAPI=async ()=>{
        let taskID= localStorage.getItem("taskID")
        const token = localStorage.getItem("token")
        const header = {
          "Content-Type": configJSON.validationApiContentType,
          "token": token
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.taskDetailsCallId = requestMessage.messageId;
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.taskGroupListApiEndPoint+"/"+taskID
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.getMethod
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
    
      }
   
    handleEditNavigate = () => {
        localStorage.setItem("task", JSON.stringify(this.state.singleTask))
        this.props.navigation.navigate("Edittaskgroup")
    }
    toggleDeleteTask = () => {
        this.setState(prev => ({ isDeleteTask: !prev.isDeleteTask }))
    }
    handleDeleteTask = (event: any) => {
        event.preventDefault()
    }
    sortDataByLabel = (data: {attributes:{label:string}}[]) => {
        return data.sort((a, b) => a.attributes.label.localeCompare(b.attributes.label));
    };
    gotoTaskGroupList=()=>{
      this.props.navigation.replace("Cftasklistener3");
    }
    // Customizable Area End
}
