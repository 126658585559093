import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
interface TaskAddRequiredErrorsType {
  task_name?: string,
  description?: string,
  userMembers?: string
}
import { generateRandomUsersWithLabel, generateTasks, TaskDataType, UserTypeWithLabel } from "./utils/generateTasks";
import { toast } from "react-toastify"
import dayjs, { Dayjs } from "dayjs"
import isBetween from 'dayjs/plugin/isBetween';
import { LevelType } from "../../../blocks/cfpipelines1/src/CreatePipelineController";
import { ChangeEvent, MouseEvent } from "react";
interface CreatedDateType {
  createdFrom: Dayjs | null,
  createdTo: Dayjs | null,
  editedFrom: Dayjs | null,
  editedTo: Dayjs | null,
}
interface LevelsType {
  level1: boolean,
  level2: boolean,
  level3: boolean,
  level4: boolean,
}
export interface TaskDetails{
  "id": string,
  "type": string,
  "attributes": {
      task_serial_id: string;
      "id": number,
      "account_id"?: string,
      "title": string,
      "due_date"?: string,
      "description": string,
      "status"?: string,
      "priority"?: string,
      "permissions":LevelType[],
      "accounts": AccountsDetails[]
      "pipelines":PipelinesDetails[]
      created_at: string
      "updated_at":string
  }
}
export interface PipelinesDetails{
  "id": string,
  "type": string,
  "attributes": {
      "id": number,
      "pipeline_name": string,
      "label": string
  }
}
export interface AccountsDetails {
      "id"?: string,
      "type"?:string,
      "attributes": {
          id?: number;
          label: string;
          "first_name"?: string,
          "last_name"?: string,
          "full_phone_number"?: string,
          "country_code"?: string,
          "phone_number"?: string,
          "email"?: string,
          "activated"?: boolean,
          "admin_level"?: string
      }
  
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  openRows: any
  selectedTasks: { [key: number]: boolean }
  task_name: string
  description: string
  errors: TaskAddRequiredErrorsType
  tasks: TaskDetails[]
  filteredTasks:TaskDetails[];
  currentPageIndex: number
  requiredPageSize: number
  sortField: string
  sortOrder: string
  searchQuery: string
  isDeleteTask: boolean
  taskId: string
  isAddUserModalOpen: boolean
  selectedUser: UserTypeWithLabel
  userMembers: UserTypeWithLabel[]
  dates: CreatedDateType
  levels: LevelsType
  isFilterModal: boolean
  allLevels:LevelType[]
  isLoading:boolean
  openToolTip:HTMLElement|null
  deleteError:string
  confirmDelete:string
  isCategryModalOpen:boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Cftasklistener3Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  taskGroupListCallId: string="";
  levelsListCallId: string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      openRows: {},
      selectedTasks: {},
      task_name: '',
      description: '',
      errors: {},
      tasks: [],
      filteredTasks: [],
      currentPageIndex: 0,
      requiredPageSize: 10,
      sortField: 'id',
      sortOrder: 'asc',
      searchQuery: '',
      isDeleteTask: false,
      taskId: '',
      isAddUserModalOpen: false,
      selectedUser: { user_name: '', source: "", label: '' },
      userMembers: [],
      dates: {
        createdFrom: null,
        createdTo: null,
        editedFrom: null,
        editedTo: null,
      },
      levels: {
        level1: true,
        level2: false,
        level3: false,
        level4: false,
      },
      isFilterModal: false,
      allLevels:[],
      isLoading:true,
      openToolTip:null,
      deleteError:"",
      confirmDelete:"",
      isCategryModalOpen:false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.levelslistAPI()
    this.taskGroupListAPI()
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      )

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      )
     let errorResponse  =message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      )
      if (apiRequestCallId) {
        switch (apiRequestCallId) {
          case this.levelsListCallId:
            this.handleLevelListRes(responseJson, errorResponse);
            break;
          case this.taskGroupListCallId:
            this.handleTaskGroupListRes(responseJson, errorResponse);
            break; 
        }
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  
  handleLevelListRes=(responseJson:any,errorRes: string)=>{
    if(responseJson.data)
{    this.setState({ isLoading:false,allLevels:responseJson.data})
}  }
  handleTaskGroupListRes=(responseJson:any,errorRes: string)=>{
    if(responseJson.data)
{     
  this.setState({isLoading:false,tasks:responseJson.data,filteredTasks:responseJson.data})
}
  }
  getNumericTaskId = (formattedId: any): number => {
    const match = formattedId?.match(/\d+/);
    return match ? Number(match[0]) : NaN;
  };
  handleSortTasks = (field: string) => {
    const { sortField, sortOrder } = this.state;

    if (sortField === field) {
      this.setState({
        sortOrder: sortOrder === 'asc' ? 'desc' : 'asc',
      });
    } else {
      this.setState({
        sortField: field,
        sortOrder: 'asc',
      });
    }
  };
  onPageChangeTask = (page: number) => this.setState({ currentPageIndex: page })
  handlePreviousTask = () => {
    if (this.state.currentPageIndex > 0) this.onPageChangeTask(this.state.currentPageIndex - 1);
  };
  handlePageChangeTask = (event: any, value: number) => {
    this.setState({ currentPageIndex: value - 1 });
  };

  handleNextTask = (totalPages: number) => {
    if (this.state.currentPageIndex < totalPages - 1) this.onPageChangeTask(this.state.currentPageIndex + 1);
  };

  handleSortTaskData = () => {
    const { sortOrder, filteredTasks, sortField } = this.state;
    if (!Array.isArray(filteredTasks) || filteredTasks.length === 0) {
      return [];
    }
    const sortedData = [...filteredTasks].sort((a, b) => {
      let comparison = 0;

      if (sortField === 'id') {
        comparison = Number(a.id) - Number(b.id);
      } else if (sortField === 'name') {
        comparison = a.attributes.title.localeCompare(b.attributes.title);
      } else if (sortField === 'created_at' || sortField === 'updated_at') {
        const dateA = new Date(a.attributes[sortField]).getTime();
        const dateB = new Date(b.attributes[sortField]).getTime();
        comparison = dateA - dateB;
      }

      return sortOrder === 'asc' ? comparison : -comparison;
    });

    return sortedData
  };
  handleRowClick = (rowName: string) => {
    this.setState((prevState) => ({
      openRows: {
        ...prevState.openRows,
        [rowName]: !prevState.openRows[rowName],
      },
    }));
  };

  handleTaskCreate = () => {
    this.props.navigation.navigate("Createtaskgroup")
  }
  handleSearchTaskGroup = (query: string) => {
    this.setState({ searchQuery: query });
    const { tasks } = this.state
    if (query === '') {
      this.setState({ filteredTasks: tasks });
    }
    else {
      const filtered = tasks.filter((singleTask:TaskDetails) =>
        singleTask.attributes.title.toLowerCase().includes(query.toLowerCase()) ||
        singleTask.attributes.task_serial_id.toString().includes(query.toUpperCase()) ||
        (singleTask.attributes.pipelines &&
          singleTask.attributes.pipelines.some((pipelineId: PipelinesDetails) =>
            pipelineId.attributes.label.toLowerCase().includes(query.toLowerCase())
          )
        ) || (singleTask.attributes.accounts && singleTask.attributes.accounts.some((user:AccountsDetails) =>
          user.attributes.label.toLowerCase().includes(query.toLowerCase())
        ))
      );

      this.setState({ filteredTasks: filtered });
    }
  };
  handleTaskDetailView = (task: TaskDetails) => {
    localStorage.setItem("taskID",task.id)
    this.props.navigation.navigate("Detailtaskgroup")
  }
  toggleTaskDeleteModal = () => {
    this.setState(prevState => ({ isDeleteTask: !prevState.isDeleteTask }))
  }
  handleDeleteTaskToggle = () => {
    this.toggleTaskDeleteModal()
    // this.setState({ taskId: id })
  }
  handleDeleteSingleTask = (e: any) => {
    e.preventDefault()
    const updatedTasks = this.state.filteredTasks.filter((task: { attributes: { task_serial_id: string; }; }) => task.attributes.task_serial_id !== this.state.taskId)
    this.setState({ filteredTasks: updatedTasks })
    toast.success("Task deleted successfully", { style: { fontSize: '1.6rem' } })
    this.toggleTaskDeleteModal()
  }
  toggleCategoryModal = () => {
    this.setState(prevState => ({ isCategryModalOpen: !prevState.isCategryModalOpen }))
  }
  handleUserModal = (item: TaskDetails) => {
    this.toggleCategoryModal()
  }
  handleAddUser = (e: any) => {
    e.preventDefault();
    toast.success("User added successfully", { style: { fontSize: '1.6rem' } });
  };

  handleUserSelect = (user: UserTypeWithLabel) => {
    this.setState({
      selectedUser: user
    });
  };
  handleLevelChange = (event: any) => {
    this.setState(prevState => ({ levels: { ...prevState.levels, [event.target.name]: event.target.checked } }));
  };
  resetFilters = () => {
    this.setState({
      dates: {
        createdFrom: null,
        createdTo: null,
        editedFrom: null,
        editedTo: null,
      }
    });
    this.setState({
      levels: {
        level1: false,
        level2: false,
        level3: false,
        level4: false,
      }
    });
  };
  handleDateChange = (key: string, newValue: any) => {
    this.setState((prevState) => ({
      dates: {
        ...prevState.dates,
        [key]: newValue,
      },
    }));
  };
  toggleFilterModal = () => {
    this.setState(prevState => ({ isFilterModal: !prevState.isFilterModal }))
  }
  handleApplyTaskFilter = () => {
    const { tasks, dates, levels } = this.state;
    const { createdFrom, createdTo, editedFrom, editedTo } = dates;

    if (createdFrom && createdTo && dayjs(createdTo).isBefore(dayjs(createdFrom))) {
        toast.error('The "To" date must be greater than or equal to the "From" date.', { style: { fontSize: "1.6rem" } });
        return;
    }

    if (editedFrom && editedTo && dayjs(editedTo).isBefore(dayjs(editedFrom))) {
        toast.error('The "To" date must be greater than or equal to the "From" date.', { style: { fontSize: "1.6rem" } });
        return;
    }
    const selectedLevels: string[] = [];
    if (levels.level1) selectedLevels.push('Level 1');
    if (levels.level2) selectedLevels.push('Level 2');
    if (levels.level3) selectedLevels.push('Level 3');
    if (levels.level4) selectedLevels.push('Level 4');

    const filteredByLevels = tasks.filter((task: TaskDetails) => {
        if (selectedLevels.length === 0) return true;
        const taskLevels = task.attributes.permissions.map((level: LevelType) => level.attributes.label);
        return selectedLevels.every(level => taskLevels.includes(level));
    });
    dayjs.extend(isBetween);
    const filteredByDate = filteredByLevels.filter((task: TaskDetails) => {
        const createdAt = dayjs(task.attributes.created_at);
        const updatedAt = dayjs(task.attributes.updated_at);

        let isCreatedInRange = true;
        let isEditedInRange = true;

        if (createdFrom && createdTo) {
            const createdFromDayjs = dayjs(createdFrom).startOf('day');
            const createdToDayjs = dayjs(createdTo).endOf('day');
            isCreatedInRange = createdAt.isBetween(createdFromDayjs, createdToDayjs, null, '[]');
        }

        if (editedFrom && editedTo) {
            const editedFromDayjs = dayjs(editedFrom).startOf('day');
            const editedToDayjs = dayjs(editedTo).endOf('day');
            isEditedInRange = updatedAt.isBetween(editedFromDayjs, editedToDayjs, null, '[]');
        }

        return isCreatedInRange && isEditedInRange;
    });
    this.setState({
        filteredTasks: filteredByDate
    });
    toast.success('Filters applied successfully', { style: { fontSize: "1.6rem" } });
    this.toggleFilterModal();
};


  apiCall = async (data: any) => {
    const { contentType, method, endPoint, body, type } = data;
    const token = await localStorage.getItem('token');
    const header = {
      "Content-Type": contentType,
      token: token};

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage));
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header));
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method);

    body && type != 'formData' ?
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body))

      : requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body);
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  levelslistAPI=async ()=>{
    this.levelsListCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.getMethod,
      endPoint: configJSON.levelListApiEndPoint,
    })
  }
  taskGroupListAPI=async ()=>{
    this.taskGroupListCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.getMethod,
      endPoint: configJSON.taskGroupListApiEndPoint,
    })

  }
 

  handleOpenTooltip=(event: MouseEvent<HTMLButtonElement>,row: TaskDetails)=>{
      this.setState({openToolTip:event?.currentTarget,taskId:row.id,task_name:row.attributes.title})
    }
    handleCloseToolTip=()=>{this.setState({openToolTip:null})}
  
    handleSelectAll = (paginatedRows:{id:string}[]) => {
      const allSelected = paginatedRows.every(
        (row) => this.state.openRows[row.id] === true
      );
  
      const newState:any= {};
      paginatedRows.forEach((row) => {
        newState[row.id] = !allSelected;
      });
  
      this.setState({ openRows: newState });
    };
  handleDeleteChange=(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>)=>{
    let value=event.target.value
    this.setState({confirmDelete:value})
  }
  deleteSelectedPipelines=()=>{
    if(this.state.confirmDelete!=="DELETE")
    {
      this.setState({deleteError:"Incorrect term"})
      return
    }
    Object.keys(this.state.openRows).forEach(Number); 
    this.toggleTaskDeleteModal()
  }

  handleTaskDetailsNavigate = () => {
    localStorage.setItem("taskID",this.state.taskId)
    const redirection: Message = new Message(getName(MessageEnum.NavigationMessage));
    redirection.addData(getName(MessageEnum.NavigationTargetMessage), 'Edittaskgroup');
    redirection.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(redirection)
  }
  // Customizable Area End
}
