import React from "react";
// Customizable Area Start
import { Box, Button as MuiButton, Typography as MuiTypography, IconButton,
styled, Grid, CardContent, Typography, List, ListItemText, Tabs, Tab } from '@mui/material'
import { Doughnut } from "react-chartjs-2";
import { Chart, ArcElement, Tooltip, Legend } from "chart.js";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import MenuIcon from "@mui/icons-material/Menu";
import CheckIcon from "@mui/icons-material/Check";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { SearchBar } from "../../../blocks/cfpipelines1/src/Cfpipelines1.web";
import { Bell, Deals, DiagonalArrow, DoubleTick, Pinned, Pipeline, Pipelines, Setting, Stage, Stages } from "./assets";

Chart.register(ArcElement, Tooltip, Legend);
import  {
  configJSON,
} from "./LandingPageController";
const alerts = [
  { id: "0125355", status: "Pending", time: "5 mins ago" },
  { id: "0125355", status: "Resolved", time: "12:00 PM, 15 Jan 24" },
  { id: "0125355", status: "Pending", time: "5 mins ago" },
  { id: "0125355", status: "Pending", time: "5 mins ago" },
  { id: "0125355", status: "Pending", time: "5 mins ago" },
  { id: "0125355", status: "Pending", time: "5 mins ago" },
  { id: "0125355", status: "Pending", time: "5 mins ago" },
];

// Customizable Area End

import LandinPageController, {
  Props,
} from "./LandingPageController";

export default class LandingPage extends LandinPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
   StatsCard = ( title:string, value:string, growth:string,image:any,bgColor:string,isTradingUp:boolean ) => {
    return (
      <Card variant="outlined" sx={{ borderRadius: 1 }}>
        <CardContent>
          <Box display="flex" alignItems="center" gap={2}>
            <Box
              sx={{
                width: 40,
                height: 40,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "50%",
                backgroundColor: bgColor,
              }}>
              <img src={image} />
            </Box>
            <Box>
              <Typography variant="subtitle2" color="#B1B0BE" fontWeight={"bold"}>
                {title}
              </Typography>
              <Typography variant="h5" fontWeight="bold">
                {value}
              </Typography>
            </Box>
          </Box>
            {growth && (
            <Box display="flex" alignItems="center" gap={1} mt={1} marginTop={2}>
              {
                isTradingUp?
                <TrendingUpIcon sx={{ color: "#28C76F", fontSize: 18 }} />:
                <TrendingDownIcon sx={{ color: "#DC2626", fontSize: 18 }} />

                }
              <Typography variant="body2" color={isTradingUp?"#28C76F":"#DC2626"} fontWeight={"bold"}>
                {growth}%
              </Typography>
              <Typography variant="body2" color="textSecondary" fontWeight={"bold"}>
                since last month
              </Typography>
            </Box>
          )}
        </CardContent>
      </Card>
    );
  };
  // Customizable Area End

  render() {
    const arr = new Array(8).fill(0)
    return (
      // Customizable Area Start
      <MainContainer>
        <Box style={{ display: "flex", marginBottom: '1.6rem', justifyContent: "space-between", alignItems: 'center', flexWrap: 'nowrap' }}>
          <MuiTypography variant="h6" sx={{ fontWeight: 700, fontSize: '2.2rem', color: "#292524" }}>
            Dashboard
          </MuiTypography>
          <Box display='flex' alignItems='center' gap={1.5}>
            <Box>
              <SearchBar sx={{ minWidth: "30%" }} testID="btnNav" handleSearchField={() => { }} />
            </Box>
            <MuiButton data-test-id="createPipeline" onClick={() => this.handleNavigation('CreatePipeline')} sx={{
              backgroundColor: '#1A4A42',
              color: 'white',
              padding: '0.4rem 1.6rem',
              fontWeight: 700,
              borderRadius: '0.8rem',
              textTransform: 'none',
              fontSize: '1.4rem',
              whiteSpace: 'nowrap',
              minWidth: "17%",
              '&:hover': {
                backgroundColor: '#1B5C54',
                color: '#fff',
              }
            }}>
              Create Pipeline
            </MuiButton>
            <MuiButton data-test-id="createTaskGroup" onClick={() => this.handleNavigation('Createtaskgroup')} sx={{
              backgroundColor: '#1A4A42',
              color: 'white',
              padding: '0.4rem 1.6rem',
              fontWeight: 700,
              borderRadius: '0.8rem',
              textTransform: 'none',
              fontSize: '1.4rem',
              whiteSpace: 'nowrap',
              minWidth: "17%",
              '&:hover': {
                backgroundColor: '#1B5C54',
                color: '#fff',
              }
            }}>
              Create Task Group
            </MuiButton>
            <MuiButton sx={{
              backgroundColor: '#1A4A42',
              color: 'white',
              padding: '0.4rem 1.6rem',
              fontWeight: 700,
              borderRadius: '0.8rem',
              textTransform: 'none',
              fontSize: '1.4rem',
              whiteSpace: 'nowrap',
              minWidth: "17%",
              '&:hover': {
                backgroundColor: '#1B5C54',
                color: '#fff',
              }
            }}>
              Create Rule
            </MuiButton>
          </Box>
        </Box>
        <Grid container spacing={2} sx={{ overflowX: "hidden" }}>
          <Grid item xs={12} md={8} sx={{ overflowX: "hidden" }}>
            {false &&<Box sx={{borderRadius:1,padding:0.5,
              justifyContent:"center",marginBottom:1.5,
              display:"flex",border:"1px solid #F87171",background:"#FEE2E2"}}>
              <img src={Bell} style={{margin:1}}/>
              <Typography sx={{color:"#475569",marginLeft:1}}>
              There are currently 7 emergencies
              </Typography>
            </Box>}
            <Card >
              <CardContent>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                  <Box display="flex" alignItems="center" gap={1}>
                    <Box sx={{ borderRadius: 50, background: "#E2E8F0" }}>
                      <img style={{ margin: 8 }} src={Pinned} />
                    </Box>
                    <Typography variant="subtitle1" fontWeight="bold">
                      Favorite Pipeline
                    </Typography>
                  </Box>
                  <IconButton size="small" sx={{ color: "#B1B0BE" }} >
                    <MoreHorizIcon />
                  </IconButton>
                </Box>
                {true ?
                  <>
                    <Box
                      sx={{
                        marginTop: 2,
                        padding: 2,
                        backgroundColor: "#F8F9FC",
                        borderRadius: 1,
                        border: "1px solid #EFF6FF",
                      }}
                    >
                      <Typography color="#94A3B8" sx={{ fontSize: 14 }}>
                        Pin your most important pipeline here by adding it below.
                      </Typography>
                    </Box>

                    <Box display="flex" justifyContent="center" marginTop={2}>
                      <MuiButton sx={{
                        background: "#F4F4F4F4",
                        color: '#1A4A42',
                        padding: '0.4rem 1.6rem',
                        fontWeight: 700,
                        borderRadius: '0.5rem',
                        textTransform: 'none',
                        fontSize: '1.0rem',
                        whiteSpace: 'nowrap',
                        minWidth: "17%",
                        border: "1px solid #1A4A42"
                      }}>
                        Add Pipeline
                      </MuiButton>
                    </Box>
                  </> :
                  <Box display={"flex"} sx={{ justifyContent: "space-between", marginTop: 1 }} >
                    <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography sx={{fontSize:12}} marginBottom={1} color={"#94A3B8"}>ID:</Typography>
                        <Typography sx={{fontSize:12}}>#P0000001</Typography>
                      </Box>
                      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography sx={{fontSize:12}} marginBottom={1} color={"#94A3B8"}>Name: </Typography>
                        <Typography>Send Invoice Reminders</Typography>
                      </Box>
                      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography sx={{fontSize:12}} color={"#94A3B8"}>Permission Levels: </Typography>
                        <Box display="flex" gap={1} mt={0.5}>
                          {["L1", "L2", "L3", "L4"].map((level) => (
                            <Box
                              key={level}
                              sx={{
                                padding: 0.8,
                                border: "1px solid #CBD5E0",
                                borderRadius: 1.5,
                                fontSize: 12,
                                fontWeight: "bold",
                                background: "#F8FAFC",
                              }}
                            >
                              {level}
                            </Box>
                          ))}
                        </Box>

                      </Box>
                    </Box>
                    <Box sx={{ borderRight: "1px solid #D9D9D9", }} marginLeft={1} marginRight={1} />
                    <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography sx={{fontSize:12}} marginBottom={1} color={"#94A3B8"}>Rules:</Typography>
                        <Typography sx={{fontSize:12}}>2</Typography>
                      </Box>
                      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography sx={{fontSize:12}} marginBottom={1} color={"#94A3B8"}>Stages: </Typography>
                        <Typography sx={{fontSize:12}}>7</Typography>
                      </Box>
                      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography sx={{fontSize:12}} color={"#94A3B8"}>Active Deals: </Typography>
                        <Typography sx={{fontSize:12}}>409</Typography>
                      </Box>
                    </Box>

                  </Box>

                }

              </CardContent>
            </Card>
            <Grid container spacing={2} sx={{ marginTop: 0, justifyContent: "space-between" }}>
              <Grid item xs={4} >
                {this.StatsCard("Pipelines", "87", "13", Pipelines, "#1464FF14", true)}
              </Grid>
              <Grid item xs={4}>
                {this.StatsCard("Stages", "120", "8", Stages, "#EA545514", true)}
              </Grid>
              <Grid item xs={4}>
                {this.StatsCard("Active Deals", "120", "2", Deals, "#00CFE814", false)}

              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ marginTop: 0 }}>
              <Grid item xs={6}>
                <Card>
                  <CardContent>
                    <Typography marginBottom={0.5} variant="subtitle1" color={"black"} fontWeight={"bold"} >Top 3 Active Rules</Typography>
                    <List>
                      {[
                        "Check for New Patients",
                        "Lost Leads",
                        "Check for Users Birthday",
                      ].map((rule, index) => (
                        <React.Fragment key={index}>
                          <ListItem>
                            <ListItemText
                              primary={
                                <Typography variant="subtitle1" color={"#1E293B"} fontWeight={"bold"}>
                                  {index + 1}. {rule}
                                </Typography>
                              }
                            />
                          </ListItem>
                          {index < [
                            "Check for New Patients",
                            "Lost Leads",
                            "Check for Users Birthday",
                          ].length - 1 && <Divider sx={{ marginY: 0.8, borderBottom: "0px solid #D3D3D3" }} />}
                        </React.Fragment>
                      ))}
                    </List>

                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card>
                  <CardContent>
                    <Typography variant="subtitle1" fontWeight={"bold"}>Deal Priority Split</Typography>
                    <Box columnGap={1} sx={{ marginTop: 3.6, display: "flex", justifyContent: "center" }}>
                      <Box sx={{ position: "relative", width: { xs: 50, sm: 80, md: 100 }, height: { xs: 50, sm: 80, md: 100 } }}>
                        <Doughnut data={{
                          labels: ["Low", "Medium", "High"],
                          datasets: [
                            {
                              data: [70, 20, 10],
                              backgroundColor: ["#5B93FF", "#FFD66B", "#FF8F6B"],
                              hoverBackgroundColor: ["#5B93FF", "#FFD66B", "#FF8F6B"],
                              borderRadius: 10,

                            },
                          ],
                        }}
                          options={{
                            responsive: true,
                            maintainAspectRatio: false,
                            cutout: "70%",
                            plugins: {
                              legend: {
                                display: false,
                              },
                            },
                          }} />
                        <Typography
                          variant="h6"
                          sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            fontWeight: "bold",
                          }}
                        >
                          30
                        </Typography>

                      </Box>
                      <Box display="flex" flexDirection="column" justifyContent="center" gap={1}>
                        <Box display={"flex"} marginBottom={1}>
                          <Typography variant="body2" sx={{ marginRight: 1, color: "#5B93FF" }}>
                            ●
                          </Typography>
                          <Typography variant="body2" sx={{ marginRight: 1, color: "#898D9B" }}>
                            Low
                          </Typography>

                          <Typography variant="body2" sx={{ marginRight: 5, color: "#1D2939" }}>
                            (70%)
                          </Typography>
                          <Typography variant="h6" sx={{}}>
                            223
                          </Typography>

                        </Box>

                        <Box display={"flex"} marginBottom={1}>
                          <Typography variant="body2" sx={{ marginRight: 1, color: "#FFD66B" }}>
                            ●
                          </Typography>
                          <Typography variant="body2" sx={{ marginRight: 1, color: "#898D9B" }}>
                            Medium
                          </Typography>

                          <Typography variant="body2" sx={{ marginRight: 5, color: "#1D2939" }}>
                            (20%)
                          </Typography>
                          <Typography variant="h6" sx={{}}>
                            467
                          </Typography>

                        </Box>
                        <Box display={"flex"}>
                          <Typography variant="body2" sx={{ marginRight: 1, color: "#FF8F6B" }}>
                            ●
                          </Typography>
                          <Typography variant="body2" sx={{ marginRight: 1, color: "#898D9B" }}>
                            High
                          </Typography>
                          <Typography variant="body2" sx={{ marginRight: 5, color: "#1D2939" }}>
                            (10%)
                          </Typography>
                          <Typography variant="h6" sx={{}}>
                            105
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Card sx={{ marginTop: 2 }}>
              <CardContent>
                <Box display={"flex"} gap={1} sx={{ alignItems: "center" }}>
                  <Box sx={{ borderRadius: 50, background: "#EEF2FF" }}>
                    <img style={{ margin: 8 }} src={Setting} />
                  </Box>
                  <Typography variant="h6" fontWeight={"bold"}>Top-Performing Pipeline and Stage</Typography>
                </Box>
                <Grid container spacing={2} sx={{ marginTop: 2 }}>
                  <Grid item xs={6}>
                    <Card variant="outlined">
                      <CardContent>
                        <Box display={"flex"} gap={1.5} sx={{ alignItems: "center" }}>
                          <img src={Pipeline} />
                          <Typography variant="subtitle1">Pipeline with most Deals</Typography>
                        </Box>
                        <Divider sx={{ marginY: 0.8, borderBottom: "0px solid #D3D3D3" }} />
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                            <Typography variant="body2" marginBottom={1} color={"#94A3B8"}>
                              ID:</Typography>
                            <Typography>#P0000001</Typography>
                          </Box>
                          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                            <Typography variant="body2" marginBottom={1} color={"#94A3B8"}>
                              Name: </Typography>
                            <Typography>Send Invoice Reminders</Typography>
                          </Box>
                          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                            <Typography variant="body2" color={"#94A3B8"}>
                              Associated Clinic: </Typography>
                            <Typography>Clinic 2</Typography>
                          </Box>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={6}>
                    <Card variant="outlined">
                      <CardContent>
                        <Box display={"flex"} gap={1.5} sx={{ alignItems: "center" }}>
                          <img src={Stage} />
                          <Typography variant="subtitle1">Stage with most Deals</Typography>
                        </Box>
                        <Divider sx={{ marginY: 0.8, borderBottom: "0px solid #D3D3D3" }} />
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                            <Typography variant="body2" marginBottom={1} color={"#94A3B8"}>
                              ID:
                            </Typography>
                            <Typography>#P0000001</Typography>
                          </Box>
                          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                            <Typography variant="body2" marginBottom={1} color={"#94A3B8"}>Name: </Typography>
                            <Typography>Send Invoice Reminders</Typography>
                          </Box>
                          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                            <Typography variant="body2" color={"#94A3B8"}>Associated Clinic: </Typography>
                            <Typography>Clinic 2</Typography>
                          </Box>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>

          </Grid>
          <Grid item xs={12} md={4} sx={{ overflowX: "hidden" }}>
            <Card sx={{ borderRadius: 1 }}>
              <CardContent>
                <Typography variant="h6" fontWeight="bold" color={"#1C1917"}>
                  Alerts
                </Typography>
                <Divider sx={{ marginY: 0.8, borderBottom: "0px solid #D3D3D3" }} />
                <Tabs
                  data-test-id="tabs"
                  value={this.state.tabIndex}
                  onChange={(_, newValue) => this.handleTabChange(newValue)}
                  variant="fullWidth"
                  sx={{
                    borderBottom: "1px solid #E0E0E0",
                    minHeight: 30,
                    "& .MuiTabs-indicator": { backgroundColor: "#0F5C4D" },
                  }}
                >
                  <Tab
                    icon={<MenuIcon fontSize="small" />}
                    label="All"
                    iconPosition="start"
                    sx={{
                      textTransform: "none",
                      fontSize: 12,
                      fontWeight: "bold",
                      color: this.state.tabIndex === 0 ? "#0F5C4D" : "#737373",
                      minHeight: 20,
                      paddingY: 0,
                      "&.Mui-selected": { color: "#0F5C4D" },
                    }}
                  />
                  <Tab
                    icon={<AccessTimeIcon fontSize="small" />}
                    label="Pending"
                    iconPosition="start"
                    sx={{
                      textTransform: "none",
                      fontSize: 12,
                      fontWeight: "bold",
                      color: this.state.tabIndex === 1 ? "#0F5C4D" : "#737373",
                      minHeight: 20,
                      paddingY: 0,
                      "&.Mui-selected": { color: "#0F5C4D" },
                    }}
                  />
                  <Tab
                    icon={<CheckIcon fontSize="small" />}
                    label="Resolved"
                    iconPosition="start"
                    sx={{
                      textTransform: "none",
                      fontSize: 12,
                      fontWeight: "bold",
                      color: this.state.tabIndex === 2 ? "#0F5C4D" : "#737373",
                      minHeight: 20,
                      paddingY: 0,
                      "&.Mui-selected": { color: "#0F5C4D" },
                    }}
                  />
                </Tabs>

                {alerts.map((alert, index) => (
                  <React.Fragment key={index}>
                    <Box sx={{ paddingY: 1.5, display: "flex", }}>
                      <WarningAmberIcon sx={{ color: "#F59E0B", fontSize: 18, marginRight: 1 }} />

                      <Box display="flex" flexDirection={"column"} sx={{ width: "100%" }}>

                        <Box display="flex" alignItems="center" gap={1} sx={{ justifyContent: "space-between" }} >
                          <Typography variant="body2" sx={{}}>
                            Rule ID {alert.id}
                          </Typography>
                          <Box sx={{
                            display: "flex", flexDirection: "row",
                            alignItems: "center", justifyContent: "center"
                          }}>
                            <Typography variant="caption" sx={{ marginRight: 1, color: "#737373", fontSize: 10 }}>
                              {alert.time}
                            </Typography>
                            {alert.status === "Resolved" &&
                              <img src={DoubleTick} />}
                          </Box>
                        </Box>


                        <Typography sx={{ color: "#4F4F4F", fontSize: 12, mt: 0.5 }}>
                          Send Invoice Reminder
                        </Typography>
                        <Box display="flex" justifyContent="space-between" alignItems="center" mt={1}>
                          <Box display="flex" alignItems="center" gap={0.5} mt={0.3}>
                            <HelpOutlineIcon sx={{ height: 18, width: 18, color: "#737373", fontSize: 14 }} />
                            <Typography variant="caption" sx={{ fontSize: 12 }}>
                              Email not attached
                            </Typography>
                          </Box>
                          <img src={DiagonalArrow} />
                        </Box>
                      </Box>
                    </Box>
                    <Divider sx={{ marginY: 0.8, borderBottom: "0px solid #D3D3D3" }} />
                  </React.Fragment>
                ))}


              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </MainContainer>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const MainContainer = styled("div")({
  padding: "2rem",
  justifyContent: "center",
  // boxShadow: "rgba(99, 99, 99, 0.6) 0 0.2rem 0.8rem 0",
  border: "1px solid #ccc",
  borderRadius: "1.2rem",
  backgroundColor: "#fff",
  maxWidth: "100%",
  minHeight:"100vh",
  boxSizing: "border-box",
  overflowX: "hidden",
});

const EventContainer = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
  flexWrap: "wrap",
  gap: "0.5rem",
  marginBottom: "1.5rem",
})
const EventButton = styled("button")({
  backgroundColor: "#1A4A42",
  color: "#fff",
  padding: "0.8rem 1.6rem",
  border: "none",
  borderRadius: "0.8rem",
  cursor: "pointer",
  width: "auto",
  fontWeight: "700",
  height: "100%",
});
const CardContainer = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "wrap",
  maxWidth: "100%",
  gap: "2rem",
  "@media (max-width: 427px)": {
    flexDirection: "column",
    gap: "0.8rem",
  }
});

const Card = styled("div")({
  backgroundColor: "#fff",
  borderRadius: "0.3rem",
  // boxShadow: "rgba(99, 99, 99, 0.5) 0 0.2rem 0.6rem 0",
  padding: "0rem",
  flex: "1",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  maxWidth: "100%",
  border: "1px solid #ccc"
});

const CardFooter = styled("div")({
  fontSize: "10.8rem",
  fontWeight: "700",
  marginTop: "0.4rem",
  position: "relative",
  alignSelf: 'flex-end'
});

const CardHeader = styled("div")({
  margin: "0.5rem 0",
  fontSize: "1.6rem",
  fontWeight: "700",
});

const CardBody = styled("div")({
  fontSize: "10.4rem",
});

const SectionContainer = styled("div")({
  flex: 1,
  backgroundColor: "#fff",
  borderRadius: "0.8rem",
  boxShadow: "rgba(99, 99, 99, 0.6) 0 0.2rem 0.8rem 0",
})

const SectionHeader = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "0 2rem",
});

const StyledButton = styled("button")({
  backgroundColor: "#1A4A424D",
  color: "#1A4A42",
  padding: "0.8rem 1.6rem",
  border: "none",
  borderRadius: "0.8rem",
  cursor: "pointer",
  width: "auto",
  height: "100%",
  fontWeight: "700"
});

const ListHeader = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  padding: "0 2rem",
  backgroundColor: "#1A4A421A",
  fontWeight: "700",
});

const ListItem = styled("div")({
  display: "flex",
  justifyContent: 'space-between',
  alignItems: "center",
  padding: "0 2rem",
});

const IconActions = styled("div")({
  display: "flex",
  gap: "0.8rem",
  alignItems: "center"
});

const iconStyle = {
  backgroundColor: "#1A4A424D",
  color: "#064E3B",
  padding: "0.45rem",
  borderRadius: "0.6rem",
};

const deleteIconStyle = {
  backgroundColor: "#EF444433",
  color: "#DC2626",
  padding: "0.45rem",
  borderRadius: "0.6rem",
};

const Divider = styled("hr")({
  width: "100%",
  margin: "1.5rem 0",
  backgroundColor: "#1A4A4233",
  color:"#1A4A4233"
});

// Customizable Area End
