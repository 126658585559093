// Customizable Area Start
import React from "react";
import { Box, Button as MuiButton, Typography as MuiTypography, TextField, IconButton, Modal, Divider, Checkbox, styled, Menu, MenuItem, Grid } from '@mui/material'
import FilterListIcon from '@mui/icons-material/FilterList';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CloseIcon from '@mui/icons-material/Close';
import { PipelineType } from "./utils/generatePipeline";
import PaginationComponent from "./utils/PaginationComponent";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from "dayjs"
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { LoadingSpinner } from "./CreatePipeline.web";
import SearchIcon from "@mui/icons-material/Search";
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import ArrowDropUpRoundedIcon from '@mui/icons-material/ArrowDropUpRounded'
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import CallMergeRoundedIcon from '@mui/icons-material/CallMergeRounded';
import RulesController, {
  Props,
} from "./RulesController";
import { Checked, UnChecked } from "./assets";

const icon = <CheckBoxOutlineBlankIcon style={{ height: "2rem", width: "2rem" }} />;
const checkedIcon = <CheckBoxIcon style={{ height: "2rem", width: "2rem", color: "#1A4A42", borderRadius: "20rem" }} />;
// Customizable Area End


export default class Rules extends RulesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderColumn = (flex: number, text: string | number,field: string) => {
    return (
      <MuiTypography variant="h6" data-test-id={"column-typography"+field}
        style={{display: "flex", alignItems: "center", fontSize: '1.2rem', flex: flex, fontWeight: 700, color: '#3E3E3E' }}>
        {text}
        </MuiTypography>
    )
  }
  openDatePicker = (text: string, value: Dayjs, onChangeText: string, minDate: Dayjs | null = null) => {
    return (
      <Box><MuiTypography style={{
        fontSize: '1.2rem', fontWeight: 400, color: '#1A4A42', marginBottom: "0.2rem"
      }}>{text}</MuiTypography>
        <DatePicker
          disableFuture
          value={value}
          minDate={minDate}
          format="DD-MM-YYYY"
          slots={{
            textField: (params) => (
              <TextField
                {...params}
                placeholder="19-03-2024"
                sx={{
                  fontSize: '1.4rem',
                }}
                InputProps={{
                  ...params.InputProps,
                  sx: {
                    fontWeight: 400,
                    fontSize: "1.4rem",
                    borderRadius: "0.8rem",
                    '& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': {
                      padding: '0.6rem 0 0.6rem 1rem',
                      fontSize: "1.4rem",

                    }
                  },
                }}
              />
            ),
          }}
          slotProps={{
            popper: {
              sx: {
                '& .MuiPaper-root': {
                  minWidth: '30rem',
                  border: '0.1rem solid #E2E8F0',
                  marginTop: '0.2rem',
                  borderRadius: '0.8rem'
                },
                '& .MuiTypography-root': {
                  fontSize: '1.8rem !important',
                },
                '& .MuiPickersArrowSwitcher-button': {
                  fontSize: '2rem !important',
                  '& .MuiSvgIcon-root': {
                    fontSize: '2rem !important',
                  },
                },
                '& .css-dplwbx-MuiPickersCalendarHeader-label': {
                  fontSize: '1.6rem'
                },
                '& .css-1tkx1wf-MuiSvgIcon-root-MuiPickersCalendarHeader-switchViewIcon': {
                  width: '2rem',
                  height: '2rem'
                },
                '& .css-13m8yui-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected)': {
                  border: 'none',
                  fontWeight: 700
                },

                '& .css-1b2k5pm-MuiPickersYear-yearButton': {
                  fontSize: '1.4rem'
                },
                '& .MuiDayCalendar-weekDayLabel': {
                  fontSize: '1.4rem !important',
                  fontWeight: 400
                },
              },
            },
            day: {
              sx: {
                fontSize: '1.4rem',
              },
            },
          }}
          onChange={(newValue) => this.handleDateChange(onChangeText, newValue)}
          data-test-id="date_picker"
        />
      </Box>
    )
  }

  renderFilter = (title: string, name: string, inputState: any, onChangeText: string, inputState2: any, onChangeText2: string) => {
    const { openRows, dates } = this.state
    const minDateForToPicker = dates.createdFrom
    ? dayjs(dates.createdFrom)
    : null;
    return (
      <Box style={{ marginBottom: '0.8rem' }}>
        <Box display="flex" justifyContent='space-between' alignItems='center' flexWrap='wrap'>
          <MuiTypography variant="h6" sx={{ fontWeight: 700, color: "#292524", fontSize: '1.4rem' }}>{title}</MuiTypography>
          <IconButton data-test-id="down_up_arrow" onClick={() => this.handleRowClick(name)}>
            {openRows[name] ? <KeyboardArrowUpIcon style={{ color: "#292524",fontWeight:700, fontSize: '1.8rem' }} /> 
            : <KeyboardArrowDownIcon style={{ color: "#292524", fontSize: '1.8rem' }} />}
          </IconButton>
        </Box>
        {openRows[name] &&
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box
              sx={{
                paddingTop: '0',
                display: "flex",
                gap: "1rem"
              }}
            >
              {this.openDatePicker("From", inputState, onChangeText)}
              {this.openDatePicker("To", inputState2, onChangeText2, minDateForToPicker)}
            </Box>
          </LocalizationProvider>
        }
      </Box>
    )
  }
  renderRow = (flex: number, text: string | number, row: PipelineType,id?:number) => {
    return (
      <MuiTypography variant="h6" data-test-id={"row-typography"+id} style={{
        fontSize: '1.1rem',
        whiteSpace: 'normal',
        wordWrap: 'break-word',
        wordBreak: 'break-word',
        flex: flex, fontWeight: 500, cursor: 'pointer', color: '#203447'
      }} onClick={() => this.handleRuleDetailsNavigate()}>{text}</MuiTypography>
    )
  }

  rulesList = () => {
    const { currentPage, pageSize } = this.state;
    const sortedRows = this.handleSortPipelineData()
    const paginatedRows = sortedRows.slice(currentPage * pageSize, (currentPage + 1) * pageSize);
    const startIndex = currentPage * pageSize + 1;
    const endIndex = Math.min((currentPage + 1) * pageSize, sortedRows.length);
    const totalResults = sortedRows.length;
    const isAllSelected = paginatedRows.every((row) => this.state.openRows[row.created_at] === true);
    const anySelected = paginatedRows.some((row) => {
      return this.state.openRows[row.created_at] === true;
    });
    return <Box style={{ display: "flex", flexDirection: "column" }}>
      <Box style={{ display: "flex", marginBottom: '1.6rem', justifyContent: "space-between", alignItems: 'center', flexWrap: 'nowrap' }}>
        <MuiTypography variant="h6" sx={{ fontWeight: 700, fontSize: '1.5rem', color: "#292524" }}>
          Rules
        </MuiTypography>
        <Box display='flex' alignItems='center' gap={2}>
          <SearchContainer>
      <SearchInput
      testID={"btnSearch"}
        placeholder={"Search"}
        onChange={this.handleSearchPipeline}
      />
      <SearchIconWrapper>
        <SearchIcon fontSize={"large"} sx={{width:"8%",height:"8%"}} />
      </SearchIconWrapper>
    </SearchContainer>
          <MuiButton data-test-id="btnCreateRule" onClick={this.handleNavigateCreatePipelineRule} sx={{
            backgroundColor: '#1A4A42',
            color: 'white',
            padding: '0.5rem 1.6rem',
            height:"2.6rem",
            fontWeight: 700,
            borderRadius: '0.8rem',
            textTransform: 'none',
            fontSize: '1rem',
            whiteSpace: 'nowrap',
            '&:hover': {
              backgroundColor: '#1B5C54',
              color: '#fff',
            }
          }}>
            Create Rule
          </MuiButton>
          <Box style={{ position: 'relative' }}>
            <FilterListIcon data-test-id={"filterIcon"} onClick={this.toggleFilterModal} style={{ ...iconStyle, cursor: "pointer", fontSize: "2rem" }} />
            {this.state.isFilterModal &&
                <Box
                  sx={filterModalStyle}
                >
                  <Box display="flex" justifyContent="space-between" alignItems="center" >
                    <MuiTypography variant="h6" sx={{ fontWeight: 700, color: "#292524", fontSize: '1.4rem' }}>Filters</MuiTypography>
                    <IconButton data-test-id={"closeFilterIcon"} onClick={() => this.toggleFilterModal()} >
                      <CloseIcon style={{ color: "#292524", fontSize: '1.8rem' }} />
                    </IconButton>
                  </Box>
                  {this.renderFilter("Date Created", "created_at", this.state.dates.createdFrom, "createdFrom", this.state.dates.createdTo, "createdTo")}
                  {this.renderFilter("Last Edited", "updated_at", this.state.dates.editedFrom, "editedFrom", this.state.dates.editedTo, "editedTo")}
                  <Box display="flex" justifyContent="flex-end" gap={1} mt={2}>
                    <MuiButton data-test-id="resetFilter" onClick={this.resetFilters} variant="contained" sx={{
                      backgroundColor: '#fff',
                      color: '#1A4A42',
                      height: '3rem',
                      fontWeight: 700,
                      borderRadius: '0.8rem',
                      textTransform: 'none',
                      fontSize: '1rem',
                      width: '9rem',
                      '&:hover': {
                        backgroundColor: '#F5FEFD',
                      },
                      border:"1px solid #1A4A42"
                    }}>
                      Reset
                    </MuiButton>
                    <MuiButton variant="contained" data-test-id="applyFilter" onClick={this.handleApplyPipelineFilter} sx={{
                      backgroundColor: '#1A4A42',
                      color: 'white',
                      height: '3rem',
                      fontWeight: 700,
                      borderRadius: '0.8rem',
                      textTransform: 'none',
                      fontSize: '1rem',
                      width: '9rem',
                      '&:hover': {
                        backgroundColor: '#1B5C54',
                      }
                    }}>
                      Apply
                    </MuiButton>
                  </Box>
                </Box>
              }
          </Box>
        </Box>
      </Box>
        <Box display="flex" flexDirection='column' gap={1}>
            <Box display="flex" flexDirection='column'  style={{ backgroundColor: '#29252414', borderRadius: ' 0.8rem' }}>
               <Box sx={{display:"flex" }}>
                <Box sx={{ flex: 2, paddingLeft: "1rem" }}>
                <Checkbox
                data-test-id="selectAll"
          sx={{
            '&:hover': { bgcolor: 'transparent' },
          }}
          disableRipple
          color="default"
          checked={isAllSelected}
          onChange={()=>this.handleSelectAll(paginatedRows)}
          checkedIcon={<img src={Checked} style={{ width: '1.6rem' }} />}
          icon={<img src={UnChecked} style={{ width: '1.6rem' }} />}
          inputProps={{ 'aria-label': 'Select All Checkbox' }}
        />

                </Box>
                {this.renderColumn(4, 'Rule ID', "id")}
                {this.renderColumn(6, 'Name', "name")}
                {this.renderColumn(10, 'Associated Pipelines', "associated_clinic")}
                {this.renderColumn(3, 'Actions', "actions")}
                
                </Box>
               {anySelected && <Box  sx={{display:"flex",marginLeft:8,marginBottom:1, flexDirection:"row"}}>
                  <Box sx={{display:"flex",flexDirection:"row",margin:1}} >
                  <MuiTypography sx={{marginRight:1}}>1</MuiTypography>
                  <MuiTypography>Selected</MuiTypography>
                  </Box>
                  <Divider orientation="vertical" flexItem />
                  <MuiTypography sx={{margin:1,color:"#1A4A42"}}>Deselect</MuiTypography >
                  <Divider orientation="vertical" flexItem />
                  <MuiTypography data-test-id="deleteSelected" sx={{margin:1,color:"#E46760"}} onClick={()=>{this.handleDeleteToggle()}}>
                    Delete Selected</MuiTypography>

                </Box>}
            </Box>
            
            
            {paginatedRows.length > 0 ? 

            paginatedRows.map(row => (
                <>

                <Box display='flex' key={row.id} style={{ borderBottom: '0.1rem solid #1A4A421A', alignItems: 'center' }}>
                    <Box sx={{ flex:2,paddingLeft:"1rem" }}>
                        <Checkbox
                            sx={{
                                '&:hover': { bgcolor: 'transparent' },
                            }}
                            data-test-id="singleCheckbox"
                            disableRipple
                            color="default"
                            checked={this.state.openRows[row.created_at]|| false}
                            onChange={()=>this.handleRowClick(row.created_at)}
                            checkedIcon={<img src={Checked} style={{width:"1.6rem"}} />}
                            icon={ <img src={UnChecked} style={{width:"1.6rem"}}  />}
                            inputProps={{ 'aria-label': 'Checkbox demo' }}

                        />
                    </Box>
                    
                    {this.renderRow(4, "#R00000002", row,1)}
                    {this.renderRow(6, "Invoice Reminder", row,2)}
                    {this.renderRow(10, "#P00000001, #P00000002, #P00000003...", row,3)}
                    <Box style={{ flex: 3, display: 'flex', alignItems: 'center', }}>
                        <IconButton data-test-id="accordian-btn-test" sx={addButtonStyle} style={{ padding: "0.4rem" }} onClick={() => this.handleRowClick(row.pipeline_serial_id)}>
                            {this.state.openRows[row.pipeline_serial_id]  ? (
                            <ArrowDropUpRoundedIcon fontSize="medium" />
                          ) : (
                            <ArrowDropDownRoundedIcon fontSize="medium" />
            )}
                        </IconButton>
                        <IconButton data-test-id="tooltip-btn-test" sx={addButtonStyle} style={{ padding: "0.4rem" }} onClick={(event) => { this.handleOpenTooltip(event) }}>
                            <MoreHorizOutlinedIcon fontSize="medium" />
                        </IconButton>
                    </Box>
                </Box>
                {this.state.openRows[row.pipeline_serial_id] &&
                 <Grid container justifyContent="space-between" alignItems="stretch">
                 <Grid item xs={5.9} sx={{ border: "1px solid #1A4A4233", borderRadius: "5px", display: "flex", flexDirection: "column" }}>
                   <Box data-test-id="btnParameter" sx={{ display: "flex", background: "#F1F5F9", padding: "8px" }}>
                     <MuiTypography sx={{ fontWeight: 700, fontSize: '1.2rem', color: "#292524" }}>
                       {"5 Triggers"}
                     </MuiTypography>
                   </Box>
                   <Box sx={{
    display: "flex", 
    overflowX: "auto",
    padding: "0.5rem", 
    gap: "1rem",
    height: "100%",
  }}>
  <Box sx={{
      padding: "8px", 
      whiteSpace: 'normal',
      minWidth: "40%",
  }}>
    <MuiTypography sx={{ fontWeight: 700, fontSize: '1.2rem', color: "#292524" }}>
      {"Session is booked"}
    </MuiTypography>
    <MuiTypography sx={{ fontWeight: 400, fontSize: '1rem', color: "#292524" }}>
      {"Session number - Equal To - 0001, Session date - Date is X from now - 24hrs"}
    </MuiTypography>
  </Box>

  <Box sx={{
      display: "flex", 
      margin: "10px", 
      minWidth:"20%"
  }}>
    <CallMergeRoundedIcon sx={{ color: "#1A4A42" }} />
    <MuiTypography sx={{ fontWeight: 700, fontSize: '1rem', color: "#1A4A42", marginLeft: "4px" }}>
      AND
    </MuiTypography>
  </Box>

  <Box sx={{
      padding: "8px", 
      whiteSpace: 'normal',
      minWidth: "40%",
  }}>
    <MuiTypography sx={{ fontWeight: 700, fontSize: '1.2rem', color: "#292524" }}>
      {"Field is Changed"}
    </MuiTypography>
    <MuiTypography sx={{ fontWeight: 400, fontSize: '1rem', color: "#292524" }}>
      {"Session number - Equal To - 0001Session date - Date is X from now - 24hrsSession number - Equal To - 0001Session date - Date is X from now - 24hrs"}
    </MuiTypography>
  </Box>
</Box>

                 </Grid>
               
                 <Grid item xs={5.9} sx={{ border: "1px solid #1A4A4233", borderRadius: "5px", display: "flex", flexDirection: "column" }}>
                   <Box data-test-id="btnParameter" sx={{ display: "flex", background: "#F1F5F9", padding: "8px" }}>
                     <MuiTypography sx={{ fontWeight: 700, fontSize: '1.2rem', color: "#292524" }}>
                       {"3 Actions"}
                     </MuiTypography>
                   </Box>
                   <Box   
  sx={{ 
    display: "flex", 
    overflowX: "auto",
    whiteSpace: "nowrap",
    padding: "0.5rem", 
    gap: "1rem",
    height:"100%"
  }} 
>
  {[1, 2, 3, 4, 5].map((item, index) => (
    <Box 
      key={index} 
      textAlign="center"
      sx={{ 
        flexShrink: 0,
        minWidth: "180px", 
        maxWidth: "220px", 
        padding: "0.5rem",
        
      }}
    >
      <MuiTypography fontWeight="bold" textAlign="left">
        Send a SMS 
        <MuiTypography component="span" color="#94A3B8" sx={{fontWeight:700 }}> Immediately</MuiTypography>
      </MuiTypography>
      <MuiTypography fontSize="0.9rem" color="#0F172A" sx={{ marginTop: "0.5rem" }} textAlign="left">
        Child Parameter - Condition - Input
      </MuiTypography>
    </Box>
  ))}
</Box>


                 </Grid>
               </Grid>
               
          
          }
                            <Menu
                  anchorEl={this.state.openToolTip}
                  open={Boolean(this.state.openToolTip)}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}                
                  onClose={this.handleCloseToolTip}
                  slotProps={{
                    paper: {
                      sx: {
                        borderRadius: "15px",
                        boxShadow: "none",
                        border: "1px solid #1A4A4233",
                        minWidth: "120px",
                      },
                    },
                  }}
                >
                  <MenuItem  sx={{fontWeight:"500", fontSize:"1.1rem", color: "#0F172A",textAlign: "right", justifyContent: "flex-end" }}>Duplicate</MenuItem>
                  <MenuItem sx={{fontWeight:"500",fontSize:"1.1rem", color: "#0F172A",textAlign: "right", justifyContent: "flex-end" }}
                  onClick={()=>this.handleEditNavigation()}
                  >Edit</MenuItem>
                  <MenuItem sx={{fontWeight:"500",fontSize:"1.1rem", color: "#0F172A",textAlign: "right", justifyContent: "flex-end" }}>
                    Delete
                  </MenuItem>
                </Menu>
          
                      </>
            )) 
            
            : <Box display='flex' justifyContent='center' alignItems='center'><MuiTypography variant="h5">No Rules Found</MuiTypography></Box>}
        </Box>
      <PaginationComponent data-test-id="pagination" currentPageIndex={currentPage} requiredPageSize={pageSize} startIndex={startIndex} endIndex={endIndex} totalResults={totalResults} handlePageChange={this.handlePageChange}/>     
    </Box>
  }

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box sx={pipelineListContainerStyle}>
        {this.state.isLoading ?
                <LoadingSpinner />
                :
          this.rulesList()}
        <Modal
          data-test-id="delete-pipeline-modal"
          open={this.state.isDeletePipelineModal}
          onClose={this.toggleDeleteModal}
          aria-labelledby="edit-stage-modal"
          aria-describedby="edit-stage-form"
        >
          <Box sx={modalStyle}>

            <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
              <MuiTypography  sx={{fontSize:"1.4rem", fontWeight: 700, }}>Delete Selected Rules</MuiTypography>
              <IconButton onClick={this.toggleDeleteModal}>
                <CloseIcon />
              </IconButton>
            </Box>

            <Divider sx={{ backgroundColor: "#1A4A4233", marginBottom: "1rem" }} />
            <Box sx={{ display: "flex"}}>
              <MuiTypography  style={{ fontSize: '1rem',fontWeight:700 }}>
                {`Type "DELETE" below in the input field to proceed.`}</MuiTypography>
            </Box>
            <TextField
      placeholder="Enter value"
      fullWidth
      sx={{
        marginTop:"2rem",
        "& .MuiOutlinedInput-root": {
          borderColor: "#94A3B8",
          height:"3.2rem",
          borderRadius:"0.8rem",

          "& fieldset": {
            borderColor: "", 
          },
          "&:hover fieldset": {
            borderColor: "#94A3B8", 
          },
          "&.Mui-focused fieldset": {
            borderColor: "teal",
          },
        },
        "& .MuiInputBase-input::placeholder": {
          color: "#94A3B8", fontWeight:700
        },
      }}
    />

            <Divider sx={{ backgroundColor: "#1A4A4233", marginBottom: "1rem",marginTop:"2rem" }} />

            <Box display="flex" justifyContent="flex-end" gap={2} mt={3}>
              <MuiButton onClick={this.toggleDeleteModal} variant="contained" sx={{
                backgroundColor: '#1A4A421A',
                color: '#1A4A4299',
                padding: '0.8rem 0.8rem',
                fontWeight: 700,
                borderRadius: '0.6rem',
                textTransform: 'none',
                width: "7rem",
                fontSize:"1rem",
                '&:hover': {
                  backgroundColor: '#1A4A421A',
                },
              }}>
                Cancel
              </MuiButton>
              <MuiButton   variant="contained" sx={{
                backgroundColor: '#1A4A42',
                color: 'white',
                padding: '0.8rem 0.8rem',
                fontWeight: 700,
                borderRadius: '0.6rem',
                textTransform: 'none',
                width: "7rem",
                fontSize:"1rem",
                '&:hover': {
                  backgroundColor: '#1A4A42',
                },
              }}>
                Confirm
              </MuiButton>
            </Box>
          </Box>
        </Modal>
      </Box>

      // Customizable Area End
    );
  }
}

// Customizable Area Start

const pipelineListContainerStyle = {
  padding: "1rem 1rem 1rem",
  borderRadius: "1.2rem",
  backgroundColor: "#fff",
  border: "1px solid #ccc",
  boxSizing: "border-box",
};

const iconStyle = {
  backgroundColor: "#1A4A424D",
  color: "#1A4A42",
  padding: "0.5rem",
  borderRadius: "0.6rem",
  height:"1.6rem",
  width:"1.7rem"
};

const addButtonStyle = {
  backgroundColor: "#1A4A424D",
  color: "#1A4A42",
  padding: "0.8rem",
  borderRadius: "0.6rem",
  marginRight:"0.8rem"
};

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '34rem',
  bgcolor: 'background.paper',
  borderRadius: '0.8rem',
  boxShadow: 2,
  p: 3,
};
const filterModalStyle = {
  position: 'absolute',
  top: '100%',
  right: '10%',
  width: '28rem',
  bgcolor: 'background.paper',
  borderRadius: '0.8rem',
  boxShadow: 2,
  zIndex: '99',
  p: 2,
}
const SearchContainer = styled("div")({
  position: "relative",
  minWidth: "50%",
  maxWidth: "600px",
  "@media (max-width: 600px)": {
    width: "100%"
  }
});

const SearchInput = styled("input")({
  width: "100%",
  padding: "1rem 1rem 1rem 3.8rem",
  height:"3.2rem",
  borderRadius: "1rem",
  border: "0.1rem solid #ccc",
  fontSize: "1.2rem",
  outline: "none",
  '&::placeholder': {
    color: "#1A4A42",
    opacity: 1,
    fontWeight: 700,
    fontSize:"1.2rem"
  }
});

const SearchIconWrapper = styled("div")({
  position: "absolute",
  left: "1rem",
  top: "50%",
  transform: "translateY(-50%)",
  pointerEvents: "none",
  color: "#1A4A42",
});

  
// Customizable Area End
