Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "cftasklistener3";
exports.labelBodyText = "cftasklistener3 Body";

exports.btnExampleTitle = "CLICK ME";
exports.getMethod="GET"
exports.postMethod="POST"
exports.patchMethod="PATCH"
exports.deleteMethod="delete"
exports.levelListApiEndPoint="/bx_block_roles_permissions/permissions"
exports.taskGroupListApiEndPoint="/bx_block_tasks/task_permissions/"
exports.individualUserApiEndPoint="/bx_block_roles_permissions/account_details/list_users"
exports.pipelineListApiEndPoint="/bx_block_cfpipelines1/pipelines"
exports.createTaskGroupApiEndPoint="/bx_block_tasks/task_permissions/"
// Customizable Area End