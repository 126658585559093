import React from "react";
// Customizable Area Start
import { styled } from "@mui/styles";
import {
  Box, TextField, Button, Typography, TypographyProps,
  Autocomplete, Paper, Grid, InputAdornment
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import CallMergeRoundedIcon from '@mui/icons-material/CallMergeRounded';
import CallSplitRoundedIcon from '@mui/icons-material/CallSplitRounded';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import SearchIcon from '@mui/icons-material/Search';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
export type Option = { label: string; value?: string; isNew?: boolean; id?: number | null, fixedPara?: boolean };
// Customizable Area End

import EditRuleController, {
  Props,
} from "./EditRuleController";

export default class EditRule extends EditRuleController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderTypography = (text: string, textAlign: TypographyProps['align'] = 'left') => {
    return (
      <Typography variant="body1" style={{ margin: "0.5rem 0", textAlign, color: "#1A4A42", fontWeight: 700, fontSize: "1rem" }}>
        {text}
      </Typography>
    )
  }



  addEditAutocomplete = (
    id: string,
    value: Option | null,
    options: Option[] | { label: string, value: string }[],
    placeholder: string,
    handleChangeDropDown: (event: React.SyntheticEvent, newValue: Option | null) => void,
    text?: string,
    disabled?: boolean,
    pipelineValues?:{label:string,value:string} []
  ) => {
    return (
      <React.Fragment>
        <Autocomplete
          disabled={disabled || value?.fixedPara}
          id={id}
          onChange={handleChangeDropDown}
          value={value}
          data-test-id={id}
          options={options}
          getOptionLabel={(option: Option) => option.label}
          isOptionEqualToValue={(option, value) => {
            return option.label === value?.label;
          }}
          componentsProps={{
            popupIndicator: {sx: { display: 'none' }}}}
          PaperComponent={({ children }) => (
            <Paper
              elevation={2}
              sx={{ border: '0.1rem solid #A8A29E',
                borderRadius: '0.8rem',
                marginTop: '0.2rem',
                padding: '0.5rem'}}>
              {children}
            </Paper>
          )}
          renderOption={(props, option, { selected }) => {
            return (
              <li
                {...props}
                style={{
                  fontSize: "1.1rem",backgroundColor: selected ? "#1A4A421A" : "transparent",
                  fontWeight: 400,color: selected ? "#1A4A42" : "#0F172A",
                }}
              >
                {option?.label}
              </li>
            );
          }}
          style={{ width: '100%' }}
          renderInput={(params) => (
            <TextField
            placeholder={!disabled && value == null ? placeholder : ""}
              style={{ marginBottom: "0.8rem", display: "flex", alignItems: "center" }}
              {...params}
              InputLabelProps={{ shrink: false }}
              InputProps={{
                ...params?.InputProps,
                endAdornment: (
                  <InputAdornment position="end" sx={{
                      "& .MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-fullWidth MuiInputBase-formControl MuiInputBase-adornedEnd MuiAutocomplete-inputRoot css-1hiurh-MuiInputBase-root-MuiOutlinedInput-root": {
                        paddingRight: "0 !important"}}}>
                    {id === "selectedPipeline" && !disabled && value == null ? (
                      <SearchIcon sx={{
                          fontSize: "2rem",
                          "& .MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-fullWidth MuiInputBase-formControl MuiInputBase-adornedEnd MuiAutocomplete-inputRoot css-1hiurh-MuiInputBase-root-MuiOutlinedInput-root": {
                            paddingRight: "0 !important"

                          },
                          color: "#64748B",
                          cursor: "pointer",
                        }}/>
                    ) :
                   !disabled && !value?.fixedPara 
                   || text == "Not parameter" ? (
                      <ArrowDropDownRoundedIcon
                        style={{color: "#1A4A42",cursor: "pointer",
                          fontSize: "2rem",}} />
                    ) : (
                      <></>
                    )}
                  </InputAdornment>),
                sx: {
                  display: "flex",
                  alignItems: "center",
                  borderRadius: "0.6rem",
                  height: "3rem !important",
                  fontWeight: 400,
                  paddingLeft: "0.3rem !important",
                  fontSize: "1.1rem",
                  flexWrap: "wrap",
                  overflowY: "auto",
                  backgroundColor: !disabled && value == null ? "transparent" : "#1A4A4233",
                  "&.Mui-disabled": {
                    background: "#1A4A4233",
                    color: "#292524 !important",opacity: 1,
                    WebkitTextFillColor: "#292524 !important",
                  },
                },
              }}
              sx={{ "& .MuiOutlinedInput-root": {
                  minHeight: text == "Not parameter" ? "3.5rem !important" : "1rem !important",
                  overflow: "auto",
                  padding: "0 0.3rem",
                  backgroundColor: !value?.fixedPara && !disabled ? "transparent" : "#1A4A4233",
                  border: !value?.fixedPara && !disabled ? "0.0.1px solid #1A4A4233" : "none",
                  "& fieldset": { border: !value?.fixedPara && !disabled ? "0.0.1px solid #1A4A4233" : "none" },
                },'& .MuiSelect-icon': {
                  display: 'none',
                },"& .MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-fullWidth MuiInputBase-formControl MuiInputBase-adornedEnd MuiAutocomplete-inputRoot css-1hiurh-MuiInputBase-root-MuiOutlinedInput-root, .MuiAutocomplete-hasClearIcon.css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root": {
                  paddingRight: "0 !important"
                },"& .MuiInputAdornment-root": {
                  marginRight: '1rem',
                },"&.MuiInputBase-root.MuiOutlinedInput-root": {paddingRight: "0 !important",
                },"& .MuiInputBase-adornedEnd": {
                  paddingRight: "0 !important",
                },
                "& .MuiAutocomplete-inputRoot": { paddingRight: "0 !important",},
                "& input::placeholder": {
                  fontSize: "1.1rem",opacity: 1,
                },
              }}
            />

          )}
        />
        {id === "selectedPipeline" && <Box sx={{ marginTop: "0.1rem", display: "flex", gap: "0.5rem", flexWrap: 'wrap', alignItems: 'center' }}>
          {pipelineValues && pipelineValues.map((item, index: number) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                alignItems: "center",
                fontSize: "1.1rem",
                fontWeight: 400,
                color: "#203447",
                border: '0.1rem solid #1A4A4233',
                backgroundColor: "white",
                borderRadius: "0.6rem",
                padding: "0.5rem",
              }}
            >
              {item.label}
              <CloseIcon
                data-test-id={"crossBtn" + index}
                sx={{
                  cursor: "pointer",
                  color: "#1A4A4280",
                  marginLeft: "0.5rem",
                  fontSize: "1.6rem"
                }}
                onClick={() => this.removePipeline(item)
                }
              />
            </Box>
          ))}
        </Box>}
      </React.Fragment>
    )
  }

  addEditRuleTextInput = (
    id: string,
    value: string,
    placeholder: string,
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
    text: string,
    disabled?: boolean
  ) => {
    return (
      <TextField
        data-test-id={id}
        id={id}
        value={value}
        disabled={disabled}
        placeholder={""}
        fullWidth
        onChange={handleChange}
        variant="outlined"
        sx={{"& .MuiOutlinedInput-root": {
            padding: "0.3rem 0.6rem",
            height: "3rem !important",
            minHeight: "3rem",
            borderRadius: "0.6rem",
            fontSize: "1.1rem",
            display: "flex",
            alignItems: "center",
            backgroundColor: !disabled ? "transparent" : "#1A4A4233",
            border: !disabled ? "0.0.1px solid #1A4A4233" : "none",
            "& fieldset": { border: !disabled ? "0.0.1px solid #1A4A4233" : "none" },
          },
          "& input::placeholder": { fontSize: "1.1rem", opacity: 1 },

          "& .MuiInputBase-root": {
            height: "3rem !important",
            minHeight: "3rem",
            fontSize: "1.1rem",
            display: "flex",
            alignItems: "center",
          },"& input": {
            fontSize: "1.1rem",
            padding: "0.6rem",
            height: "3rem !important",
            minHeight: "3rem",
            display: "flex",
            alignItems: "center",
          },
        }}
      />
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    const pipelineData = [{ label: "#P00000001", value: "#P00000001" },
       { label: "#P00000002", value: "#P00000002" }];
    return (
      <PipelineRuleContainer>
        <Box marginBottom={2}>
          <Box display='flex' alignItems='center' style={{ gap: "1rem" }}>
            <ArrowBackIcon data-test-id="backbtn" style={{ cursor: "pointer", fontSize: '2rem' }} onClick={this.props.navigation.goBack} />
            <Typography variant="h6" sx={{ fontWeight: 700, fontSize: '1.5rem', color: "#292524" }}>{"Edit Rule"}</Typography>
          </Box>
        </Box>

        <Box component="form" noValidate autoComplete="off" >

          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            <Box sx={{ flex: 1, marginRight: 1.5 }}>
              {this.renderTypography("Rule Name")}
              <TextField
                name="rule_name"
                data-test-id="rule_name"
                value={this.state.rule_name}
                onChange={this.handleChange}
                fullWidth
                InputLabelProps={{ shrink: false }}
                placeholder="Type rule name"
                sx={{
                  marginBottom: "0.4rem",
                  fontSize: "1.4rem",
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "0.6rem",
                    height: "3.3rem",
                    display: "flex",
                    alignItems: "center",
                    "& fieldset": {
                      height: "3.3rem",
                    },
                  },
                  "& .MuiInputBase-input": {
                    height: "3.3rem",
                    padding: "0.5rem",
                    fontSize: "1.2rem",
                    display: "flex",
                    alignItems: "center",
                  },
                  "& input::placeholder": {
                    fontSize: "1.2rem",
                    opacity: 1,
                  },
                }}
              />
            </Box>

            <Box sx={{ flex: 1 }}>
              <Box style={{ flex: 1 }}>
                {this.renderTypography("Rule Id")}
                <TextField
                  name="rule_id"
                  data-test-id="rule_id"
                  value={"#R0000001"}
                  fullWidth
                  disabled
                  InputLabelProps={{ shrink: false }}
                  sx={{
                    background: "#1A4A4233",
                    marginBottom: "0.4rem",
                    fontSize: "1.4rem",
                    borderRadius: "0.6rem !important",
                    "& .MuiOutlinedInput-root": {
                      height: "3.1rem",
                      display: "flex",
                      alignItems: "center",
                      '& fieldset': {
                        border: 'none',
                        height: "3.1rem",
                      },
                      '&:hover fieldset': {
                        border: 'none',
                      },
                      '&.Mui-focused fieldset': {
                        border: 'none',
                      },
                    },
                    "& .MuiInputBase-input": {
                      height: "3.1rem",
                      padding: "0.5rem",
                      fontSize: "1.2rem",
                      color: "#292524",
                      WebkitTextFillColor: "#292524 !important",
                      display: "flex",
                      alignItems: "center",
                    },
                    "& input::placeholder": {
                      fontSize: "1.2rem",
                      opacity: 1,
                    }
                  }}
                />
              </Box>
            </Box>
          </Box>
          <Box data-test-id="btnParameter" display={"flex"} marginBottom={2} marginTop={2} flexDirection={"row"}
            sx={{ background: "#1A4A4280", borderRadius: 1.5, justifyContent: "space-between" }} >
            <Typography paddingLeft={0.5} margin={0.5} variant="h6" sx={{ fontWeight: 700, fontSize: '1.4rem', color: "#F4F4F4F4" }}>
              {"Triggers"}
            </Typography>

          </Box>
          <Grid container>
            <Grid item md={12} sm={12} xs={12}>
              {this.state.triggers.map((trigger: any, index: number) => (
                <React.Fragment key={trigger.id}>
                  <Box sx={{ p: 1.5, borderRadius: 2, border: "1.5px solid #1A4A4233", marginBottom: 2 }}>
                    <Typography sx={{ color: "#1A4A42", fontWeight: 700, fontSize: "1rem" }} gutterBottom>
                      Trigger {index + 1}</Typography>
                    <Grid container spacing={2} alignItems="center">
                      <Grid
                        item xs={index > 0 ? 11 : 12}
                        sx={{ display: "flex", alignItems: "center", width: "100%" }}>
                        {this.addEditAutocomplete(
                          `selectedTrigger-${trigger.id}`,
                          trigger.selectedTrigger,
                          this.state.triggerDetails.map((t) => ({ label: t.trigger_name })),
                          "Select Trigger",
                          this.handleAutoComplete(index),
                          "Not parameter" )}
                      </Grid>
                      {index > 0 && (
                        <Grid item xs={1} >
                          <DeleteOutlinedIcon
                            data-test-id={`deleteTrigger-${index}`}
                            sx={{
                              width: "2.2rem",height: "2.5rem",
                              color: "#1A4A42",cursor: "pointer",
                              marginLeft: "1rem", mb: "0.5rem"
                            }}
                            onClick={() => this.handleDeleteTrigger(index)}/>
                        </Grid>
                      )}
                    </Grid>
                    {trigger.parameters.map((param: any, paramIndex: number) => (
                      <React.Fragment key={`${trigger.id}-param-${paramIndex}`}>
                        <Typography sx={{
                            display: "flex",
                            fontWeight: 700,
                            fontSize: "1rem",
                            justifyContent: "center",
                            marginBottom: 1,
                            marginTop: 1,
                          }}>
                          Parameter {paramIndex + 1}
                        </Typography>
                        <Grid container spacing={2} mb={2}>
                          <Grid item xs={3}>
                            <Typography sx={{ color: "#1A4A42", fontWeight: 700, fontSize: "0.8rem" }} gutterBottom>
                              Parent Parameter
                            </Typography>
                            {this.addEditAutocomplete(
                              `selectedParentParameter-${trigger.id}-${paramIndex}`,
                              param.selectedParentParameter,
                              param.parentParameterOptions,
                              "Select Parent Parameter",
                              this.handleParentParameterChange(index, paramIndex),
                              "Parent Parameter",
                              !trigger.selectedTrigger
                            )}
                          </Grid>
                          <Grid item xs={3}>
                            <Typography sx={{ color: "#1A4A42", fontWeight: 700, fontSize: "0.8rem" }} gutterBottom>
                              Child Parameter
                            </Typography>
                            {this.addEditAutocomplete(
                              `selectedChildParameter-${trigger.id}-${paramIndex}`,
                              param.selectedChildParameter,
                              param.childParameterOptions,
                              "Select Child Parameter",
                              this.handleChildParameterChange(index, paramIndex),
                              "Child Parameter",
                              !trigger.selectedTrigger
                            )}
                          </Grid>
                          <Grid item xs={3}>
                            <Typography sx={{ color: "#1A4A42", fontWeight: 700, fontSize: "0.8rem" }} gutterBottom>
                              Condition
                            </Typography>
                            {this.addEditAutocomplete(
                              `selectedCondition-${trigger.id}-${paramIndex}`,
                              param.selectedCondition,
                              param.conditionOptions,
                              "Select Condition",
                              this.handleConditionChange(index, paramIndex),
                              "Condition",
                              !trigger.selectedTrigger
                            )}
                          </Grid>
                          <Grid item xs={3}>
                            <Typography sx={{ color: "#1A4A42", fontWeight: 700, fontSize: "0.8rem" }} gutterBottom>
                              Input
                            </Typography>
                            {this.addEditRuleTextInput(
                              `selectedInput-${trigger.id}-${paramIndex}`,
                              param.selectedInput,
                              "Enter Input",
                              this.handleInputChange(index, paramIndex),
                              "Input",
                              !trigger.selectedTrigger
                            )}
                          </Grid>
                        </Grid>
                      </React.Fragment>
                    ))}
                  </Box>
                  <Box display="flex" justifyContent="center" gap={2} sx={{ marginBottom: 2 }}>
                    <Button
                      startIcon={<CallMergeRoundedIcon />}
                      data-test-id={`btn-And-${index}`}                    
                      sx={{
                        textTransform: "none",
                        fontWeight: "700",
                        width: "15%",
                        background:this.state.activeTriggerIndexes.some((item: { index: number; activeButton: string }) =>
                              item.index === index && item.activeButton === "AND" ) 
                            || trigger.type === "AND"
                            ? "#1A4A42E5" : "#1A4A421A",
                        color: this.state.activeTriggerIndexes.some(
                          (item: { index: number; activeButton: string }) =>item.index === index && item.activeButton === "AND") 
                          || trigger.type === "AND"
                          ? "#fff" : "#1A4A42",
                        border: "2px solid #1A4A42",
                        borderRadius: 2,
                        paddingLeft: 1,paddingRight: 1,
                      }}
                      onClick={(e) => this.handleAddTrigger("AND", index, e)}>
                      Add AND Trigger
                    </Button>
                    <Button
                      sx={{
                        textTransform: "none",
                        fontWeight: "700",
                        width: "15%",
                        background:this.state.activeTriggerIndexes.some(
                            (item: { index: number; activeButton: string }) =>item.index === index && item.activeButton === "OR") || trigger.type === "OR"
                            ? "#1A4A42E5"
                            : "#1A4A421A",
                        color: this.state.activeTriggerIndexes.some(
                          (item: { index: number; activeButton: string }) =>item.index === index && item.activeButton === "OR") || trigger.type === "OR"
                          ? "#fff" : "#1A4A42",
                        border: "2px solid #1A4A42",
                        borderRadius: 2,paddingLeft: 1,
                        paddingRight: 1,
                      }}
                      data-test-id={`btn-OR-${index}`}
                      startIcon={<CallSplitRoundedIcon />}
                      onClick={(e) => this.handleAddTrigger("OR", index, e)}>
                      Add OR Trigger
                    </Button>
                  </Box>
                </React.Fragment>
              ))}
            </Grid>
          </Grid>
          <Box data-test-id="btnParameter" display={"flex"} marginBottom={2} flexDirection={"row"} sx={{ background: "#1A4A4280", borderRadius: 1.5, justifyContent: "space-between" }} >
            <Typography paddingLeft={0.5} margin={0.5} variant="h6" sx={{ fontWeight: 700, fontSize: '1.4rem', color: "#F4F4F4F4" }}>
              {"Actions"}</Typography>
          </Box>
          <Grid container>
            <Grid 
            item md={12} 
            sm={12} 
            xs={12}>
              {this.state.actions.map((action: any, index: number) => (
                <Box
                  key={action.id}
                  sx={{ p: 1.5, borderRadius: 2, border: "1.5px solid #1A4A4233", mb: 2 }}
                >
                  <Grid container display={"flex"} spacing={2} alignItems={"center"}>
                    <Grid item xs={index > 0 ? 5.5 : 6} sx={{ width: "100%" }}>
                      <Typography
                        sx={{ color: "#1A4A42", fontWeight: 700, fontSize: "1rem" }}
                        gutterBottom
                      >
                        Action {index + 1}
                      </Typography>
                      {this.addEditAutocomplete(
                        `selectedAction-${action.id}`,
                        action.selectedAction,
                        this.state.actionDetails.map((action: { action_name: any; }) => ({
                          label: action.action_name,
                        })),
                        "Select Action",
                        this.handleActionAutoComplete(index),

                        "Not parameter"
                      )}
                    </Grid>
                    <Grid item xs={index > 0 ? 5.5 : 6} sx={{ width: "100%" }}>
                      <Typography
                        sx={{ color: "#1A4A42", fontWeight: 700, fontSize: "1rem" }}
                        gutterBottom
                      >
                        Waiting Time
                      </Typography>
                      {this.addEditAutocomplete(
                        `selectedWaitingTime-${action.id}`,
                        action.selectedWaitingTime,
                        action.waitingTimeOptions,
                        "Select a specified amount of time",
                        this.handleActionAutoComplete(index),
                        "Not parameter"
                      )}
                    </Grid>
                    {index > 0 && (
                      <Grid item xs={1}>
                        <DeleteOutlinedIcon
                          data-test-id={`deleteAction-${index}`}
                          sx={{
                            ml: "1rem", mt: "0.5rem"
                            , width: "2.2rem", height: "2.2rem", color: "#1A4A42"
                          }}
                          onClick={() => this.handleDeleteAction(index)}

                        />
                      </Grid>
                    )}
                  </Grid>
                  {action.parameters.map((param: any, paramIndex: number) => (
                    <React.Fragment key={`${action.id}-param-${paramIndex}`}>
                      <Typography
                        sx={{
                          display: "flex",
                          fontWeight: 700,
                          fontSize: "1rem",
                          justifyContent: "center",
                          marginBottom: 1,
                          marginTop: 1,
                        }}
                      >
                        Parameter {paramIndex + 1}
                      </Typography>

                      <Grid container spacing={2} mb={2}>
                        <Grid item xs={3}>
                          <Typography
                            sx={{ color: "#1A4A42", fontWeight: 700, fontSize: "0.8rem" }}
                            gutterBottom
                          >
                            Parent Parameter
                          </Typography>
                          {this.addEditAutocomplete(
                            `selectedActionParentParameter-${action.id}-${paramIndex}`,
                            param.selectedParentParameter,
                            param.parentParameterOptions,
                            "Select Parent Parameter",
                            this.handleActionParentParameterChange(index, paramIndex),

                            "Parent Parameter",
                            !action.selectedAction

                          )}
                        </Grid>

                        <Grid item xs={3}>
                          <Typography
                            sx={{ color: "#1A4A42", fontWeight: 700, fontSize: "0.8rem" }}
                            gutterBottom
                          >
                            Child Parameter
                          </Typography>
                          {this.addEditAutocomplete(
                            `selectedActionChildParameter-${action.id}-${paramIndex}`,
                            param.selectedChildParameter,
                            param.childParameterOptions,
                            "Select Child Parameter",
                            this.handleActionChildParameterChange(index, paramIndex),
                            "Child Parameter",
                            !action.selectedAction

                          )}
                        </Grid>

                        <Grid item xs={3}>
                          <Typography
                            sx={{ color: "#1A4A42", fontWeight: 700, fontSize: "0.8rem" }}
                            gutterBottom
                          >
                            Condition
                          </Typography>
                          {this.addEditAutocomplete(
                            `selectedActionCondition-${action.id}-${paramIndex}`,
                            param.selectedCondition,
                            param.conditionOptions,
                            "Select Condition",
                            this.handleActionConditionChange(index, paramIndex),
                            "Condition",
                            !action.selectedAction
                          )}
                        </Grid>
                        <Grid item xs={3}>
                          <Typography
                            sx={{ color: "#1A4A42", fontWeight: 700, fontSize: "0.8rem" }}
                            gutterBottom>
                            Input
                          </Typography>
                          {this.addEditRuleTextInput(
                            `selectedActionInput-${action.id}-${paramIndex}`,
                            param.selectedInput,
                            "Enter Input",
                            this.handleActionInputChange(index, paramIndex),
                            "Input",
                            !action.selectedAction
                          )}
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  ))}
                </Box>
              ))}

              <Box display="flex" justifyContent="center" gap={2}>
                <Button
                  data-test-id="addAction"
                  startIcon={<AddCircleOutlineOutlinedIcon />}
                  sx={{
                    color: "#1A4A42",
                    border: "2px solid #1A4A42",
                    borderRadius: 2,
                    paddingLeft: 1,
                    paddingRight: 1,
                    width: "12%",
                    marginTop: 1,
                    marginBottom: 1,
                    textTransform: "none",
                    fontWeight: "700",
                    background: "#1A4A421A",
                  }}
                  onClick={this.addNewAction}>
                  Add Action
                </Button>
              </Box>
            </Grid>
          </Grid>;

          {this.renderTypography("Associated Pipeline ID")}
          {this.addEditAutocomplete(
            "selectedPipeline",
            null,
            pipelineData,
            "#Pipeline ID-Pipeline Name",
            this.handlePipelineChange,
            "Not parameter",
            false,
            this.state.selectedPipeline
          )}
          <CustomWarningBox>
            <CustomCreateEditButton type="submit"
              disabled={false}
              style={{
                backgroundColor: "#1A4A42",
                color: "#fff",
                cursor: "pointer",
              }}>{"Save Changes"}
            </CustomCreateEditButton>
          </CustomWarningBox>
        </Box>
      </PipelineRuleContainer >
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const PipelineRuleContainer = styled("div")({
  padding: "2rem 2rem 1rem",
  borderRadius: "1.2rem",
  backgroundColor: "#fff",
  border: "1px solid #ccc",
  fontFamily: "Raleway, sans-serif",
  boxSizing: "border-box",
});

const CustomWarningBox = styled("div")(
  {
    border: "0.1rem dashed #292524",
    padding: "1.5rem",
    borderRadius: "1.2rem",
    backgroundColor: "#f9f9f9",
    marginTop: "2.5rem"
  });

const CustomCreateEditButton = styled("button")({
  width: "100%",
  padding: "1rem",
  border: "none",
  borderRadius: "0.8rem",
  fontSize: "1.4rem",
  cursor: "pointer",
  fontWeight: 600,
});
// Customizable Area End
