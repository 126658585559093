import React from "react";

import { Input, Button, Typography } from '@builder/component-library';

// Customizable Area Start
import { Box, Button as MuiButton, Typography as MuiTypography, TextField, IconButton, Modal, Divider, Checkbox, styled, Menu, MenuItem } from '@mui/material'
import FilterListIcon from '@mui/icons-material/FilterList';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CloseIcon from '@mui/icons-material/Close';
import { formatDateTimeTable, PipelineType } from "./utils/generatePipeline";
import PaginationComponent from "./utils/PaginationComponent";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from "dayjs"
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { LoadingSpinner } from "./CreatePipeline.web";
import SearchIcon from "@mui/icons-material/Search";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
const icon = <CheckBoxOutlineBlankIcon style={{ height: "2rem", width: "2rem" }} />;
const checkedIcon = <CheckBoxIcon style={{ height: "2rem", width: "2rem", color: "#1A4A42", borderRadius: "20rem" }} />;
// Customizable Area End

import Cfpipelines1Controller, {
  Props,
  configJSON,
} from "./Cfpipelines1Controller";
import { Checked, UnChecked } from "./assets";

export default class Cfpipelines1 extends Cfpipelines1Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderColumnTypography = (flex: number, text: string | number,field: string) => {
    const { sortField, sortOrder } = this.state;
    let sortIcon = null;
    if (sortField === field) {
      sortIcon = sortOrder === 'asc' ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />;
    } else {
      sortIcon = <ArrowDropDownIcon />;
    }

    return (
      <MuiTypography variant="h6" data-test-id={"column-typography"+field} onClick={() => this.handleSortPipeline(field)} 
      style={{display: "flex", alignItems: "center", fontSize: '1.2rem', flex: flex, fontWeight: 700, color: '#3E3E3E' }}>{text}{text == "Actions" ? "" : sortIcon}</MuiTypography>
    )
  }
  renderDatePicker = (text: string, value: Dayjs, onChangeText: string, minDate: Dayjs | null = null) => {
    return (
      <Box><MuiTypography style={{
        fontSize: '1.2rem', fontWeight: 400, color: '#1A4A42', marginBottom: "0.2rem"
      }}>{text}</MuiTypography>
        <DatePicker
          disableFuture
          value={value}
          minDate={minDate}
          format="DD-MM-YYYY"
          slots={{
            textField: (params:any) => (
              <TextField
                {...params}
                placeholder="Select Date"
                sx={{
                  fontSize: '1.2rem',
                }}
                InputProps={{
                  ...params.InputProps,
                  sx: {
                    fontWeight: 400,
                    fontSize: "1.2rem",
                    borderRadius: "0.8rem",
                    '& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': {
                      padding: '0.6rem 0 0.6rem 1rem',
                      fontSize: "1.2rem",

                    }
                  },
                }}
              />
            ),
          }}
          slotProps={{
            popper: {
              sx: {
                '& .MuiPaper-root': {
                  minWidth: '30rem',
                  border: '0.1rem solid #E2E8F0',
                  marginTop: '0.2rem',
                  borderRadius: '0.8rem'
                },
                '& .MuiTypography-root': {
                  fontSize: '1.8rem !important',
                },
                '& .MuiPickersArrowSwitcher-button': {
                  fontSize: '2rem !important',
                  '& .MuiSvgIcon-root': {
                    fontSize: '2rem !important',
                  },
                },
                '& .css-dplwbx-MuiPickersCalendarHeader-label': {
                  fontSize: '1.6rem'
                },
                '& .css-1tkx1wf-MuiSvgIcon-root-MuiPickersCalendarHeader-switchViewIcon': {
                  width: '2rem',
                  height: '2rem'
                },
                '& .css-13m8yui-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected)': {
                  border: 'none',
                  fontWeight: 700
                },

                '& .css-1b2k5pm-MuiPickersYear-yearButton': {
                  fontSize: '1.4rem'
                },
                '& .MuiDayCalendar-weekDayLabel': {
                  fontSize: '1.4rem !important',
                  fontWeight: 400
                },
              },
            },
            day: {
              sx: {
                fontSize: '1.2rem',
              },
            },
          }}
          onChange={(newValue) => this.handleDateChange(onChangeText, newValue)}
          data-test-id="date_picker"
        />
      </Box>
    )
  }

  renderFilterDateSection = (title: string, name: string, inputState: any, onChangeText: string, inputState2: any, onChangeText2: string) => {
    const { openRows, dates } = this.state
    const minDateForToPicker = dates.createdFrom
    ? dayjs(dates.createdFrom)
    : null;
    return (
      <Box style={{ marginBottom: '0.8rem' }}>
        <Box display="flex" justifyContent='space-between' alignItems='center' flexWrap='wrap'>
          <MuiTypography variant="h6" sx={{ fontWeight: 700, color: "#292524", fontSize: '1.4rem' }}>{title}</MuiTypography>
          <IconButton data-test-id={"down_up_arrow"+name} onClick={() => this.handleRowClick(name)}>
            {openRows[name] ? <KeyboardArrowUpIcon style={{ color: "#0F172A", fontSize: '1.8rem' }} /> : <KeyboardArrowDownIcon style={{ color: "#0F172A", fontSize: '1.8rem' }} />}
          </IconButton>
        </Box>
        {openRows[name] &&
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box
              sx={{
                paddingTop: '0',
                display: "flex",
                gap: "1rem"
              }}
            >
              {this.renderDatePicker("From", inputState, onChangeText)}
              {this.renderDatePicker("To", inputState2, onChangeText2, minDateForToPicker)}
            </Box>
          </LocalizationProvider>
        }
      </Box>
    )
  }
  renderRowTypography = (flex: number, text: string | number, row: PipelineType) => {
    return (
      <Box sx={{display:"flex",flexDirection:"column",flex:flex}}>
<MuiTypography variant="h6" style={{
        fontSize: '1.1rem',
        whiteSpace: 'normal',
        wordWrap: 'break-word',
        wordBreak: 'break-word',
        flex: flex, fontWeight: 500, cursor: 'pointer', color: '#203447'
      }} 
      data-test-id={`rowTypography${text}`}
      onClick={() => this.handlePipelineNavigate(row)}>{text}</MuiTypography>
<Menu
data-test-id={"tootipView"+text}
                  anchorEl={this.state.openToolTip}
                  open={Boolean(this.state.openToolTip)}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}                
                  onClose={this.handleCloseToolTip}
                  slotProps={{
                    paper: {
                      sx: {
                        borderRadius: "12px",
                        boxShadow: "none",
                        border: "1px solid #1A4A4233",
                        minWidth: "120px",
                        marginTop:0.5
                      },
                    },
                  }}
                >
                  <MenuItem  sx={{fontWeight:"500", fontSize:"1.1rem", color: "#0F172A",textAlign: "right", justifyContent: "flex-end" }}>Duplicate</MenuItem>
                  <MenuItem sx={{fontWeight:"500",fontSize:"1.1rem", color: "#0F172A",textAlign: "right", justifyContent: "flex-end" }}
                  data-test-id={"toolTipEdit"+text}
                  onClick={()=>this.handlePipelineDetailsNavigate(row)}
                  >Edit</MenuItem>
                  <MenuItem 
                   sx={{fontWeight:"500",fontSize:"1.1rem", color: "#0F172A",textAlign: "right", justifyContent: "flex-end" }}
                   data-test-id={"toolTipDelete"+text}
                   onClick={()=>this.handleDeleteToggle()}
                   >
                    Delete
                  </MenuItem>
                </Menu>

      </Box>
    )
  }
  
  renderCheckBox = (name: string, text: string, checkValue: boolean) => {

    return (
      <Box
        display="flex"
        alignItems="center"
        sx={{
          border: "0.1rem solid #1A4A4233",
          borderRadius: "0.8rem",
          boxSizing: "border-box",
          flex: "1 1 calc(50% - 1rem)"

        }}
      >
        <Checkbox
          checked={checkValue}
          onChange={this.handleClinicChange}
          icon={icon}
          checkedIcon={checkedIcon}
          name={name}
        />
        <MuiTypography
          sx={{
            fontSize: "1.2rem",
            fontWeight: 400,
            color: "#0F172A"
          }}
        >
          {text}
        </MuiTypography>
      </Box>
    );
  }
  renderFilterSection = (title: string, name: string, data: any, handleChange: (event: any) => void) => {
    const { openRows } = this.state;
  
    return (
      <Box>
        <Box display="flex" justifyContent="space-between" alignItems="center" flexWrap="wrap">
          <MuiTypography variant="h6" sx={{ fontWeight: 700, color: "#292524", fontSize: "1.4rem" }}>
            {title}
          </MuiTypography>
          <IconButton data-test-id={"arrows"+name} onClick={() => this.handleRowClick(name)}>
            {openRows[name] ? (
              <KeyboardArrowUpIcon style={{ color: "#0F172A", fontSize: "1.8rem" }} />
            ) : (
              <KeyboardArrowDownIcon style={{ color: "#0F172A", fontSize: "1.8rem" }} />
            )}
          </IconButton>
        </Box>
        {openRows[name] && (
          <Box display="flex" sx={{ gap: "1rem", flexWrap: "wrap" }}>
            {Object.keys(data).map((key) => (
              <Box
                key={key}
                display="flex"
                alignItems="center"
                sx={{
                  border: "0.1rem solid #1A4A4233",
                  borderRadius: "0.8rem",
                  boxSizing: "border-box",
                  flex: "1 1 calc(50% - 1rem)",
                }}
              >
                <Checkbox
                  data-test-id={"checkbox"+key}
                  checked={data[key]}
                  onChange={handleChange}
                  icon={icon}
                  checkedIcon={checkedIcon}
                  name={key}
                />
                <MuiTypography sx={{ fontSize: "1.2rem", fontWeight: 400, color: "#0F172A" }}>
                  {key.replace(/^\w/, (c) => c.toUpperCase())}
                </MuiTypography>
              </Box>
            ))}
          </Box>
        )}
      </Box>
    );
  };
  renderClinicFilterSection = () => this.renderFilterSection("Associated Clinics", "clinics", this.state.clinics, this.handleClinicChange);
  renderPermissionFilterSection = () => this.renderFilterSection("Permission Levels", "permissions", this.state.levels, this.handleLevelChange)

  pipelineList = () => {
    const { currentPage, pageSize, filteredData } = this.state;
    const sortedRows = this.handleSortPipelineData()
    const paginatedRows = sortedRows.slice(currentPage * pageSize, (currentPage + 1) * pageSize);
    const startIndex = currentPage * pageSize + 1;
    const endIndex = Math.min((currentPage + 1) * pageSize, sortedRows.length);
    const totalResults = sortedRows.length;
    const isAllSelected = paginatedRows.every((row) => this.state.openRows[row.id] === true);
    const anySelected = paginatedRows.some((row) => {
      return this.state.openRows[row.id] === true;
    });
    const selectedCount = Object.values(this.state.openRows).filter(value => value === true).length;
    return <Box style={{ display: "flex", flexDirection: "column" }}>
      <Box style={{ display: "flex", marginBottom: '1.6rem', justifyContent: "space-between", alignItems: 'center', flexWrap: 'nowrap' }}>
        <MuiTypography variant="h6" sx={{ fontWeight: 700, fontSize: '1.5rem', color: "#292524" }}>
          Pipelines
        </MuiTypography>

        <Box display='flex' alignItems='center' gap={2}>
          <SearchBar testID="btnSearch" handleSearchField={this.handleSearchPipeline} />
              <MuiButton onClick={this.handleCreatePipeline} sx={{
            backgroundColor: '#1A4A42',
            color: 'white',
            padding: '0.5rem 1.6rem',
            height:"2.6rem",
            fontWeight: 700,
            borderRadius: '0.8rem',
            textTransform: 'none',
            fontSize: '1rem',
            whiteSpace: 'nowrap',
            '&:hover': {
              backgroundColor: '#1B5C54',
              color: '#fff',
            }
          }}>
            Create Pipeline
          </MuiButton>
          <Box style={{ position: 'relative' }}>
            <FilterListIcon data-test-id="toggleFilter" onClick={this.toggleFilterModal} style={{ ...iconStyle, cursor: "pointer", fontSize: "2rem" }} />
            {this.state.isFilterModal &&
                <Box
                  sx={filterModalStyle}
                >
                  <Box display="flex" justifyContent="space-between" alignItems="center" >
                    <MuiTypography variant="h6" sx={{ fontWeight: 700, color: "#292524", fontSize: '1.4rem' }}>Filters</MuiTypography>
                    <IconButton data-test-id="closeFilter" onClick={() => this.toggleFilterModal()} >
                      <CloseIcon style={{ color: "#0F172A", fontSize: '1.8rem' }} />
                    </IconButton>
                  </Box>
                  {this.renderFilterDateSection("Date Created", "created_at", this.state.dates.createdFrom, "createdFrom", this.state.dates.createdTo, "createdTo")}
                  {this.renderFilterDateSection("Last Edited", "updated_at", this.state.dates.editedFrom, "editedFrom", this.state.dates.editedTo, "editedTo")}
                  {this.renderClinicFilterSection()}
                  {this.renderPermissionFilterSection()}
                  <Box display="flex" justifyContent="flex-end" gap={1} mt={2}>
                    <MuiButton onClick={this.resetFilters} variant="contained" sx={{
                      backgroundColor: '#fff',
                      color: '#1A4A42',
                      height: '3rem',
                      padding: '0.8rem',
                      fontWeight: 700,
                      borderRadius: '0.8rem',
                      textTransform: 'none',
                      fontSize: '1rem',
                      width: '9rem',
                      '&:hover': {
                        backgroundColor: '#F5FEFD',
                      },
                      border:"1px solid #1A4A42"
                    }}>
                      Reset
                    </MuiButton>
                    <MuiButton variant="contained" onClick={this.handleApplyPipelineFilter} sx={{
                      backgroundColor: '#1A4A42',
                      color: 'white',
                      height: '3rem',
                      padding: '0.8rem',
                      fontWeight: 700,
                      borderRadius: '0.8rem',
                      textTransform: 'none',
                      fontSize: '1rem',
                      width: '9rem',
                      '&:hover': {
                        backgroundColor: '#1B5C54',
                      }
                    }}>
                      Apply
                    </MuiButton>
                  </Box>
                </Box>
              }
          </Box>
        </Box>
      </Box>
      <Box display="flex" flexDirection='column' gap={1}>
      <Box display="flex" flexDirection={"column"} style={{ backgroundColor: '#29252414', padding: '0rem 1rem', borderRadius: '0.8rem' }}>
     <Box sx={{ display: "flex", width: "100%" }}>
    <Box sx={{ flex: 2, paddingLeft: "1rem" }}>
      <Checkbox
        data-test-id="selectAll"
        sx={{ '&:hover': { bgcolor: 'transparent' } }}
        disableRipple
        color="default"
        checked={isAllSelected}
        onChange={()=>this.handleSelectAll(paginatedRows)}
        checkedIcon={<img src={Checked} style={{ width: '1.6rem' }} />}
        icon={<img src={UnChecked} style={{ width: '1.6rem' }} />}
        inputProps={{ 'aria-label': 'Select All Checkbox' }}
      />
    </Box>

    {this.renderColumnTypography(3, 'Pipeline ID', "id")}
    {this.renderColumnTypography(5, 'Name', "name")}
    {this.renderColumnTypography(4, 'Date Created', "created_at")}
    {this.renderColumnTypography(4, 'Last Edited', "updated_at")}
    {this.renderColumnTypography(4, 'Associated Clinic', "associated_clinic")}
    {this.renderColumnTypography(2, 'Actions', "actions")}
    
  </Box>
  {anySelected && <Box  sx={{display:"flex",marginLeft:8,marginBottom:1, flexDirection:"row"}}>
                    <Box sx={{display:"flex",flexDirection:"row",margin:1}} >
                    <MuiTypography sx={{marginRight:1}}>{selectedCount}</MuiTypography>
                    <MuiTypography>Selected</MuiTypography>
                    </Box>
                    <Divider orientation="vertical" flexItem />
                    <MuiTypography sx={{margin:1,color:"#1A4A42"}}>Deselect</MuiTypography >
                    <Divider orientation="vertical" flexItem />
                    <MuiTypography data-test-id="deleteSelected" 
                    onClick={()=>this.handleDeleteToggle()}
                    sx={{margin:1,color:"#E46760",
                      "&:hover":{cursor:"pointer"}

                    }}  >
                      Delete Selected</MuiTypography>
  
  </Box>}
</Box>


        {paginatedRows.length > 0 ? paginatedRows.map(row => (
          <Box display='flex' key={row.id} style={{ padding: '0rem 1rem', borderBottom: '0.1rem solid #1A4A421A', alignItems: 'center' }}>
           
           <Box sx={{ flex:2,paddingLeft:"1rem" }}>
                                   <Checkbox
                                       sx={{
                                           '&:hover': { bgcolor: 'transparent' },
                                       }}
                                       data-test-id="singleCheckbox"
                                       disableRipple
                                       color="default"
                                       checked={this.state.openRows[row.id]|| false}
                                       onChange={()=>this.handleRowClick(row.id)}
                                       checkedIcon={<img src={Checked} style={{width:"1.6rem"}} />}
                                       icon={ <img src={UnChecked} style={{width:"1.6rem"}}  />}
                                       inputProps={{ 'aria-label': 'Checkbox demo' }}
           
                                   />
                               </Box>
            {this.renderRowTypography(3, row.pipeline_serial_id, row)}
            {this.renderRowTypography(5, row.pipeline_name, row)}
            {this.renderRowTypography(4, formatDateTimeTable(row.created_at), row)}
            {this.renderRowTypography(4, formatDateTimeTable(row.updated_at), row)}
            {this.renderRowTypography(4, "Global", row)}
            <Box style={{ flex: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <IconButton data-test-id="tooltip-btn-test" sx={addButtonStyle} 
            style={{ padding: "0.4rem" }} onClick={(event) => { this.handleOpenTooltip(event,row) }}>
                <MoreHorizOutlinedIcon fontSize="medium" />
            </IconButton>
            </Box>

          </Box>
        )) : <Box display='flex' justifyContent='center' alignItems='center'><MuiTypography variant="h5">No Pipelines Found</MuiTypography></Box>}
      </Box>
      <PaginationComponent currentPageIndex={currentPage} requiredPageSize={pageSize} startIndex={startIndex} endIndex={endIndex} totalResults={totalResults} handlePageChange={this.handlePageChange}/>     
    </Box>
  }

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box sx={pipelineListContainerStyle}>
        {this.state.isLoading ?
                <LoadingSpinner />
                :
          this.pipelineList()}
        <Modal
          data-test-id="delete-pipeline-modal"
          open={this.state.isDeletePipelineModal}
          onClose={this.toggleDeleteModal}
          aria-labelledby="edit-stage-modal"
          aria-describedby="edit-stage-form"
        >
          <Box sx={modalStyle}>

            <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
              <MuiTypography  sx={{fontSize:"1.4rem", fontWeight: 700, }}>Delete Selected Pipelines</MuiTypography>
              <IconButton onClick={this.toggleDeleteModal}>
                <CloseIcon />
              </IconButton>
            </Box>

            <Divider sx={{ backgroundColor: "#1A4A4233", marginBottom: "1rem" }} />
            <Box sx={{ display: "flex"}}>
              <MuiTypography  style={{ fontSize: '1rem',fontWeight:700 }}>
                {`Type "DELETE" below in the input field to proceed.`}</MuiTypography>
            </Box>
            <TextField
            data-test-id="deleteTextInput"
      placeholder="Enter value"
      onChange={(event)=>this.handleDeleteChange(event)}
      fullWidth
      sx={{
        marginTop:"2rem",
        "& .MuiOutlinedInput-root": {
          borderColor: "#94A3B8",
          height:"3.2rem",
          borderRadius:"0.8rem",

          "& fieldset": {
            borderColor: "", 
          },
          "&:hover fieldset": {
            borderColor: "#94A3B8", 
          },
          "&.Mui-focused fieldset": {
            borderColor: "teal",
          },
        },
        "& .MuiInputBase-input::placeholder": {
          color: "#94A3B8", fontWeight:700
        },
      }}
    />
{this.state.deleteError.length>0 && <MuiTypography sx={{ marginTop: '0.5rem', fontSize: '1rem' }} color="red" variant="body2">
  {this.state.deleteError}</MuiTypography>
}    

            <Divider sx={{ backgroundColor: "#1A4A4233", marginBottom: "1rem",marginTop:"2rem" }} />

            <Box display="flex" justifyContent="flex-end" gap={2} mt={3}>
              <MuiButton 
              data-test-id="cancelDelete"
              onClick={this.toggleDeleteModal} variant="contained" sx={{
                backgroundColor: '#1A4A421A',
                color: '#1A4A4299',
                padding: '0.8rem 0.8rem',
                fontWeight: 700,
                borderRadius: '0.6rem',
                textTransform: 'none',
                width: "7rem",
                fontSize:"1rem",
                '&:hover': {
                  backgroundColor: '#1A4A421A',
                },
              }}>
                Cancel
              </MuiButton>
              <MuiButton 
                data-test-id="confirmDelete"
                onClick={()=>this.deleteSelectedPipelines()}
                variant="contained" sx={{
                backgroundColor: '#1A4A42',
                color: 'white',
                padding: '0.8rem 0.8rem',
                fontWeight: 700,
                borderRadius: '0.6rem',
                textTransform: 'none',
                width: "7rem",
                fontSize:"1rem",
                '&:hover': {
                  backgroundColor: '#1A4A42',
                },
              }}>
                Confirm
              </MuiButton>
            </Box>
          </Box>
        </Modal>
        <Modal
          open={this.state.isPipelineDuplicateModal}
          onClose={this.toggleDuplicateModal}
          aria-labelledby="edit-stage-modal"
          aria-describedby="edit-stage-form"
        >
          <Box sx={modalStyle}>

            <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
              <MuiTypography variant="h6" sx={{ fontWeight: 700, color: "#292524" }}>Duplicate Pipeline</MuiTypography>
              <IconButton onClick={this.toggleDuplicateModal}>
                <CloseIcon />
              </IconButton>
            </Box>

            <Divider sx={{ backgroundColor: "#1A4A4233", marginBottom: "1rem" }} />
            <Box sx={{ display: "flex", justifyContent: 'center', alignItems: 'center' }}>
              <MuiTypography variant="h6" style={{ fontSize: '1.2rem' }}>{`Are you sure want to duplicate pipeline: ${this.state.singlePiplineDuplicate.pipeline_name} ?`}</MuiTypography>
            </Box>
            <Box display="flex" justifyContent="center" gap={2} mt={3}>
              <MuiButton onClick={this.toggleDuplicateModal} variant="contained" sx={{
                backgroundColor: '#1A4A421A',
                color: '#1A4A4299',
                padding: '0.8rem 0',
                fontWeight: 700,
                borderRadius: '0.8rem',
                textTransform: 'none',
                width: "12rem",
                fontSize: '1.2rem'
              }}>
                Cancel
              </MuiButton>
              <MuiButton onClick={this.handleDuplicatePipeline} variant="contained" sx={{
                backgroundColor: '#1A4A42',
                color: 'white',
                padding: '0.8rem 0',
                fontWeight: 700,
                borderRadius: '0.8rem',
                textTransform: 'none',
                width: "12rem",
                fontSize: '1.2rem'
              }}>
                Duplicate
              </MuiButton>
            </Box>
          </Box>
        </Modal>
      </Box>

      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const SearchBar = ({
  handleSearchField,
}:any) => {
  const handleInputChange = (event: { target: { value: string; }; }) => {
    if (handleSearchField) {
      handleSearchField(event?.target?.value);
    }
  };

  return (
    <SearchContainer>
      <SearchInput
      testID={"search"}
        placeholder={"Search"}
        onChange={handleInputChange}
      />
      <SearchIconWrapper>
      <SearchIcon fontSize={"large"} sx={{width:{xs:"3%",sm: "8%"},height:{xs:"3%",sm: "8%"}}} />
      </SearchIconWrapper>
    </SearchContainer>
  );
};
const pipelineListContainerStyle = {
  padding: "1.5rem 1.5rem 1rem",
  borderRadius: "1.2rem",
  backgroundColor: "#fff",
  border: "1px solid #ccc",
  boxSizing: "border-box",
};
const dividerStyle = {
  margin: "1.5rem 0",
  backgroundColor: "#1A4A4233",
};
const iconStyle = {
  backgroundColor: "#1A4A424D",
  color: "#1A4A42",
  padding: "0.5rem",
  borderRadius: "0.6rem",
  height:"1.6rem",
  width:"1.7rem"
};

const addButtonStyle = {
  backgroundColor: "#1A4A424D",
  color: "#1A4A42",
  padding: "0.8rem",
  borderRadius: "0.6rem",
  marginRight:"0.8rem"
};
const deleteIconStyle = {
  backgroundColor: "#EF444433",
  color: "#DC2626",
  padding: "0.45rem",
  borderRadius: "0.6rem",
};
const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '40rem',
  bgcolor: 'background.paper',
  borderRadius: '0.8rem',
  boxShadow: 2,
  p: 3,
};
const filterModalStyle = {
  position: 'absolute',
  top: '100%',
  right: '10%',
  width: '28rem',
  bgcolor: 'background.paper',
  borderRadius: '0.8rem',
  boxShadow: 2,
  zIndex: '99',
  p: 2,
}
const SearchContainer = styled("div")({
  position: "relative",
  minWidth: "50%",
  maxWidth: "600px",
  "@media (max-width: 600px)": {
    width: "100%"
  }
});

const SearchInput = styled("input")({
  width: "100%",
  padding: "1rem 1rem 1rem 3.8rem",
  height:"3.2rem",
  borderRadius: "1rem",
  border: "0.1rem solid #ccc",
  fontSize: "1.2rem",
  outline: "none",
  '&::placeholder': {
    color: "#1A4A42",
    opacity: 1,
    fontWeight: 700,
    fontSize:"1.2rem"
  }
});

const SearchIconWrapper = styled("div")({
  position: "absolute",
  left: "1rem",
  top: "50%",
  transform: "translateY(-50%)",
  pointerEvents: "none",
  color: "#1A4A42",
});

// Customizable Area End
